import React from 'react'
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <>
            <section style={{
                backgroundImage: "url(images/Newimage/Services1.png)",

                backgroundColor: "rgb(255, 255, 255)"
            }}
                class="sub-header position-relative">
                <div class="container">
                    <div class="page-info position-absolute">
                        <h2 class="heading-2" style={{color:'white',textAlign:'center'}}>Our Services</h2>
                        <h6 class="heading-6">
                            <a href="/">Home</a> / <a href="#">Services</a>
                        </h6>
                    </div>
                </div>
            </section>



            <main id="service">

                <section>
                    <div class="container" style={{marginTop:"-110px"}}>
                        <div class="section-heading d-flex flex-column align-items-center justify-content-center">
                            <div class="space120"></div>
                            <h2 class="heading-2">"Empowering Growth with Cutting-Edge Software Services."</h2>

                            <div class="paragraph">
                               
                            </div>
                        </div>
                    </div>

                    <div class="container" style={{maxWidth:"1340px",marginTop:"-30px"}}>
                        <div class="service-cards row g-4">
                            
                            <div class="col-md-6 col-lg-3">
                                <Link to="/ecommerce">
                                <div class="service-card individual-card" style={{ height: "300px" }}>
                                    <div class="icon-wrapper">
                                        <img src="images\1.png" alt="" />
                                    </div>

                                    <Link to="/ecommerce" className="heading-6">
                                        E-Commerce
                                    </Link>

                                    <div class="paragraph">
                                        Empowering E-Commerce Excellence: Unleash the Future of Online Retail with Our Cutting-Edge Software Solutions."
                                    </div>
                                </div>
                                </Link>

                            </div>
                            <div class="col-md-6 col-lg-3" >
                            <Link to="/webdesign">
                                <div class="service-card individual-card" style={{ height: "300px" }}>
                                    <div class="icon-wrapper">
                                        <img src="images\2.png" alt="" />
                                    </div>

                                    <h5 class="heading-6">Web Design And Development</h5>

                                    <div class="paragraph">
                                        "Elevating Digital Presence, Crafting Experiences: Your Vision, Our Expertise in Website Design and Development."
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div class="col-md-6 col-lg-3">
                            <Link to="/mobileapplication">
                                <div class="service-card individual-card" style={{ height: "300px" }}>
                                    <div class="icon-wrapper">
                                        <img src="images\3.png" alt="" />
                                    </div>

                                    <h5 class="heading-6">Mobile App Development</h5>

                                    <div class="paragraph">
                                        "Innovate, Integrate, Elevate: Transforming Ideas into Exceptional Mobile Experiences through Expert App Development."
                                    </div>
                                    
                                </div>
                                </Link>
                            </div>
                            <div class="col-md-6 col-lg-3">
                            <Link to="/UIUX">
                                <div class="service-card individual-card" style={{ height: "300px" }}>
                                    <div class="icon-wrapper">
                                        <img src="images\2.png" alt="" />
                                    </div>

                                    <h5 class="heading-6">UIUX </h5>

                                    <div class="paragraph">
                                    Organizing elements on the screen to create a balanced and visually pleasing layout
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div class="col-md-6 col-lg-3">
                            <Link to="/CyberSecurity">
                                <div class="service-card individual-card" style={{ height: "300px" }}>
                                    <div class="icon-wrapper">
                                        <img src="images\4.png" alt="" />
                                    </div>

                                    <h5 class="heading-6">Cyber Security</h5>

                                    <div class="paragraph">
                                        "Securing Tomorrow, Today: Your Shield in the Digital Frontier with Cutting-Edge Cybersecurity Solutions."
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div class="col-md-6 col-lg-3">
                            <Link to="/DigitalMarketing">
                                <div class="service-card individual-card" style={{ height: "300px" }}>
                                    <div class="icon-wrapper">
                                        <img src="images\5.png" alt="" />
                                    </div>

                                    <h5 class="heading-6">Digital Marketing</h5>

                                    <div class="paragraph">
                                        "Driving Growth in the Digital Realm: Your Success, Our Expertise in Strategic Digital Marketing."
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div class="col-md-6 col-lg-3">
                            <Link to="/seo">
                                <div class="service-card individual-card" style={{ height: "300px" }}>
                                    <div class="icon-wrapper">
                                        <img src="images\6.png" alt="" />
                                    </div>

                                    <h5 class="heading-6">SEO Analytics</h5>

                                    <div class="paragraph">
                                        "Uncover, Analyze, Excel: Elevating Your Digital Presence Through Precision SEO Analytics."
                                    </div>
                                </div>
                                </Link>
                            </div>

                            <div class="col-md-6 col-lg-3">
                            <Link to="/Dataanalytics">
                                <div class="service-card individual-card" style={{ height: "300px" }}>
                                    <div class="icon-wrapper">
                                        <img src="images\1.png" alt="" />
                                    </div>

                                    <h5 class="heading-6">Data Analytics </h5>

                                    <div class="paragraph">
                                        "Turning Data into Decisions, Insights into Impact: Unleashing the Potential of Your Information with Advanced Data Analytics."
                                    </div>
                                </div>
                                </Link>
                            </div>


                         
                            
                        </div>
                        
                    </div>
                </section>



               
                <div class="container" style={{marginBottom:"-30px"}}>
                    <section class="subscribe position-relative">
                        <div class="subscribe-area">
                            <h2 class="heading-2 mb-30">Driving Efficiency, Enabling Excellence.</h2>

                            <div class="paragraph mb-40">
                               We help you....
                            </div>

                            <form action="">
                                <div class="d-flex flex-md-row justify-content-between align-items-center">
                                    <input type="email" name="email" placeholder="Email Address" />

                                    <a href="#">
                                        <span> @ </span>
                                    </a>
                                </div>
                            </form>
                        </div>
                        <div class="overed-image position-absolute" >
                            <img src="" alt="" />
                        </div>
                        <div class="overed-image position-absolute overed-image-2" >
                            <img src="images/s1.jpg" alt="" style={{ width: "300px", marginBottom: "10px", height: "270px" }} />
                        </div>
                    </section>
                </div>



               


                <div class="space100"></div>
            </main>
        </>
    )
}

export default Services
