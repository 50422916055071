import React from 'react';
import { Link } from 'react-router-dom';
import Dashboard from './Dashboard';

const Portfolio = () => {
    return (
        <>
        <div>
            <section style={{
                backgroundImage: "url(images/Newimage/portfolio1.jpg)",
                backgroundColor: "rgb(255, 255, 255)"
            }}
                class="sub-header position-relative">
                <div class="container">
                    <div class="page-info position-absolute">
                        <h2 class="heading-2" style={{color:"white",textAlign:"center"}}>Our Portfolio</h2>
                        <h6 class="heading-6">
                            <a href="/">Home</a> / <a href="#">Portfolio</a>
                        </h6>
                    </div>
                </div>
            </section>



            <section id="projects">
                <div class="container" style={{ marginTop: "-120px" }}>
                    <div class="section-heading d-flex flex-column align-items-center justify-content-center">
                        <div class="space120"></div>
                        <h2 class="heading-2 mb-20">Our Latest Projects</h2>

                        <div class="paragraph">

                        </div>
                    </div>
                </div>

                <section id="latest-news " className="home__latest--news ">
                    <div className="container ">
                        <div className="row g-4 ">


                            <div className="col-md-6 col-lg-4 hideOnMd">
                                <Link to="https://anantasaukhyam.com/" className="link-to-external-website" target='_blank'>
                                    <div className="news-card position-relative">
                                        <div className="img-wrapper mb-30">
                                            <img src="images/a1.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading-5 mb-15">ANANTA SAUKHYAM</h5>
                                            <div className="paragraph mb-25">Wellness</div>
                                        </div>
                                        <div className="circle-1 position-absolute"></div>
                                        <div className="circle-2 position-absolute"></div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 hideOnMd ">
                                <a href=" " target='_blank'>
                                    <div className="news-card position-relative " style={{ height: "330px" }}>
                                        <div className="img-wrapper mb-30 ">
                                            <img src="images/d1.png " alt=" " />
                                        </div>
                                        <div className="content ">

                                            <hh5 className="heading-5 mb-15 ">
                                                DYAVASANDRA INDUSTRIAL ESTATE ASSOCIATION
                                            </hh5>
                                            <div className="paragraph mb-25 ">
                                                Industrial  Assosiation
                                            </div>


                                        </div>
                                        <div className="circle-1 position-absolute "></div>
                                        <div className="circle-2 position-absolute "></div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-6 col-lg-4 hideOnMd">
                                <Link to="http://www.autometengg.com/" className="link-to-external-website" target='_blank'>
                                    <div className="news-card position-relative">
                                        <div className="img-wrapper mb-30">
                                            <img src="images/au1.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading-5 mb-15">AUTOMET ENGINEERING</h5>
                                            <div className="paragraph mb-25">Automobile</div>
                                        </div>
                                        <div className="circle-1 position-absolute"></div>
                                        <div className="circle-2 position-absolute"></div>
                                    </div>
                                </Link>
                            </div>


                            <div className="col-md-6 col-lg-4 hideOnMd">
                                <Link to="http://gifcoindia.com/" className="link-to-external-website" target='_blank'>
                                    <div className="news-card position-relative">
                                        <div className="img-wrapper mb-30">
                                            <img src="images/gi1.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading-5 mb-15">GIFCO AUTO FILTERS INDIA</h5>
                                            <div className="paragraph mb-25">Automobile</div>
                                        </div>
                                        <div className="circle-1 position-absolute"></div>
                                        <div className="circle-2 position-absolute"></div>
                                    </div>
                                </Link>
                            </div>



                            <div className="col-md-6 col-lg-4 hideOnMd">
                                <Link to="https://dtechwale.com/" className="link-to-external-website" target='_blank'>
                                    <div className="news-card position-relative">
                                        <div className="img-wrapper mb-30">
                                            <img src="images/de1.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading-5 mb-15">DTECH</h5>
                                            <div className="paragraph mb-25">Civil</div>
                                        </div>
                                        <div className="circle-1 position-absolute"></div>
                                        <div className="circle-2 position-absolute"></div>
                                    </div>
                                </Link>
                            </div>


                            <div className="col-md-6 col-lg-4 hideOnMd">
                                <Link to="https://sttsonline.com/" className="link-to-external-website" target='_blank'>
                                    <div className="news-card position-relative">
                                        <div className="img-wrapper mb-30">
                                            <img src="images/st1.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading-5 mb-15">Shaw Tech Training Services</h5>
                                            <div className="paragraph mb-25">Educational</div>
                                        </div>
                                        <div className="circle-1 position-absolute"></div>
                                        <div className="circle-2 position-absolute"></div>
                                    </div>
                                </Link>
                            </div>



                            <div className="col-md-6 col-lg-4 ">
                                <a href="https://itdeskmahadevpura.com/" target='_blank'>
                                    <div className="news-card position-relative ">
                                        <div className="img-wrapper mb-30 ">
                                            <img src="images/p5.png " alt=" " />
                                        </div>
                                        <div className="content ">

                                            <hh5 className="heading-5 mb-15 ">
                                                IT DESK MAHADEVPURA
                                            </hh5>
                                            <div className="paragraph mb-25 ">
                                                Educational
                                            </div>


                                        </div>
                                        <div className="circle-1 position-absolute "></div>
                                        <div className="circle-2 position-absolute "></div>
                                    </div>
                                </a>
                            </div>



                            <div className="col-md-6 col-lg-4 ">
                                <a href="http://caddeskmahadevpura.com/ " target='_blank'>
                                    <div className="news-card position-relative ">
                                        <div className="img-wrapper mb-30 ">
                                            <img src="images/p4.png " alt=" " />
                                        </div>
                                        <div className="content ">

                                            <hh5 className="heading-5 mb-15 ">
                                                CAD DESK MAHADEVPURA
                                            </hh5>
                                            <div className="paragraph mb-25 ">
                                                Educational
                                            </div>


                                        </div>
                                        <div className="circle-1 position-absolute "></div>
                                        <div className="circle-2 position-absolute "></div>
                                    </div>
                                </a>
                            </div>



                            <div className="col-md-6 col-lg-4 ">
                                <a href="">
                                    <div className="news-card position-relative ">
                                        <div className="img-wrapper mb-30 ">
                                            <img src="images/p11.png " alt=" " />
                                        </div>
                                        <div className="content ">

                                            <hh5 className="heading-5 mb-15 ">
                                                FLAREMAX
                                            </hh5>
                                            <div className="paragraph mb-25 ">
                                                E-Commerce
                                            </div>


                                        </div>
                                        <div className="circle-1 position-absolute "></div>
                                        <div className="circle-2 position-absolute "></div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-6 col-lg-4 ">
                                <a href=" ">
                                    <div className="news-card position-relative ">
                                        <div className="img-wrapper mb-30 ">
                                            <img src="images/p1.png " alt=" " />
                                        </div>
                                        <div className="content ">

                                            <hh5 className="heading-5 mb-15 ">
                                                Dr MANJUNATH KN
                                            </hh5>
                                            <div className="paragraph mb-25 ">
                                                Plastic Surgery
                                            </div>


                                        </div>
                                        <div className="circle-1 position-absolute "></div>
                                        <div className="circle-2 position-absolute "></div>
                                    </div>
                                </a>
                            </div>


                            <div className="col-md-6 col-lg-4 ">
                                <a href=" ">
                                    <div className="news-card position-relative ">
                                        <div className="img-wrapper mb-30 ">
                                            <img src="images/p7.png " alt=" " />
                                        </div>
                                        <div className="content ">

                                            <hh5 className="heading-5 mb-15 ">
                                                SLICRC
                                            </hh5>
                                            <div className="paragraph mb-25 ">
                                                Health Care
                                            </div>


                                        </div>
                                        <div className="circle-1 position-absolute "></div>
                                        <div className="circle-2 position-absolute "></div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-6 col-lg-4 ">
                                <a href=" ">
                                    <div className="news-card position-relative ">
                                        <div className="img-wrapper mb-30 ">
                                            <img src="images/p8.png " alt=" " />
                                        </div>
                                        <div className="content ">

                                            <hh5 className="heading-5 mb-15 ">
                                                KRTIEXIM
                                            </hh5>
                                            <div className="paragraph mb-25 ">
                                                Import and Export
                                            </div>


                                        </div>
                                        <div className="circle-1 position-absolute "></div>
                                        <div className="circle-2 position-absolute "></div>
                                    </div>
                                </a>
                            </div>




                            <div className="col-md-6 col-lg-4 ">
                                <a href=" ">
                                    <div className="news-card position-relative ">
                                        <div className="img-wrapper mb-30 ">
                                            <img src="images/p9.png " alt=" " />
                                        </div>
                                        <div className="content ">

                                            <hh5 className="heading-5 mb-15 ">
                                                HASIRAATA
                                            </hh5>
                                            <div className="paragraph mb-25 ">
                                                Ayurvedic
                                            </div>


                                        </div>
                                        <div className="circle-1 position-absolute "></div>
                                        <div className="circle-2 position-absolute "></div>
                                    </div>
                                </a>
                            </div>



                            <div className="col-md-6 col-lg-4 ">
                                <a href=" ">
                                    <div className="news-card position-relative ">
                                        <div className="img-wrapper mb-30 ">
                                            <img src="images/p10.png " alt=" " />
                                        </div>
                                        <div className="content ">

                                            <hh5 className="heading-5 mb-15 ">
                                                SSF
                                            </hh5>
                                            <div className="paragraph mb-25 ">
                                                Electrical
                                            </div>


                                        </div>
                                        <div className="circle-1 position-absolute "></div>
                                        <div className="circle-2 position-absolute "></div>
                                    </div>
                                </a>
                            </div>



                        </div>
                    </div>
                </section>


                <div class="space100"></div>
            </section>



            <section style={{
                backgroundImage: "url(images/Newimage/digital-marketing-banner1.jpg)",

                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
            }}
                id="newsletter" class="portfolio__newsletter">
                <div class="container newsletter-Wrapper">
                    <div class="text">
                        <h3>Join to Our Team For More Info</h3>
                        <p>Excerpt Technologies PVT LTD</p>
                    </div>
                    <form class="form" action="">
                        <div class="email-wrapper">
                            <input type="email" name="email" id="email" placeholder="Enter your email" />
                            <a href="#" type="submit" class="subscribe-btn">
                                <span>Reach US </span>
                            </a>
                        </div>
                    </form>
                </div>
            </section>


            <div class="space100"></div>



        </div>
        
       
        </>
    )
}

export default Portfolio
