import React from 'react'
import HomeCard from '../Components/HomeCard'
import Homeabout from '../Components/Homeabout'
import Homevideo from '../Components/Homevideo'
import HomeProject from '../Components/HomeProject'
import Slider from '../Components/Slider'
import HomeArticle from '../Components/HomeArticle'
import Dashboard from './Dashboard'
import Popupform from '../Components/PopupScroll/Popupform'



const Home = () => {
    return (
        <div>
            <Slider />
            <Popupform />
            <HomeCard />
            <Homeabout />
            <HomeProject />
            <Homevideo />
            <HomeArticle />
          
            
            {/* <ResumeComponent/> */}
        </div>
    )
}

export default Home
