import React from 'react';

function Project() {
  return (
    
    <div style={{marginTop:'80px'}}>
       <div className="space90 "></div>
       <div style={{backgroundImage: `url(images/home1/exclusive-service/bg.png)`}} className="ex-service-cards">
      <div id="projects" className="home__projects">
        <div className="container" >
          
          <div className="section-heading d-flex flex-column align-items-center justify-content-center">
            <h1 className="heading-2" style={{fontSize:'50px'}}>Our Services</h1>
            
          </div>
        </div>

        <div className="container" style={{marginTop:'20px',maxWidth:"1340px"}}>
       
          <div className="project-slider row">
            {/* Project Card 1 */}
          

            {/* Project Card 2 */}
            <div className="col-lg-3 mb-4">
              <div className="project-card position-relative">
                <div className="img-wrapper">
                  <img src="images/home1/projects/2.png" alt="" />
                </div>
                <div className="info d-flex flex-column align-items-center justify-content-center">
                  <h6 className="heading-6" style={{marginLeft:"70px"}}>Web Design & Development</h6>
                  {/* <div className="paragraph">Marketing Agency</div> */}
                </div>
                <a href="/webdesign" className="icon position-absolute ">
                  <span>
                    <i className="fa-solid fa-arrow-turn-down "></i>
                  </span>
                </a>
              </div>
            </div>

            {/* Project Card 3 */}
            <div className="col-lg-3 mb-4">
              <div className="project-card position-relative">
                <div className="img-wrapper">
                  <img src="images/home1/projects/3.png" alt="" />
                </div>
                <div className="info d-flex flex-column align-items-center justify-content-center">
                  <h6 className="heading-6">Mobile App Development</h6>
                  {/* <div className="paragraph">Creative Advisor</div> */}
                </div>
                <a href="/mobileapplication " className="icon position-absolute ">
                  <span>
                    <i className="fa-solid fa-arrow-turn-down "></i>
                  </span>
                </a>
              </div>
            </div>
        
            <div className="col-lg-3 mb-4">
              <div className="project-card position-relative">
                <div className="img-wrapper">
                  <img src="images/home1/projects/8.png" alt="" />
                </div>
                <div className="info d-flex flex-column align-items-center justify-content-center">
                  <h6 className="heading-6">UI/UX Design</h6>
                  {/* <div className="paragraph">Creative Advisor</div> */}
                </div>
                <a href="/uiux" className="icon position-absolute ">
                  <span>
                    <i className="fa-solid fa-arrow-turn-down "></i>
                  </span>
                </a>
              </div>
            </div>
            
            <div className="col-lg-3 mb-4">
              <div className="project-card position-relative">
                <div className="img-wrapper">
                  <img src="images/home1/projects/e.jfif" alt=""  />
                </div>
                <div className="info d-flex flex-column align-items-center justify-content-center">
                  <h6 className="heading-6">Ecommerce Solutions</h6>
                  {/* <div className="paragraph">Web Development</div> */}
                </div>
                <a href="/ecommerce" className="icon position-absolute ">
                  <span>
                    <i className="fa-solid fa-arrow-turn-down "></i>
                  </span>
                </a>
              </div>
            </div>

            {/* Repeat the structure for additional cards */}
          </div>
        </div>

      
        <div className="container" style={{maxWidth:"1340px"}} >
       
          <div className="project-slider row">
            {/* Project Card 1 */}
            <div className="col-lg-3 mb-4">
              <div className="project-card position-relative">
                <div className="img-wrapper">
                  <img src="images/home1/projects/5.png" alt="" />
                </div>
                <div className="info d-flex flex-column align-items-center justify-content-center">
                  <h6 className="heading-6">Digital Marketing</h6>
                  {/* <div className="paragraph">Web Development</div> */}
                </div>
                <a href="/DigitalMarketing " className="icon position-absolute ">
                  <span>
                    <i className="fa-solid fa-arrow-turn-down "></i>
                  </span>
                </a>
              </div>
            </div>

            {/* Project Card 2 */}
            <div className="col-lg-3 mb-4">
              <div className="project-card position-relative">
                <div className="img-wrapper">
                  <img src="images/home1/projects/4.png" alt="" />
                </div>
                <div className="info d-flex flex-column align-items-center justify-content-center">
                  <h6 className="heading-6">Cyber Security</h6>
                  {/* <div className="paragraph">Creative Advisor</div> */}
                </div>
                <a href="/CyberSecurity" className="icon position-absolute ">
                  <span>
                    <i className="fa-solid fa-arrow-turn-down "></i>
                  </span>
                </a>
              </div>
            </div>

            {/* Project Card 3 */}
            <div className="col-lg-3 mb-4">
              <div className="project-card position-relative">
                <div className="img-wrapper">
                  <img src="images/home1/projects/7.png" alt="" />
                </div>
                <div className="info d-flex flex-column align-items-center justify-content-center">
                  <h6 className="heading-6">Data Analytics</h6>
                  {/* <div className="paragraph">Creative Advisor</div> */}
                </div>
                <a href="/Dataanalytics" className="icon position-absolute ">
                  <span>
                    <i className="fa-solid fa-arrow-turn-down "></i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="project-card position-relative">
                <div className="img-wrapper">
                  <img src="images/home1/projects/6.png" alt="" />
                </div>
                <div className="info d-flex flex-column align-items-center justify-content-center">
                  <h6 className="heading-6" style={{marginLeft:"70px"}}>Search Engine Optimization</h6>
                  {/* <div className="paragraph">Marketing Agency</div> */}
                </div>
                <a href="/seo " className="icon position-absolute ">
                  <span>
                    <i className="fa-solid fa-arrow-turn-down "></i>
                  </span>
                </a>
              </div>
            </div>
            
            

            {/* Repeat the structure for additional cards */}
          </div>
        </div>

       
      </div>
    </div>
    </div>
  );
}

export default Project;
