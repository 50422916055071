// import React, { Fragment } from 'react';
// import { Link } from 'react-router-dom'
// import { motion } from 'framer-motion';
// import '../Allprogram/Allprogram.css'; // Import the CSS file

// const coursesData = [
//     {
//         id: 1,
//         title: 'Digital Marketing',
//         image: "/Trainingimage/dm1.jpg",
//         description: 'A most powerful tool to grow business and increase reach worldwide.                               ',
//         link: '/DigitalMarketing'
//     },
//     {
//         id: 2,
//         title: 'Data Science with Python',
//         image: '/Trainingimage/datas.jpeg',
//         description: 'Data science with Python performs data analysis, data visualization.',
//         link: '/DataScience',
//     },
//     {
//         id: 3,
//         title: 'JAVA',
//         image: '/Trainingimage/jva1.jpeg',
//         description: 'Data Science with R provides the skills required to work with real data sets',
//         link: '/Java',
//     },
//     {
//         id: 4,
//         title: 'Mern Stack Development',
//         image: '/Trainingimage/mern.png',
//         description: 'MEAN is an acronym for MongoDB, Express.js and Angularjs, all of which function upon Node.js',
//         link: '/Mern',
//     },
//     // Add more course objects as needed
//     {
//         id: 5,
//         title: '.NET',
//         image: '/Trainingimage/net.png',
//         description: 'Most powerful, secure, and flexible end-to-end analytics platform for data',
//         link: '/Dotnet',
//     },
//     {
//         id: 6,
//         title: 'Python',
//         image: '/trainingimage/py1.jpeg',
//         description: 'high-level programming language with dynamic semantics developed by Guido van Rossum.',
//         link: '/Python',
//     },
//     {
//         id: 7,
//         title: 'Ethical Hacking',
//         image: '/Trainingimage/hck.jpeg',
//         description: 'o legally break into computers and networks to test an organizations overall security',
//         link: '/EthicalHacking',
//     },
//     {
//         id: 8,
//         title: 'Fullstack Developer',
//         image: '/Trainingimage/jfsd.png',
//         description: 'a professional responsible for working on both front-end and back-end development processes.',
//         link: '/FSD',
//     },
//     {
//         id: 9,
//         title: 'C Sharp',
//         image: '/Trainingimage/csharp.jpeg',
//         description: 'a professional responsible for working on both front-end and back-end development processes.',
//         link: '/CSharp',
//     },

//     {
//         id: 10,
//         title: 'C++ programming',
//         image: '/Trainingimage/c++.png',
//         description: 'a professional responsible for working on both front-end and back-end development processes.',
//         link: '/CPP',
//     },

//     {
//         id: 11,
//         title: 'Android',
//         image: '/Trainingimage/andr.jpeg',
//         description: 'a professional responsible for working on both front-end and back-end development processes.',
//         link: '/Android',
//     },

//     {
//         id: 12,
//         title: ' Python Fullstack Development ',
//         image: '/Trainingimage/pfsd.jpeg',
//         description: 'a professional responsible for working on both front-end and back-end development processes.',
//         link: '/PythonFSD',
//     },
//     {
//         id: 13,
//         title: ' PHP',
//         image: '/Trainingimage/php.png',
//         description: 'a professional responsible for working on both front-end and back-end development processes.',
//         link: '/PHP',
//     },
//     {
//         id: 14,
//         title: ' Selenium Testing',
//         image: '/Trainingimage/tst.jpeg',
//         description: 'a professional responsible for working on both front-end and back-end development processes.',
//         link: '/SeleniumTesting',
//     },
//     {
//         id: 15,
//         title: ' AWS ',
//         image: '/Trainingimage/aws.jpeg',
//         description: 'a professional responsible for working on both front-end and back-end development processes.',
//         link: '/AWS',
//     },
//     {
//         id: 16,
//         title: ' MYSQL',
//         image: '/Trainingimage/sql.jpeg',
//         description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
//         link: '/Mysql',
//     },
//     {
//         id: 17,
//         title: ' Basic MS Office',
//         image: '/Trainingimage/msofce.png',
//         description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
//         link: '/MsOffice',
//     },
//     {
//         id: 18,
//         title: ' Advance EXCEL',
//         image: '/Trainingimage/excel.png',
//         description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
//         link: '/AdvanceExcel',
//     },
//     {
//         id: 19,
//         title: ' Tally ERP9 with GST ',
//         image: '/Trainingimage/tally.png',
//         description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
//         link: '/Tally',
//     },
//     {
//         id: 20,
//         title: 'SAP- FICO ',
//         image: '/Trainingimage/sap.jpeg',
//         description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
//         link: '/SAP',
//     },

// ];

// const TrendingCourses = () => {
//     return (
//         <Fragment>
//              <div className="tab-container resposnive_allprogram" >
                    
//              <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
//                         <Link to="/Allprogram" style={{color:"white"}}>Allprogram</Link>
//                     </button>
//                     <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
//                         <Link to="/Onlineprogram" style={{color:"white"}}>Onlineprogram</Link>
//                     </button>
//                     <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
//                         <Link to="/Internship" style={{color:"white"}}>Internship</Link>
//                     </button>
//                     <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
//                         <Link to="/Placement" style={{color:"white"}}>Placement</Link>
//                     </button>
//                 </div>
            
//             <div className="trending-courses">
//                 {/* <br /><h1>IT DESK - Courses</h1><br /> */}

//                 <br />
//                 <div className="course-list">
//                     {coursesData.map(course => (
//                         <motion.div
//                             className="course-card"
//                             key={course.id}
//                             whileHover={{ scale: 1.05 }}
//                             whileTap={{ scale: 0.95 }}
//                         >
//                             <img src={course.image} alt={course.title} />
//                             <h2 style={{textAlign:"center",textTransform:"uppercase"}}>{course.title}</h2>
//                             <p>{course.description}</p>
//                             <a href={course.link} target="_blank" rel="noopener noreferrer">
//                                 <button className="button">Learn More</button>
//                             </a>
//                             {/* <Link to={course.link} >Learn More</Link> */}
//                         </motion.div>
//                     ))}
//                 </div>
//             </div>
//         </Fragment>
//     );
// };

// export default TrendingCourses









// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';

// const coursesData = [
//   {
//     id: 1,
//     title: 'Digital Marketing',
//     image: "/Trainingimage/dm1.jpg",
//     description: 'A most powerful tool to grow business and increase reach worldwide.',
//     detailedDescription: 'Digital Marketing is a comprehensive...',
//     curriculum: ['Introduction to Digital Marketing', 'SEO', 'Social Media Marketing', '...'],
//     duration: '3 months',
//   },
//   {
//     id: 1,
//     title: 'Digital Marketing1',
//     image: "/Trainingimage/dm1.jpg",
//     description: 'A most powerful tool to grow business and increase reach worldwide.',
//     detailedDescription: 'Digital Marketing is a comprehensive...',
//     curriculum: ['Introduction to Digital Marketing', 'SEO', 'Social Media Marketing', '...'],
//     duration: '3 months',
//   },
//   {
//     id: 2,
//     title: 'Digital Marketing2',
//     image: "/Trainingimage/dm1.jpg",
//     description: 'A most powerful tool to grow business and increase reach worldwide.',
//     detailedDescription: 'Digital Marketing is a comprehensive...',
//     curriculum: ['Introduction to Digital Marketing', 'SEO', 'Social Media Marketing', '...'],
//     duration: '3 months',
//   },
//   {
//     id: 3,
//     title: 'Digital Marketing3',
//     image: "/Trainingimage/dm1.jpg",
//     description: 'A most powerful tool to grow business and increase reach worldwide.',
//     detailedDescription: 'Digital Marketing is a comprehensive...',
//     curriculum: ['Introduction to Digital Marketing', 'SEO', 'Social Media Marketing', '...'],
//     duration: '3545453 months',
//   },
//   {
//     id: 4,
//     title: 'Digital Marketing4',
//     image: "/Trainingimage/dm1.jpg",
//     description: 'A most powerful tool to grow business and increase reach worldwide.',
//     detailedDescription: 'Digital Marketing is a comprehensive...',
//     curriculum: ['Introduction to Digital Marketing', 'SEO', 'Social Media Marketing', '...'],
//     duration: '3 months',
//   },
//   // ... other courses
// ];

// const CoursePage = () => {
//   const { courseId } = useParams();
//   const course = coursesData.find(c => c.id === parseInt(courseId));

//   if (!course) return <div>Course not found</div>;

//   return (
//     <div className="course-page">
//       <h1>{course.title}</h1>
//       <img src={course.image} alt={course.title} />
//       <p>{course.detailedDescription}</p>
//       <h2>Curriculum</h2>
//       <ul>
//         {course.curriculum.map((item, index) => (
//           <li key={index}>{item}</li>
//         ))}
//       </ul>
//       <p>Duration: {course.duration}</p>
//     </div>
//   );
// };

// const TrendingCourses = () => {
//   return (
//     <div>
//       {coursesData.map(course => (
//         <div key={course.id}>
//           <h2>{course.title}</h2>
//           <p>{course.description}</p>
//           <Link to={`/course/${course.id}`}>Learn More</Link>
//         </div>
//       ))}
//     </div>
//   );
// };

// export { TrendingCourses, CoursePage };




import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../Allprogram/Allprogram.css'; // Import the CSS file

export const coursesData = [

    {
        id: 'WebDesign',
        title: 'WEB DESIGN DEVELOPMENT',
        image: "/Trainingimage/web.jpg",
        description: 'Web design is the art of crafting visually appealing, user-friendly websites that deliver a seamless user experience.',
        link: '/course/WebDesign',
    },
    {
        id: 'Mern-Stack',
        title: 'MERN STACK DEVELOPMENT',
        image: "/Trainingimage/mm.jpeg",
        description: 'The MERN stack comprises MongoDB, Express.js, React, and Node.js, forming a powerful framework for developing full-stack web applications.',
        link: '/course/Mern-Stack',
    },
    {
        id: 'Java',
        title: 'JAVA',
        image: "/Trainingimage/java.png",
        description: 'Java is a versatile, object-oriented programming language use in enterprise software development.',
        link: '/course/Java',
    },
    {
        id: 'Fullstack-Development',
        title: 'FULLSTACK DEVELOPMENT',
        image: "/Trainingimage/jfsd.png",
        description: 'A professional responsible for working on both front-end and back-end development processes.',
        link: '/course/Fullstack-Development',
    },
    {
        id: 'Python',
        title: 'PYTHON',
        image: "/Trainingimage/py1.jpeg",
        description: 'Python is a high-level, interpreted programming language known for its simplicity and readability.',
        link: '/course/Python',
    },
    {
        id: 'Data-Science-with-Python',
        title: 'DATA SCIENCE WITH PYTHON',
        image: "/Trainingimage/datas.jpeg",
        description: 'Data science with Python performs data analysis, data visualization.',
        link: '/course/Data-Science-with-Python',
    },
    {
        id: 'Python-FullStack-Development',
        title: 'PYTHON FULLSTACK',
        image: "/Trainingimage/pfsd.jpeg",
        description: 'Python Full-Stack Development involves using Python for both front-end and back-end development, enabling seamless integration and efficient web application development.',
        link: '/course/Python-FullStack-Development',
    },
    {
        id: 'C-Plus-Plus',
        title: 'C++',
        image: "/Trainingimage/c++.png",
        description: 'C++ is a powerful, object-oriented programming language widely used for system software, game development and performance-critical applications.',
        link: '/course/C-Plus-Plus',
    },
    {
        id: 'AWS',
        title: 'AWS',
        image: "/Trainingimage/aws.jpeg",
        description: 'AWS (Amazon Web Services) is a comprehensive cloud computing platform offering a wide range of services, including computing power, storage, and databases, for scalable and flexible cloud solutions.',
        link: '/course/AWS',
    },
    {
        id: 'Dot-NET',
        title: '.NET',
        image: "/Trainingimage/net.png",
        description: '.NET is a versatile framework developed by Microsoft for building various types of applications, including web, mobile, desktop, and cloud-based solutions.',
        link: '/course/dot-net',
    },
    {
        id: 'C-sharp',
        title: 'C SHARP',
        image: "/Trainingimage/csharp.jpeg",
        description: 'C Sharp is a modern, versatile programming language developed by Microsoft and extensively used in building Windows applications, web services, and games.',
        link: '/course/c-sharp',
    },
    {
        id: 'php',
        title: 'PHP',
        image: "/Trainingimage/php.png",
        description: 'PHP is a popular server-side scripting language designed for web development, known for its ease of use and ability to embed directly into HTML.',
        link: '/course/php',
    },
    {
        id: 'Mysql',
        title: 'MYSQL',
        image: "/Trainingimage/sql.jpeg",
        description: 'MySQL is a widely-used open-source relational database management system known for its reliability, scalability, and ease of use in managing and organizing data.',
        link: '/course/Mysql',
    },
    {
        id: 'Ethical-hacking',
        title: 'ETHICAL HACKING',
        image: "/Trainingimage/hck.jpeg",
        description: 'Ethical hacking involves legally breaking into systems to identify and fix security vulnerabilities, ensuring data protection and cybersecurity.',
        link: '/course/Ethical-hacking',
    },
    {
        id: 'Selenium-testing',
        title: 'SELENIUM TESTING',
        image: "/Trainingimage/tst.jpeg",
        description: 'Selenium Testing is an open-source tool used for automating web browser testing, enabling efficient validation of web applications across different browsers and platforms.',
        link: '/course/Selenium-testing',
    },
    {
        id: 'Basic-ms-office',
        title: 'BASIC MS OFFICE',
        image: "/Trainingimage/msofce.png",
        description: 'Basic MS Office skills include proficiency in using Microsoft Word, Excel, and PowerPoint for creating documents, spreadsheets, and presentations.',
        link: '/course/Basic-ms-office',
    },
    {
        id: 'Advanced-excel',
        title: 'ADVANCED EXCEL',
        image: "/Trainingimage/excel.png",
        description: 'Advanced Excel involves using complex functions, data analysis tools, macros, and advanced charting techniques for more sophisticated data manipulation and reporting.',
        link: '/course/Advanced-excel',
    },
    {
        id: 'Tally-erp9-with-gst',
        title: 'TALLY ERP9 WITH GST',
        image: "/Trainingimage/tally.png",
        description: 'Accounting software for financial management and GST compliance.',
        link: '/course/Tally-erp9-with-gst',
    },
    {
        id: 'Sap-fico',
        title: 'SAP-FICO',
        image: "/Trainingimage/sap.jpeg",
        description: 'SAP-FICO integrates financial accounting and management accounting processes within SAP ERP.',
        link: '/course/Sap-fico',
    },
    {
        id: 'Power-BI',
        title: 'Power-BI',
        image: "/Trainingimage/Power_BI.png",
        description: 'Power BI covering data visualization, interactive report creation and help you to make decisions efficiently.',
        link: '/course/Power-BI',
    },
    {
        id: 'Adobe-Photoshop',
        title: 'Adobe Photoshop',
        image: "/Trainingimage/AP.png",
        description: 'Power BI covering data visualization, interactive report creation and help you to make decisions efficiently.',
        link: '/course/Adobe-Photoshop',
    },
];

const TrendingCourses = () => {
    return (
        <Fragment>
            <div className="tab-container resposnive_allprogram">
                <button style={{ margin: "10px", backgroundColor: "#0554f2" }}>
                    <Link to="/Allprogram" style={{ color: "white" }}>Allprogram</Link>
                </button>
                <button style={{ margin: "10px", backgroundColor: "#0554f2" }}>
                    <Link to="/Onlineprogram" style={{ color: "white" }}>Onlineprogram</Link>
                </button>
                <button style={{ margin: "10px", backgroundColor: "#0554f2" }}>
                    <Link to="/Internship" style={{ color: "white" }}>Internship</Link>
                </button>
                <button style={{ margin: "10px", backgroundColor: "#0554f2" }}>
                    <Link to="/Placement" style={{ color: "white" }}>Placement</Link>
                </button>
            </div>

            <div className="trending-courses">
                <div className="course-list">
                    {coursesData.map(course => (
                        <motion.div
                            className="course-card"
                            key={course.id}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <img src={course.image} alt={course.title} />
                            <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>{course.title}</h2>
                            <p>{course.description}</p>
                            <Link to={`/course/${course.id}`}>
                                <button className="button">Learn More</button>
                            </Link>
                        </motion.div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export default TrendingCourses;
