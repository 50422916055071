

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import '../Invoice/Invoice.css';

// const InvoiceForm = () => {
//   const [invoiceData, setInvoiceData] = useState({
//     invoiceNumber: '',
//     date: '',
//     terms: '',
//     dueDate: '',
//     billTo: '',
//     shipTo: '',
//     webDesign: '',
//     sslCertificate: '',
//     webDomain: '',
//     webHosting: '',
//     subTotal: '',
//     cgst: '',
//     sgst: '',
//     total: '',
//     balanceDue: '',
//     totalInWords: ''
//   });

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setInvoiceData(prevData => ({
//       ...prevData,
//       [name]: value
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:8080/invoices', invoiceData);
//       console.log('Response from server:', response.data);
//       navigate(`/invoice-display/${response.data._id}`);
//     } catch (error) {
//       console.error('Error saving invoice:', error);
//     }
//   };

//   return (
//     <form className="invoice-form" onSubmit={handleSubmit}>
//       <div className="header">
//         <img src="/Image/excerpt.png" alt="Excerpt Logo" style={{height:"100px",width:"170px"}} />
//         <h2>INVOICE</h2>
//       </div>
//       <div className="company-info">
//         <h3>Excerpt Technologies Pvt Ltd</h3>
//         <p>B133/1, 2nd Fl, KSSDC ITI Estate, Whitefield Rd,</p>
//         <p>Mahadevpura Post, Bangalore - 560048</p>
//         <p>Phone: +91(636) 465 7660, +91 (990) 050 2404</p>
//         <p>GSTIN: 29AAGCE2327K1ZH</p>
//         <p>LUT No.: AD291021005439D</p>
//       </div>
//       <div className="invoice-details">
//         <div>
//           <label>
//             Invoice:
//             <input type="text" name="invoiceNumber" value={invoiceData.invoiceNumber} onChange={handleChange} />
//           </label>
//           <label>
//             Date:
//             <input type="date" name="date" value={invoiceData.date} onChange={handleChange} />
//           </label>
//           <label>
//             Terms:
//             <input type="text" name="terms" value={invoiceData.terms} onChange={handleChange} />
//           </label>
//           <label>
//             Due Date:
//             <input type="date" name="dueDate" value={invoiceData.dueDate} onChange={handleChange} />
//           </label>
//         </div>
//         <div>
//           <label>
//             Bill To:
//             <textarea name="billTo" value={invoiceData.billTo} onChange={handleChange} />
//           </label>
//           <label>
//             Ship To:
//             <textarea name="shipTo" value={invoiceData.shipTo} onChange={handleChange} />
//           </label>
//         </div>
//       </div>
//       <table>
//         <thead>
//           <tr>
//             <th>Item & Description</th>
//             <th>Amount</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td colSpan="2"><strong>Part A - Website Design and Development</strong></td>
//           </tr>
//           <tr>
//             <td>1. Web design and development</td>
//             <td>
//               <input type="number" name="webDesign" value={invoiceData.webDesign} onChange={handleChange} />
//             </td>
//           </tr>
//           <tr>
//             <td>2. SSL Certificate</td>
//             <td>
//               <input type="number" name="sslCertificate" value={invoiceData.sslCertificate} onChange={handleChange} />
//             </td>
//           </tr>
//           <tr>
//             <td colSpan="2"><strong>Part B - Yearly Maintenance</strong></td>
//           </tr>
//           <tr>
//             <td>3. Web domain charge (Yearly)</td>
//             <td>
//               <input type="number" name="webDomain" value={invoiceData.webDomain} onChange={handleChange} />
//             </td>
//           </tr>
//           <tr>
//             <td>4. Web hosting charge (Yearly)</td>
//             <td>
//               <input type="number" name="webHosting" value={invoiceData.webHosting} onChange={handleChange} />
//             </td>
//           </tr>
//         </tbody>
//       </table>
//       <div className="invoice-summary">
//         <label>
//           Sub Total:
//           <input type="number" name="subTotal" value={invoiceData.subTotal} onChange={handleChange} />
//         </label>
//         <label>
//           CGST (9%):
//           <input type="number" name="cgst" value={invoiceData.cgst} onChange={handleChange} />
//         </label>
//         <label>
//           SGST (9%):
//           <input type="number" name="sgst" value={invoiceData.sgst} onChange={handleChange} />
//         </label>
//         <label>
//           Total:
//           <input type="number" name="total" value={invoiceData.total} onChange={handleChange} />
//         </label>
//         <label>
//           Balance Due:
//           <input type="number" name="balanceDue" value={invoiceData.balanceDue} onChange={handleChange} />
//         </label>
//         <label>
//           Total In Words:
//           <input type="text" name="totalInWords" value={invoiceData.totalInWords} onChange={handleChange} />
//         </label>
//       </div>
//       <div className="thank-you-message">
//         <p>Thank you for the valuable business! We will always be at your desk to support you.</p>
//       </div>
//       <div className="terms-conditions">
//         <h4>Terms & Conditions</h4>
//         <p>Full payment is due upon receipt of this invoice. Late payments may incur additional charges or interest as per the applicable laws.</p>
//       </div>
//       <div className="bank-details">
//         <h4>Our Bank Details</h4>
//         <p>Name: Excerpt Technologies Private Limited</p>
//         <p>Account Number: 10063739760</p>
//         <p>Name of Bank: IDFC First Bank</p>
//         <p>IFSC Code: IDFB0080151</p>
//         <p>PAN No: AAGCE2327K</p>
//       </div>
//       <button type="submit">Generate Invoice</button>
//     </form>
//   );
// };

// export default InvoiceForm;






// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import '../Invoice/Invoice.css';

// const InvoiceForm = () => {
//   const [invoiceData, setInvoiceData] = useState({
//     invoiceNumber: '',
//     date: '',
//     terms: '',
//     dueDate: '',
//     billTo: '',
//     shipTo: '',
//     webDesign: '',
//     sslCertificate: '',
//     webDomain: '',
//     webHosting: '',
//     subTotal: '',
//     cgst: '',
//     sgst: '',
//     total: '',
//     balanceDue: '',
//     totalInWords: '',
//     year: '24-25' // Default year
//   });

//   const [lastInvoiceNumber, setLastInvoiceNumber] = useState(0);

//   const navigate = useNavigate();

//   useEffect(() => {
//     // Fetch the last invoice number from the server
//     const fetchLastInvoiceNumber = async () => {
//       try {
//         const response = await axios.get('http://localhost:8080/last-invoice-number');
//         setLastInvoiceNumber(response.data.lastNumber);
//       } catch (error) {
//         console.error('Error fetching last invoice number:', error);
//       }
//     };

//     fetchLastInvoiceNumber();
//   }, []);

//   useEffect(() => {
//     // Generate invoice number
//     const newInvoiceNumber = `ETPL/INV/${invoiceData.year}/${String(lastInvoiceNumber + 1).padStart(3, '0')}`;
//     setInvoiceData(prevData => ({
//       ...prevData,
//       invoiceNumber: newInvoiceNumber
//     }));
//   }, [lastInvoiceNumber, invoiceData.year]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setInvoiceData(prevData => ({
//       ...prevData,
//       [name]: value
//     }));
//   };


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:8080/invoices', invoiceData);
//       console.log('Response from server:', response.data);
      
//       // Update the local state
//       setLastInvoiceNumber(prevNumber => prevNumber + 1);
      
//       navigate(`/invoice-display/${response.data._id}`);
//     } catch (error) {
//       console.error('Error saving invoice:', error);
//     }
//   };
//   return (
//     <form className="invoice-form" onSubmit={handleSubmit}>
//       <div className="header">
//         <img src="/Image/excerpt.png" alt="Excerpt Logo" style={{height:"100px",width:"170px"}} />
//         <h2>INVOICE</h2>
//       </div>
//       <div className="company-info">
//         <h3>Excerpt Technologies Pvt Ltd</h3>
//         <p>B133/1, 2nd Fl, KSSDC ITI Estate, Whitefield Rd,</p>
//         <p>Mahadevpura Post, Bangalore - 560048</p>
//         <p>Phone: +91(636) 465 7660, +91 (990) 050 2404</p>
//         <p>GSTIN: 29AAGCE2327K1ZH</p>
//         <p>LUT No.: AD291021005439D</p>
//       </div>
//       <div className="invoice-details">
//         <div>
//           <label>
//             Invoice:
//             <input type="text" name="invoiceNumber" value={invoiceData.invoiceNumber} readOnly />
//           </label>
//           <label>
//             Year:
//             <select name="year" value={invoiceData.year} onChange={handleChange}>
//               <option value="24-25">2024-2025</option>
//               <option value="25-26">2025-2026</option>
//               <option value="26-27">2026-2027</option>
//               {/* Add more years as needed */}
//             </select>
//           </label>
//           <label>
//             Date:
//             <input type="date" name="date" value={invoiceData.date} onChange={handleChange} />
//           </label>
//           <label>
//             Terms:
//             <input type="text" name="terms" value={invoiceData.terms} onChange={handleChange} />
//           </label>
//           <label>
//             Due Date:
//             <input type="date" name="dueDate" value={invoiceData.dueDate} onChange={handleChange} />
//           </label>
//         </div>
//         <div>
//           <label>
//             Bill To:
//             <textarea name="billTo" value={invoiceData.billTo} onChange={handleChange} />
//           </label>
//           <label>
//             Ship To:
//             <textarea name="shipTo" value={invoiceData.shipTo} onChange={handleChange} />
//           </label>
//         </div>
//       </div>
//       <table>
//         <thead>
//           <tr>
//             <th>Item & Description</th>
//             <th>Amount</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td colSpan="2"><strong>Part A - Website Design and Development</strong></td>
//           </tr>
//           <tr>
//             <td>1. Web design and development</td>
//             <td>
//               <input type="number" name="webDesign" value={invoiceData.webDesign} onChange={handleChange} />
//             </td>
//           </tr>
//           <tr>
//             <td>2. SSL Certificate</td>
//             <td>
//               <input type="number" name="sslCertificate" value={invoiceData.sslCertificate} onChange={handleChange} />
//             </td>
//           </tr>
//           <tr>
//             <td colSpan="2"><strong>Part B - Yearly Maintenance</strong></td>
//           </tr>
//           <tr>
//             <td>3. Web domain charge (Yearly)</td>
//             <td>
//               <input type="number" name="webDomain" value={invoiceData.webDomain} onChange={handleChange} />
//             </td>
//           </tr>
//           <tr>
//             <td>4. Web hosting charge (Yearly)</td>
//             <td>
//               <input type="number" name="webHosting" value={invoiceData.webHosting} onChange={handleChange} />
//             </td>
//           </tr>
//         </tbody>
//       </table>
//       <div className="invoice-summary">
//         <label>
//           Sub Total:
//           <input type="number" name="subTotal" value={invoiceData.subTotal} onChange={handleChange} />
//         </label>
//         <label>
//           CGST (9%):
//           <input type="number" name="cgst" value={invoiceData.cgst} onChange={handleChange} />
//         </label>
//         <label>
//           SGST (9%):
//           <input type="number" name="sgst" value={invoiceData.sgst} onChange={handleChange} />
//         </label>
//         <label>
//           Total:
//           <input type="number" name="total" value={invoiceData.total} onChange={handleChange} />
//         </label>
//         <label>
//           Balance Due:
//           <input type="number" name="balanceDue" value={invoiceData.balanceDue} onChange={handleChange} />
//         </label>
//         <label>
//           Total In Words:
//           <input type="text" name="totalInWords" value={invoiceData.totalInWords} onChange={handleChange} />
//         </label>
//       </div>
//       <div className="thank-you-message">
//         <p>Thank you for the valuable business! We will always be at your desk to support you.</p>
//       </div>
//       <div className="terms-conditions">
//         <h4>Terms & Conditions</h4>
//         <p>Full payment is due upon receipt of this invoice. Late payments may incur additional charges or interest as per the applicable laws.</p>
//       </div>
//       <div className="bank-details">
//         <h4>Our Bank Details</h4>
//         <p>Name: Excerpt Technologies Private Limited</p>
//         <p>Account Number: 10063739760</p>
//         <p>Name of Bank: IDFC First Bank</p>
//         <p>IFSC Code: IDFB0080151</p>
//         <p>PAN No: AAGCE2327K</p>
//       </div>
//       <button type="submit">Generate Invoice</button>
//     </form>
//   );
// };

// export default InvoiceForm;





// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import '../Invoice/Invoice.css';

// const InvoiceForm = () => {
//   const [invoiceData, setInvoiceData] = useState({
//     invoiceNumber: '',
//     date: '',
//     terms: '',
//     dueDate: '',
//     billTo: '',
//     shipTo: '',
//     webDesign: '',
//     sslCertificate: '',
//     webDomain: '',
//     webHosting: '',
//     subTotal: '',
//     cgst: '',
//     sgst: '',
//     total: '',
//     balanceDue: '',
//     totalInWords: '',
//     year: '24-25' // Default year
//   });

//   const [lastInvoiceNumber, setLastInvoiceNumber] = useState(0);

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchLastInvoiceNumber = async () => {
//       try {
//         const response = await axios.get('http://localhost:8080/last-invoice-number');
//         setLastInvoiceNumber(response.data.lastNumber);
//       } catch (error) {
//         console.error('Error fetching last invoice number:', error);
//       }
//     };

//     fetchLastInvoiceNumber();
//   }, []);

//   useEffect(() => {
//     const newInvoiceNumber = `ETPL/INV/${invoiceData.year}/${String(lastInvoiceNumber + 1).padStart(3, '0')}`;
//     setInvoiceData(prevData => ({
//       ...prevData,
//       invoiceNumber: newInvoiceNumber
//     }));
//   }, [lastInvoiceNumber, invoiceData.year]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setInvoiceData(prevData => ({
//       ...prevData,
//       [name]: value
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:8080/invoices', invoiceData);
//       console.log('Response from server:', response.data);
//       setLastInvoiceNumber(prevNumber => prevNumber + 1);
//       navigate(`/invoice-display/${response.data._id}`);
//     } catch (error) {
//       console.error('Error saving invoice:', error);
//     }
//   };

//   return (
//     <form className="invoice-form" onSubmit={handleSubmit}>
//       <div className="header">
//       <img src="/Image/excerpt.png" alt="Excerpt Logo" style={{height:"100px",width:"170px"}} />
//         <h2>INVOICE</h2>
//       </div>
//       <div className="company-info">
//         <h3>Excerpt Technologies Pvt Ltd</h3>
//         <p>B133/1, 2nd Fl, KSSDC ITI Estate, Whitefield Rd,</p>
//         <p>Mahadevpura Post, Bangalore - 560048</p>
//         <p>Phone: +91(636) 465 7660, +91 (990) 050 2404</p>
//         <p>GSTIN: 29AAGCE2327K1ZH</p>
//         <p>LUT No.: AD291021005439D</p>
//       </div>
//       <div className="invoice-details">
//         <div>
//           <label>
//             Invoice:
//             <input type="text" name="invoiceNumber" value={invoiceData.invoiceNumber} readOnly />
//           </label>
//           <label>
//             Year:
//             <select name="year" value={invoiceData.year} onChange={handleChange}>
//               <option value="24-25">2024-2025</option>
//               <option value="25-26">2025-2026</option>
//               <option value="26-27">2026-2027</option>
//             </select>
//           </label>
//           <label>
//             Date:
//             <input type="date" name="date" value={invoiceData.date} onChange={handleChange} />
//           </label>
//           <label>
//             Terms:
//             <input type="text" name="terms" value={invoiceData.terms} onChange={handleChange} />
//           </label>
//           <label>
//             Due Date:
//             <input type="date" name="dueDate" value={invoiceData.dueDate} onChange={handleChange} />
//           </label>
//         </div>
//         <div>
//           <label>
//             Bill To:
//             <textarea name="billTo" value={invoiceData.billTo} onChange={handleChange} />
//           </label>
//           <label>
//             Ship To:
//             <textarea name="shipTo" value={invoiceData.shipTo} onChange={handleChange} />
//           </label>
//         </div>
//       </div>
//       <table>
//         <thead>
//           <tr>
//             <th>Item & Description</th>
//             <th>Amount</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td colSpan="2"><strong>Part A - Website Design and Development</strong></td>
//           </tr>
//           <tr>
//             <td>1. Web design and development</td>
//             <td>
//               <input type="number" name="webDesign" value={invoiceData.webDesign} onChange={handleChange} />
//             </td>
//           </tr>
//           <tr>
//             <td>2. SSL Certificate</td>
//             <td>
//               <input type="number" name="sslCertificate" value={invoiceData.sslCertificate} onChange={handleChange} />
//             </td>
//           </tr>
//           <tr>
//             <td colSpan="2"><strong>Part B - Yearly Maintenance</strong></td>
//           </tr>
//           <tr>
//             <td>3. Web domain charge (Yearly)</td>
//             <td>
//               <input type="number" name="webDomain" value={invoiceData.webDomain} onChange={handleChange} />
//             </td>
//           </tr>
//           <tr>
//             <td>4. Web hosting charge (Yearly)</td>
//             <td>
//               <input type="number" name="webHosting" value={invoiceData.webHosting} onChange={handleChange} />
//             </td>
//           </tr>
//         </tbody>
//       </table>
//       <div className="invoice-summary">
//         <label>
//           Sub Total:
//           <input type="number" name="subTotal" value={invoiceData.subTotal} onChange={handleChange} />
//         </label>
//         <label>
//           CGST (9%):
//           <input type="number" name="cgst" value={invoiceData.cgst} onChange={handleChange} />
//         </label>
//         <label>
//           SGST (9%):
//           <input type="number" name="sgst" value={invoiceData.sgst} onChange={handleChange} />
//         </label>
//         <label>
//           Total:
//           <input type="number" name="total" value={invoiceData.total} onChange={handleChange} />
//         </label>
//         <label>
//           Balance Due:
//           <input type="number" name="balanceDue" value={invoiceData.balanceDue} onChange={handleChange} />
//         </label>
//         <label>
//           Total In Words:
//           <input type="text" name="totalInWords" value={invoiceData.totalInWords} onChange={handleChange} />
//         </label>
//       </div>
//       <div className="thank-you-message">
//         <p>Thank you for the valuable business! We will always be at your desk to support you.</p>
//       </div>
//       <div className="terms-conditions">
//         <h4>Terms & Conditions</h4>
//         <p>Full payment is due upon receipt of this invoice. Late payments may incur additional charges or interest as per the applicable laws.</p>
//       </div>
//       <div className="bank-details">
//         <h4>Our Bank Details</h4>
//         <p>Name: Excerpt Technologies Private Limited</p>
//         <p>Account Number: 10063739760</p>
//         <p>Name of Bank: IDFC First Bank</p>
//         <p>IFSC Code: IDFB0080151</p>
//         <p>PAN No: AAGCE2327K</p>
//       </div>
//       <button type="submit">Generate Invoice</button>
//     </form>
//   );
// };

// export default InvoiceForm;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Invoice/Invoice.css';

const InvoiceForm = () => {
  const [invoiceData, setInvoiceData] = useState({
    invoiceNumber: '',
    date: '',
    terms: '',
    dueDate: '',
    billTo: '',
    shipTo: '',
    webDesign: '',
    sslCertificate: '',
    webDomain: '',
    webHosting: '',
    subTotal: '',
    cgst: '',
    sgst: '',
    total: '',
    balanceDue: '',
    totalInWords: '',
    year: '24-25' // Default year
  });

  const [lastInvoiceNumber, setLastInvoiceNumber] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLastInvoiceNumber = async () => {
      try {
        const response = await axios.get('http://localhost:8080/last-invoice-number');
        setLastInvoiceNumber(response.data.lastNumber);
      } catch (error) {
        console.error('Error fetching last invoice number:', error);
      }
    };

    fetchLastInvoiceNumber();
  }, []);

  useEffect(() => {
    const newInvoiceNumber = `ETPL/INV/${invoiceData.year}/${String(lastInvoiceNumber + 1).padStart(3, '0')}`;
    setInvoiceData(prevData => ({
      ...prevData,
      invoiceNumber: newInvoiceNumber
    }));
  }, [lastInvoiceNumber, invoiceData.year]);

  useEffect(() => {
    const calculateTotals = () => {
      const webDesign = parseFloat(invoiceData.webDesign) || 0;
      const sslCertificate = parseFloat(invoiceData.sslCertificate) || 0;
      const webDomain = parseFloat(invoiceData.webDomain) || 0;
      const webHosting = parseFloat(invoiceData.webHosting) || 0;

      const subTotal = webDesign + sslCertificate + webDomain + webHosting;
      const cgst = subTotal * 0.09;
      const sgst = subTotal * 0.09;
      const total = subTotal + cgst + sgst;

      setInvoiceData(prevData => ({
        ...prevData,
        subTotal: subTotal.toFixed(2),
        cgst: cgst.toFixed(2),
        sgst: sgst.toFixed(2),
        total: total.toFixed(2)
        // Removed balanceDue from here
      }));
    };

    calculateTotals();
  }, [invoiceData.webDesign, invoiceData.sslCertificate, invoiceData.webDomain, invoiceData.webHosting]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8080/invoices', invoiceData);
      console.log('Response from server:', response.data);
      setLastInvoiceNumber(prevNumber => prevNumber + 1);
      navigate(`/invoice-display/${response.data._id}`);
    } catch (error) {
      console.error('Error saving invoice:', error);
    }
  };

  return (
    <form className="invoice-form" onSubmit={handleSubmit}>
      <div className="header">
        <img src="/Image/excerpt.png" alt="Excerpt Logo" style={{height:"100px",width:"170px"}} />
        <h2>INVOICE</h2>
      </div>
      <div className="company-info">
        <h3>Excerpt Technologies Pvt Ltd</h3>
        <p>B133/1, 2nd Fl, KSSDC ITI Estate, Whitefield Rd,</p>
        <p>Mahadevpura Post, Bangalore - 560048</p>
        <p>Phone: +91(636) 465 7660, +91 (990) 050 2404</p>
        <p>GSTIN: 29AAGCE2327K1ZH</p>
        <p>LUT No.: AD291021005439D</p>
      </div>
      <div className="invoice-details">
        <div>
          <label>
            Invoice:
            <input type="text" name="invoiceNumber" value={invoiceData.invoiceNumber} readOnly />
          </label>
          <label>
            Year:
            <select name="year" value={invoiceData.year} onChange={handleChange}>
              <option value="24-25">2024-2025</option>
              <option value="25-26">2025-2026</option>
              <option value="26-27">2026-2027</option>
            </select>
          </label>
          <label>
            Date:
            <input type="date" name="date" value={invoiceData.date} onChange={handleChange} />
          </label>
          <label>
            Terms:
            <input type="text" name="terms" value={invoiceData.terms} onChange={handleChange} />
          </label>
          <label>
            Due Date:
            <input type="date" name="dueDate" value={invoiceData.dueDate} onChange={handleChange} />
          </label>
        </div>
        <div>
          <label>
            Bill To:
            <textarea name="billTo" value={invoiceData.billTo} onChange={handleChange} />
          </label>
          <label>
            Ship To:
            <textarea name="shipTo" value={invoiceData.shipTo} onChange={handleChange} />
          </label>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Item & Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2"><strong>Part A - Website Design and Development</strong></td>
          </tr>
          <tr>
            <td>1. Web design and development</td>
            <td>
              <input type="number" name="webDesign" value={invoiceData.webDesign} onChange={handleChange} />
            </td>
          </tr>
          <tr>
            <td>2. SSL Certificate</td>
            <td>
              <input type="number" name="sslCertificate" value={invoiceData.sslCertificate} onChange={handleChange} />
            </td>
          </tr>
          <tr>
            <td colSpan="2"><strong>Part B - Yearly Maintenance</strong></td>
          </tr>
          <tr>
            <td>3. Web domain charge (Yearly)</td>
            <td>
              <input type="number" name="webDomain" value={invoiceData.webDomain} onChange={handleChange} />
            </td>
          </tr>
          <tr>
            <td>4. Web hosting charge (Yearly)</td>
            <td>
              <input type="number" name="webHosting" value={invoiceData.webHosting} onChange={handleChange} />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="invoice-summary">
        <label>
          Sub Total:
          <input type="number" name="subTotal" value={invoiceData.subTotal} readOnly />
        </label>
        <label>
          CGST (9%):
          <input type="number" name="cgst" value={invoiceData.cgst} readOnly />
        </label>
        <label>
          SGST (9%):
          <input type="number" name="sgst" value={invoiceData.sgst} readOnly />
        </label>
        <label>
          Total:
          <input type="number" name="total" value={invoiceData.total} readOnly />
        </label>
        <label>
          Balance Due:
          <input type="number" name="balanceDue" value={invoiceData.balanceDue} onChange={handleChange} />
        </label>
        <label>
          Total In Words:
          <input type="text" name="totalInWords" value={invoiceData.totalInWords} onChange={handleChange} />
        </label>
      </div>
      <div className="thank-you-message">
        <p>Thank you for the valuable business! We will always be at your desk to support you.</p>
      </div>
      <div className="terms-conditions">
        <h4>Terms & Conditions</h4>
        <p>Full payment is due upon receipt of this invoice. Late payments may incur additional charges or interest as per the applicable laws.</p>
      </div>
      <div className="bank-details">
        <h4>Our Bank Details</h4>
        <p>Name: Excerpt Technologies Private Limited</p>
        <p>Account Number: 10063739760</p>
        <p>Name of Bank: IDFC First Bank</p>
        <p>IFSC Code: IDFB0080151</p>
        <p>PAN No: AAGCE2327K</p>
      </div>
      <button type="submit">Generate Invoice</button>
    </form>
  );
};

export default InvoiceForm;