import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CertificateGenerator from './CertificateGenerator';
import './Certificate.css'


function Certificate() {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedCollegeName, setSelectedCollegeName] = useState('');
  const [selectedYOP, setSelectedYOP] = useState('');
  const [collegeNames, setCollegeNames] = useState([]);
  const [yopList, setYOPList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRegNo, setSelectedRegNo] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/students');
      setStudents(response.data);

      // Extract unique college names from the data
      const uniqueCollegeNames = [...new Set(response.data.map(student => student.college_name))];
      setCollegeNames(uniqueCollegeNames);

      // Extract unique YOP from the data
      const uniqueYOPs = [...new Set(response.data.map(student => student.yop))];
      setYOPList(uniqueYOPs);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    filterStudents(selectedCollegeName, selectedYOP, searchText, selectedRegNo);
  }, [students, selectedCollegeName, selectedYOP, searchText, selectedRegNo]);

  const handleCollegeChange = (e) => {
    const selectedCollege = e.target.value;
    setSelectedCollegeName(selectedCollege);
  };

  const handleYOPChange = (e) => {
    const selectedYOP = e.target.value;
    setSelectedYOP(selectedYOP);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleRegNoChange = (e) => {
    setSelectedRegNo(e.target.value);
  };

  const filterStudents = (collegeName, yop, searchText, regNo) => {
    let filtered = students;

    if (collegeName) {
      filtered = filtered.filter(student => student.college_name.toLowerCase() === collegeName.toLowerCase());
    }

    if (yop) {
      filtered = filtered.filter(student => student.yop === yop);
    }

    if (searchText) {
      const searchLowerCase = searchText.toLowerCase();
      filtered = filtered.filter(student =>
        student.NAME.toLowerCase().includes(searchLowerCase) ||
        student.REG_NO.toLowerCase().includes(searchLowerCase) ||
        student.FATHER_NAME.toLowerCase().includes(searchLowerCase) ||
        student.coursename.toLowerCase().includes(searchLowerCase) ||
        student.certificate_type.toLowerCase().includes(searchLowerCase)
      );
    }

    if (regNo) {
      filtered = filtered.filter(student => student.REG_NO === regNo);
    }

    // Sort the filtered array alphabetically by student name
    filtered.sort((a, b) => a.NAME.localeCompare(b.NAME));

    setFilteredStudents(filtered);
  };



  return (
    <>

      <section style={{ height: "100px" }}
        class="sub-header position-relative">
        <div class="container">
          <div class=" position-absolute" style={{
            marginTop: "-210px", maxWidth: "354px",

            width: "100",
            padding: "20px",


            left: "8%",
            top: "0",
            transform: "translate(-50%, 235px)",

            color: "black"
          }} >
            <h2 class="heading-2">Certificate </h2>
            <h6 class="heading-6">
              <a href="/dashbord">dashbord</a>  <a href="#"> </a>
            </h6>
          </div>
        </div>
      </section >

      <div className='container' style={{
        height: "100px", marginLeft: "-100px", display: "flex",
        alignItems: "center"
      }}>

        <select id="collegeDropdown" value={selectedCollegeName} onChange={handleCollegeChange} style={{ marginTop: "40px", marginLeft: "-450px" }}>
          <option value="">CollegeName</option>
          {collegeNames.map((collegeName, index) => (
            <option key={index} value={collegeName}>{collegeName}</option>
          ))}
        </select>
      </div>
      <div className='container' style={{
        height: "100px", marginLeft: "-80px", display: "flex",
        alignItems: "center"
      }}>
        <select id="yopDropdown" value={selectedYOP} onChange={handleYOPChange} style={{ marginLeft: "-0px", marginTop: "-60px" }}>
          <option value="">YOP</option>
          {yopList.map((yop, index) => (
            <option key={index} value={yop}>{yop}</option>
          ))}
        </select>
      </div>

      <div className='container search-container' style={{
        height: "100px", marginLeft: "430px", display: "flex", marginTop: "-90px",
        alignItems: "center"
      }}>

        <input
          type="text"
          className='search-input'
          placeholder="Search..."
          value={searchText}
          onChange={handleSearchChange}
          style={{ marginLeft: "-500px", marginTop: "-70px" }}
        />
        <i className="fa fa-search search-icon"></i>
      </div>

      <div className='container search-container' style={{
        height: "100px", marginLeft: "730px", display: "flex", marginTop: "-100px",
        alignItems: "center"
      }}>

        <input
          type="text"
          className='search-input'
          placeholder="Enter REG_NO..."
          value={selectedRegNo}
          onChange={handleRegNoChange}
          style={{ marginLeft: "-550px", marginTop: "-70px" }}
        />
      </div>

      <div className='card_student_container' style={{ marginRight: "-100px", marginLeft: "-90px" }}>
        {filteredStudents.map((student, index) => (
          <div key={index}>
            <CertificateGenerator
              firstName={student.NAME}
              Gender ={student. GENDER}
              CollegeName={student.college_name}
              FATHER_NAME={student.FATHER_NAME}
              REG_NO={student.REG_NO}
              coursename={student.coursename}
              certificate_type={student.certificate_type}
              yop={student.yop}
              coursecertificatr={student.Course_Certificate_Type}
              Role={student.Role}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default Certificate;
