import React from "react";
import { Helmet } from "react-helmet";

const Webdesign = () => {
  return (
    <div>
      <section
        style={{
          backgroundImage: "url(images/w01.jpg)",
          backgroundColor: "rgb(255, 255, 255)",
        }}
        class="sub-header position-relative"
      >
        <Helmet>
        <title>Web Design & Development | E-commerce Solutions | Excerpt Technologies</title>
        <meta name="description" content="Discover top-tier Web Design and Development, along with cutting-edge E-commerce Solutions at Excerpt Technologies. Our expert team delivers customized, responsive websites and powerful online stores to enhance your digital footprint. Get in touch to transform your business today!" />
        <meta name="keywords" content="software solutions website,
best website developers in bengaluru,
best website developers for small business,
ecommerce website developers,
ecommerce website developers in bangalore,
ecommerce website developers near me,
website design & development services,
website developers"/>
</Helmet>
        <div class="container">
          <div class="page-info position-absolute">
            <h2 class="heading-2" style={{color:'white',textAlign:'center'}}>Web Design & Development </h2>
            <h6 class="heading-6">
              <a href="/">Home</a> / <a href="#">Web Design</a>
            </h6>
          </div>
        </div>
      </section>

      <div class="space120"></div>

      <section id="service-details" class="service-details">
        <div class="container">
          <div class="row g-4">
            <div class="col-md-8 mb-40">
              <div class="img-wrapper mb-25">
                <img src="images/web1.jpg" class="img-fluid" alt="" />
              </div>

              <h4 class="heading-4 mb-25">
                Web page Designing and Development
              </h4>

              <div class="paragraph mb-40">
                We excel in crafting
                bespoke websites that marry aesthetics with functionality. Our
                adept team collaborates closely with you to actualize your
                vision, guaranteeing a smooth user journey and robust online
                visibility. Whether it's a sleek informational site or a dynamic
                e-commerce hub, count on us for top-tier performance and
                stunning design.  
              </div>

              <div class="row g-4 mb-25">
                <div class="col-md-6">
                  <div class="img-wrapper">
                    <img
                      src="images/web2.jpg"
                      class="img-fluid sub"
                      alt=""
                      style={{ height: "300px" }}
                    />
                  </div>
                </div>
                <div class="d-none d-md-block col-md-6">
                  <div class="img-wrapper">
                    <img src="images/web3.jpg" class="img-fluid sub" alt="" />
                  </div>
                </div>
              </div>

              <h4 class="heading-3 mb-25">Manage designing Solutions</h4>

              <div class="paragraph mb-40">
              We keep your website current and adaptable. We handle updates,
                maintenance, and feature enhancements, allowing you to
                concentrate on your core business. Trust us for a hassle-free
                experience, keeping your website fresh, secure, and competitive.
              </div>

              <div class="service-divider"></div>
            </div>
            <div class="d-none d-md-block col-md-4">
              <div class="service">
                <h4 class="heading-3">Services</h4>

                <div class="underline"></div>

                <ul class="service-list">
                  <li class="active">
                    <a href="/ecommerce">
                      <div class="d-flex justify-content-between item">
                        <span>Ecommerce</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/mobileapplication">
                      <div class="d-flex justify-content-between item">
                        <span>MobileApplication</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Cybersecurity">
                      <div class="d-flex justify-content-between item">
                        <span>CyberSecurity</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/DigitalMarketing">
                      <div class="d-flex justify-content-between item">
                        <span>DigitalMarketing</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Seo">
                      <div class="d-flex justify-content-between item">
                        <span>SEOAnalytics</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Dataanalytics">
                      <div class="d-flex justify-content-between item">
                        <span>DataAnalytics</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="squre-banner">
                <h2 class="heading-3">Easy solutions to your tech problem</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="space120"></div>
    </div>
  );
};

export default Webdesign;
