import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Register = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    qualification: '',
    gender: '',
    role: 'user' // default role
  });
  const navigate=useNavigate()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.post('/api/register', formData);
    console.log(response.data);
    
    toast.success(`Registration successful!`, { 
      position: `top-right`,
      onClose: () => navigate('/login') // Navigate to login page after toast is closed
    }); 
  } catch (error) {
    console.error(error);
    toast.error('Error in registration', { position: 'top-right' }); 
  }
};
  return (
    <div>
      <div className="wrapper login-1" style={{ height: '600px' }}>
        <div className="lcontainer1" style={{ marginLeft: '350px', marginTop: '50px', marginBottom: '50px' }}>
          <div className="col-left">
            <div className="login-text">
              <h2>Welcome</h2>
              <p>Create your account.<br />It's totally free.</p>
              <Link to="/login" className="btn">Sign Up</Link>
            </div>
          </div>
          <div className="col-right">
            <div className="login-form">
              <h2>Register</h2>

              <form onSubmit={handleSubmit} >
                <p>
                  <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
                </p>
                <p>
                  <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                </p>
                <p>
                  <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
                </p>
             <p>

                <select className='login-1 login-form input ' name="gender" value={formData.gender} onChange={handleChange}>
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                </p>

                <p>
                  <select className='login-1 login-form input '  name="role" value={formData.role} onChange={handleChange}>
                    <option value="user">User</option>
                    <option value="user">Staff</option>
                    {/* <option value="admin">Admin</option> */}
                  </select>
                </p>


                <p>
                
                  <button type="submit">Register</button>
                </p>
                <p>
                  <a href="">Forget Password?</a>
                </p>
              </form>
              <ToastContainer position="top-right" autoClose={5000} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register






