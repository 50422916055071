import React from 'react'

import CertificateGenerator from '../Components/CertificateGenerator'
import Certificate1  from '../Components/Certificate1'

import Studentform from '../Components/Certificate/Studentform'
import Studentcertificate from '../Components/Studentcertificate.js/Studentcertificate'
const Certificate = () => {
  return (
    <div>
     <Certificate1 />
        <CertificateGenerator />
        {/* <Dashbord1 /> */}
<Studentform />
     <Studentcertificate />   
       
    </div>
  )
}

export default Certificate
