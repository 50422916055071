import React from 'react';
import './Whatsapp.css'; // Import your CSS file with styling

function App() {
  

  return (
    <div className="App btn-whatsapp-pulse">
      {/* WhatsApp icon with a link to open the WhatsApp application */}
      <a href='https://wa.me/+916364657660' target='blank' >
        <i className="fab fa-whatsapp"></i>
      </a>
    </div>
  );
}

export default App;
