import React from 'react'

import Carrerbg from '../Pages/CarrerBg'
import CompanyGoals from '../Pages/CompanyGoals'

import JobData from '../Components/JobData'



const Carrers = () => {
  return (
    <div>
    {/* <Topheader /> */}
    <Carrerbg />
    {/* <CompanyGoals /> */}
    <JobData/>
    </div>
  )
}

export default Carrers
