
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import highlight2 from '../../../Trainingimage/andr.jpeg';
import img1 from '../../../Trainingimage/img111.png';
import img2 from '../../../Trainingimage/img666.jpg';
import img4 from '../../../Trainingimage/img444.jpg';
import img3 from '../../../Trainingimage/img333.png';
import img5 from '../../../Trainingimage/img555.png';
import img6 from '../../../Trainingimage/img666.png';
import cert from '../../../Trainingimage/cert11.jpg';
import { coursesData } from '../Allprogram/Allprogram';
import axios from 'axios';

// Define the common highlights array with image paths
const commonHighlights = [
  { title: "Instructor Led Training  ", image: img1 },
  { title: "Weekly Doubt Clearing Sessions", image: img5 },
  { title: "Lifetime Assistance for Revision", image: img3 },
  { title: "Lifetime Login Access to Student Dashboard", image: img4 },
  { title: "World-Wide Valid Certification", image: img2 },
  { title: "Study Material, Assignments and Projects", image: img6 }
];
// Define the courses data array
const coursesDataa = [


    {
        id: 'Power-BI',
        title: 'Power BI',
        image: '/path/to/power-bi-course-image.jpg',
        description: 'Learn Power BI with our course, covering data visualization, report creation, and interactive dashboards to help you make data-driven decisions.',
        detailedDescription: [
            {
                heading: <h4>About Course</h4>,
                paragraph: <h5>The Power BI course offers comprehensive training on Microsoft's Power BI, a powerful tool for data visualization and business intelligence. Learn to create interactive reports, dashboards, and data models to gain insights from your data. The course covers essential features and best practices for effective data analysis and reporting.</h5>
            },
            {
                heading: <strong>Topics include</strong>,
                paragraph: (
                    <>
                        <h4>Introduction to Power BI</h4>
                        <ul>
                            <li>Overview of Power BI</li>
                            <li>Power BI Desktop and Power BI Service</li>
                            <li>Connecting to Data Sources</li>
                        </ul>
                        <h4>Data Transformation and Modeling</h4>
                        <ul>
                            <li>Data Cleaning and Transformation with Power Query</li>
                            <li>Creating Data Models and Relationships</li>
                            <li>Using DAX for Calculations and Measures</li>
                        </ul>
                        <h4>Data Visualization</h4>
                        <ul>
                            <li>Creating and Customizing Visualizations</li>
                            <li>Building Interactive Dashboards</li>
                            <li>Using Filters and Slicers</li>
                        </ul>
                        <h4>Advanced Features</h4>
                        <ul>
                            <li>Sharing and Publishing Reports</li>
                            <li>Creating Power BI Apps</li>
                            <li>Data Refresh and Security</li>
                        </ul>
                        <h4>Project Work</h4>
                        <ul>
                            <li>Developing an Interactive Dashboard</li>
                            <li>Creating a Comprehensive Report for Business Analysis</li>
                        </ul>
                        <h4>Duration: 2 months</h4>
                    </>
                ),
            },
        ],
        duration: '2 months',
        highlights: ['Expert Trainers', 'Hands-on Labs', 'Data Visualization Skills', 'Certification Provided'],
      }
    , 
    {
        id: 'Adobe-Photoshop',
        title: 'Adobe Photoshop',
        image: '/path/to/power-bi-course-image.jpg',
        description: 'Learn Power BI with our course, covering data visualization, report creation, and interactive dashboards to help you make data-driven decisions.',
        detailedDescription: [
            {
                heading: <strong>About Course</strong>,
                paragraph: "The Power BI course offers comprehensive training on Microsoft's Power BI, a powerful tool for data visualization and business intelligence. Learn to create interactive reports, dashboards, and data models to gain insights from your data. The course covers essential features and best practices for effective data analysis and reporting."
            },
            {
                heading: <strong>Topics include</strong>,
                paragraph: (
                    <>
                        <h4>Introduction to Power BI</h4>
                        <ul>
                            <li>Overview of Power BI</li>
                            <li>Power BI Desktop and Power BI Service</li>
                            <li>Connecting to Data Sources</li>
                        </ul>
                        <h4>Data Transformation and Modeling</h4>
                        <ul>
                            <li>Data Cleaning and Transformation with Power Query</li>
                            <li>Creating Data Models and Relationships</li>
                            <li>Using DAX for Calculations and Measures</li>
                        </ul>
                        <h4>Data Visualization</h4>
                        <ul>
                            <li>Creating and Customizing Visualizations</li>
                            <li>Building Interactive Dashboards</li>
                            <li>Using Filters and Slicers</li>
                        </ul>
                        <h4>Advanced Features</h4>
                        <ul>
                            <li>Sharing and Publishing Reports</li>
                            <li>Creating Power BI Apps</li>
                            <li>Data Refresh and Security</li>
                        </ul>
                        <h4>Project Work</h4>
                        <ul>
                            <li>Developing an Interactive Dashboard</li>
                            <li>Creating a Comprehensive Report for Business Analysis</li>
                        </ul>
                        <h4>Duration: 2 months</h4>
                    </>
                ),
            },
        ],
        duration: '2 months',
        highlights: ['Expert Trainers', 'Hands-on Labs', 'Data Visualization Skills', 'Certification Provided'],
      }
    ,  
    
{
    id: 'php',
    title: 'PHP',
    image: '/path/to/php-course-image.jpg',
    description: 'Master PHP development with our comprehensive course, covering the basics to advanced concepts of PHP programming.',
    detailedDescription: [
      {
          heading: <strong>About Course</strong>,
          paragraph: "The PHP course at Excerpt IT Training Services is designed to provide a strong foundation in server-side scripting and web application development using PHP. Learn to build dynamic websites, integrate with databases, and develop robust web solutions. The course includes practical labs and projects to enhance your coding skills and application development. Excerpt IT Training Services offers lifetime access to course materials and additional practice coaching. Our certification will validate your PHP skills and advance your career in web development."
      },
      {
          heading: <strong>Topics include</strong>,
          paragraph: (
              <>
                  <h4>PHP Basics</h4>
                  <ul>
                      <li>Introduction to PHP</li>
                      <li>PHP Syntax and Operators</li>
                      <li>Variables and Data Types</li>
                      <li>Control Structures</li>
                      <li>Functions and Arrays</li>
                  </ul>
                  <h4>Forms and Sessions</h4>
                  <ul>
                      <li>Handling Form Data</li>
                      <li>Working with Sessions and Cookies</li>
                      <li>File Uploads</li>
                  </ul>
                  <h4>Advanced PHP</h4>
                  <ul>
                      <li>Object-Oriented Programming (OOP)</li>
                      <li>Working with Databases (MySQL)</li>
                      <li>Database CRUD Operations</li>
                      <li>Security Best Practices</li>
                  </ul>
                  <h4>Project Work</h4>
                  <ul>
                      <li>Building a Dynamic Website</li>
                      <li>Developing a Web Application with User Authentication</li>
                  </ul>
                  <h4>Duration: 3 months</h4>
              </>
          ),
      },
  ],
    duration: '3 months',
    highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
  }
  ,
  {
    id: 'Mysql',
    title: 'MySQL',
    image: '/path/to/mysql-course-image.jpg',
    description: 'Master MySQL database management with our comprehensive course, covering the basics to advanced concepts of MySQL.',
    detailedDescription: [
      {
          heading: <strong>About Course</strong>,
          paragraph: "The MySQL course at Excerpt IT Training Services provides comprehensive training on database design, SQL queries, and data management using MySQL. Learn to create and manage databases, write complex SQL queries, and handle data efficiently. The course includes hands-on projects to build practical skills in database management. Excerpt IT Training Services ensures lifetime access to course materials and additional coaching to support your learning. Our certification will enhance your credentials and advance your career in database administration."
      },
      {
          heading: <strong>Topics include</strong>,
          paragraph: (
              <>
                  <h4>Introduction to MySQL</h4>
                  <ul>
                      <li>Overview of MySQL</li>
                      <li>Installing and Configuring MySQL</li>
                      <li>Basic MySQL Commands</li>
                  </ul>
                  <h4>SQL Basics</h4>
                  <ul>
                      <li>Writing SQL Queries</li>
                      <li>Data Types and Operators</li>
                      <li>Filtering and Sorting Data</li>
                      <li>Joins and Subqueries</li>
                  </ul>
                  <h4>Database Design and Management</h4>
                  <ul>
                      <li>Creating and Modifying Tables</li>
                      <li>Normalization and Schema Design</li>
                      <li>Indexes and Views</li>
                      <li>Stored Procedures and Triggers</li>
                  </ul>
                  <h4>Advanced Topics</h4>
                  <ul>
                      <li>Transaction Management</li>
                      <li>Backup and Recovery</li>
                      <li>Performance Tuning</li>
                      <li>Security Best Practices</li>
                  </ul>
                  <h4>Project Work</h4>
                  <ul>
                      <li>Designing a Database Schema</li>
                      <li>Developing a Database-Driven Application</li>
                  </ul>
                  <h4>Duration: 2 months</h4>
              </>
          ),
      },
  ],
    duration: '3 months',
    highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
  }
  ,
  {
    id: 'Ethical-hacking',
    title: 'Ethical Hacking',
    image: '/path/to/ethical-hacking-course-image.jpg',
    description: 'Master ethical hacking techniques with our comprehensive course, covering the basics to advanced concepts of cybersecurity.',
    detailedDescription: [
      {
          heading: <strong>About Course</strong>,
          paragraph: "The Ethical Hacking course is designed to provide a comprehensive understanding of cybersecurity and ethical hacking techniques. Learn how to identify and exploit security vulnerabilities, perform penetration testing, and use various tools and techniques to secure systems. The course prepares you for a career in cybersecurity with practical skills and real-world scenarios."
      },
      {
          heading: <strong>Topics include</strong>,
          paragraph: (
              <>
                  <h4>Introduction to Ethical Hacking</h4>
                  <ul>
                      <li>Overview of Ethical Hacking</li>
                      <li>Ethical Hacking vs. Cybercrime</li>
                      <li>Legal and Ethical Considerations</li>
                  </ul>
                  <h4>Reconnaissance and Scanning</h4>
                  <ul>
                      <li>Information Gathering Techniques</li>
                      <li>Network Scanning and Enumeration</li>
                      <li>Vulnerability Scanning</li>
                  </ul>
                  <h4>Exploitation and Post-Exploitation</h4>
                  <ul>
                      <li>Exploiting Vulnerabilities</li>
                      <li>Creating and Using Exploits</li>
                      <li>Post-Exploitation Techniques</li>
                      <li>Maintaining Access and Covering Tracks</li>
                  </ul>
                  <h4>Tools and Techniques</h4>
                  <ul>
                      <li>Using Metasploit Framework</li>
                      <li>Network Sniffing Tools</li>
                      <li>Password Cracking Tools</li>
                      <li>Social Engineering</li>
                  </ul>
                  <h4>Legal and Ethical Aspects</h4>
                  <ul>
                      <li>Understanding Cyber Laws</li>
                      <li>Ethical Hacking Methodologies</li>
                      <li>Professional Conduct and Responsibilities</li>
                  </ul>
                  <h4>Project Work</h4>
                  <ul>
                      <li>Conducting a Penetration Test</li>
                      <li>Preparing a Security Assessment Report</li>
                  </ul>
                  <h4>Duration: 3 months</h4>
              </>
          ),
      },
  ],
    duration: '4 months',
    highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
  }
  ,
  {
    id: 'Selenium-testing',
    title: 'Selenium Testing',
    image: '/path/to/selenium-testing-course-image.jpg',
    description: 'Master Selenium testing with our comprehensive course, covering the basics to advanced concepts of automated testing.',
    detailedDescription: [
      {
          heading: <strong>About Course</strong>,
          paragraph: "The Selenium Testing course provides an in-depth understanding of Selenium, a popular tool for automated testing of web applications. The course covers Selenium WebDriver, test automation frameworks, and best practices for creating and managing automated test scripts. Gain practical experience with real-world testing scenarios and enhance your skills in software quality assurance."
      },
      {
          heading: <strong>Topics include</strong>,
          paragraph: (
              <>
                  <h4>Introduction to Selenium</h4>
                  <ul>
                      <li>Overview of Selenium</li>
                      <li>Selenium Components and Architecture</li>
                      <li>Setting Up Selenium Environment</li>
                  </ul>
                  <h4>Selenium WebDriver</h4>
                  <ul>
                      <li>Introduction to WebDriver</li>
                      <li>Locating Elements and Interacting with Web Elements</li>
                      <li>Handling Alerts, Frames, and Windows</li>
                      <li>Waits and Synchronization</li>
                  </ul>
                  <h4>Test Automation Frameworks</h4>
                  <ul>
                      <li>Introduction to TestNG</li>
                      <li>Creating and Managing Test Suites</li>
                      <li>Data-Driven Testing</li>
                      <li>Page Object Model (POM)</li>
                  </ul>
                  <h4>Advanced Topics</h4>
                  <ul>
                      <li>Handling Dynamic Web Elements</li>
                      <li>Integration with CI/CD Tools</li>
                      <li>Reporting and Logging</li>
                      <li>Performance and Load Testing</li>
                  </ul>
                  <h4>Project Work</h4>
                  <ul>
                      <li>Developing a Test Automation Framework</li>
                      <li>Creating and Executing Automated Test Scripts</li>
                  </ul>
                  <h4>Duration: 2 months</h4>
              </>
          ),
      },
  ],
    duration: '3 months',
    highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
  }
  ,
  {
    id: 'Basic-ms-office',
    title: 'Basic MS Office',
    image: '/path/to/basic-ms-office-course-image.jpg',
    description: 'Learn the fundamentals of Microsoft Office applications with our course, covering Word, Excel, PowerPoint, and more.',
    detailedDescription: [
      {
          heading: <strong>About Course</strong>,
          paragraph: "The Basic MS Office course is designed to help you acquire fundamental skills in Microsoft Office applications. Learn to use Word for document creation, Excel for data analysis, and PowerPoint for presentations. This course is ideal for beginners who want to enhance their productivity and office skills."
      },
      {
          heading: <strong>Topics include</strong>,
          paragraph: (
              <>
                  <h4>Microsoft Word</h4>
                  <ul>
                      <li>Creating and Formatting Documents</li>
                      <li>Working with Text and Paragraphs</li>
                      <li>Inserting and Formatting Images</li>
                      <li>Using Tables and Charts</li>
                  </ul>
                  <h4>Microsoft Excel</h4>
                  <ul>
                      <li>Introduction to Spreadsheets</li>
                      <li>Basic Formulas and Functions</li>
                      <li>Data Sorting and Filtering</li>
                      <li>Creating Charts and Graphs</li>
                  </ul>
                  <h4>Microsoft PowerPoint</h4>
                  <ul>
                      <li>Creating and Designing Slides</li>
                      <li>Adding and Formatting Text</li>
                      <li>Inserting Images and Media</li>
                      <li>Creating Slide Transitions and Animations</li>
                  </ul>
                  <h4>Project Work</h4>
                  <ul>
                      <li>Creating a Word Document with Formatting</li>
                      <li>Developing an Excel Spreadsheet with Formulas</li>
                      <li>Designing a PowerPoint Presentation</li>
                  </ul>
                  <h4>Duration: 1 month</h4>
              </>
          ),
      },
  ],
    duration: '1 month',
    highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
  }
  ,
  {
    id: 'Advanced-excel',
    title: 'Advanced Excel',
    image: '/path/to/advanced-excel-course-image.jpg',
    description: 'Enhance your Excel skills with our Advanced Excel course, focusing on complex functions, data analysis, and automation techniques.',
    detailedDescription: [
      {
          heading: <strong>About Course</strong>,
          paragraph: "The Advanced Excel course is designed for professionals looking to deepen their Excel skills. Learn advanced functions, data analysis techniques, and automation tools to handle complex data tasks efficiently. This course helps you leverage Excel's full potential for business analysis and decision-making."
      },
      {
          heading: <strong>Topics include</strong>,
          paragraph: (
              <>
                  <h4>Advanced Formulas and Functions</h4>
                  <ul>
                      <li>Array Formulas</li>
                      <li>Lookup Functions (VLOOKUP, HLOOKUP, INDEX, MATCH)</li>
                      <li>Conditional Formulas</li>
                      <li>Text Functions and Date Functions</li>
                  </ul>
                  <h4>Data Analysis and PivotTables</h4>
                  <ul>
                      <li>Creating and Customizing PivotTables</li>
                      <li>Pivot Charts and Reports</li>
                      <li>Data Analysis Toolpak</li>
                      <li>What-If Analysis (Data Tables, Goal Seek, Solver)</li>
                  </ul>
                  <h4>Automation and Scripting</h4>
                  <ul>
                      <li>Introduction to Macros and VBA</li>
                      <li>Recording and Editing Macros</li>
                      <li>Creating Custom Functions and Forms</li>
                      <li>Automating Repetitive Tasks</li>
                  </ul>
                  <h4>Advanced Data Visualization</h4>
                  <ul>
                      <li>Creating Complex Charts (Combo Charts, Sparklines)</li>
                      <li>Using Conditional Formatting</li>
                      <li>Dynamic Dashboards and Data Models</li>
                  </ul>
                  <h4>Project Work</h4>
                  <ul>
                      <li>Building a Complex Financial Model</li>
                      <li>Creating a Data Dashboard</li>
                  </ul>
                  <h4>Duration: 2 months</h4>
              </>
          ),
      },
  ],
    duration: '2 months',
    highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
  }
  ,
  {
    id: 'Tally-erp9-with-gst',
    title: 'TALLY ERP9 with GST',
    image: '/path/to/tally-erp9-with-gst-course-image.jpg',
    description: 'Master TALLY ERP9 with GST for effective accounting and financial management with our comprehensive course covering all essential features.',
    detailedDescription: [
      {
          heading: <strong>About Course</strong>,
          paragraph: "The Tally ERP 9 with GST course provides comprehensive training on Tally ERP 9, a leading accounting software. Learn to manage accounting, inventory, and taxation with a focus on GST compliance. The course is designed to equip you with practical skills to handle financial tasks efficiently using Tally ERP 9."
      },
      {
          heading: <strong>Topics include</strong>,
          paragraph: (
              <>
                  <h4>Introduction to Tally ERP 9</h4>
                  <ul>
                      <li>Overview of Tally ERP 9</li>
                      <li>Setting Up and Configuration</li>
                      <li>Basic Features and Navigation</li>
                  </ul>
                  <h4>Accounting Fundamentals</h4>
                  <ul>
                      <li>Creating and Managing Ledgers</li>
                      <li>Recording Transactions</li>
                      <li>Generating Financial Statements</li>
                  </ul>
                  <h4>Inventory Management</h4>
                  <ul>
                      <li>Creating Stock Items and Categories</li>
                      <li>Managing Purchase and Sales Orders</li>
                      <li>Stock Valuation and Reports</li>
                  </ul>
                  <h4>GST Compliance</h4>
                  <ul>
                      <li>Understanding GST Regulations</li>
                      <li>Configuring GST in Tally</li>
                      <li>Generating GST Invoices and Reports</li>
                      <li>Filing GST Returns</li>
                  </ul>
                  <h4>Advanced Features</h4>
                  <ul>
                      <li>Customizing Tally Reports</li>
                      <li>Using Tally for Payroll Management</li>
                      <li>Data Backup and Restoration</li>
                  </ul>
                  <h4>Project Work</h4>
                  <ul>
                      <li>Setting Up a Complete Accounting System</li>
                      <li>Generating GST-Compliant Financial Reports</li>
                  </ul>
                  <h4>Duration: 2 months</h4>
              </>
          ),
      },
  ],
    duration: '2 months',
    highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
  }
  ,
  {
    id: 'Sap-fico',
    title: 'SAP FICO',
    image: '/path/to/sap-fico-course-image.jpg',
    description: 'Gain expertise in SAP FICO, the financial accounting and controlling module, with our comprehensive course designed to enhance your skills in managing financial operations and reporting.',
    detailedDescription: [
      {
          heading: <strong>About Course</strong>,
          paragraph: "The SAP FICO course provides in-depth training in SAP Financial Accounting (FI) and Controlling (CO) modules. Learn to manage financial transactions, reporting, and internal controls within SAP ERP. The course is designed to help you master the essential functionalities of SAP FICO for efficient business management."
      },
      {
          heading: <strong>Topics include</strong>,
          paragraph: (
              <>
                  <h4>Introduction to SAP FICO</h4>
                  <ul>
                      <li>Overview of SAP FICO</li>
                      <li>SAP Architecture and Navigation</li>
                      <li>Integration with Other SAP Modules</li>
                  </ul>
                  <h4>Financial Accounting (FI)</h4>
                  <ul>
                      <li>General Ledger Accounting</li>
                      <li>Accounts Payable and Receivable</li>
                      <li>Asset Accounting</li>
                      <li>Bank Accounting and Reconciliation</li>
                  </ul>
                  <h4>Controlling (CO)</h4>
                  <ul>
                      <li>Cost Element Accounting</li>
                      <li>Cost Center and Profit Center Accounting</li>
                      <li>Internal Orders</li>
                      <li>Profitability Analysis</li>
                  </ul>
                  <h4>Reporting and Analysis</h4>
                  <ul>
                      <li>Financial Reports and Statements</li>
                      <li>Cost Analysis Reports</li>
                      <li>Custom Report Creation</li>
                  </ul>
                  <h4>Project Work</h4>
                  <ul>
                      <li>Implementing SAP FICO Modules in a Business Scenario</li>
                      <li>Generating Comprehensive Financial Reports</li>
                  </ul>
                  <h4>Duration: 3 months</h4>
              </>
          ),
      },
  ],
    duration: '4 months',
    highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
  },
 
    {
        id: 'C-sharp',
        title: 'C#',
        image: '/path/to/csharp-course-image.jpg',
        description: 'Master C# programming with our comprehensive course, covering the basics to advanced concepts of C# development.',
        detailedDescription: [
          {
              heading: <strong>About Course</strong>,
              paragraph: "The C# course at Excerpt IT Training Services offers a comprehensive introduction to C# programming, focusing on object-oriented principles and .NET integration. Learn to develop efficient and scalable applications using C#, covering topics such as data structures, algorithms, and application design. The course includes hands-on projects to solidify your understanding and practical skills. Excerpt IT Training Services ensures lifetime access to course materials and additional support through practice sessions. Our certification will validate your expertise and enhance your career prospects in C# programming."
          },
          {
              heading: <strong>Topics include</strong>,
              paragraph: (
                  <>
                      <h4>C# Basics</h4>
                      <ul>
                          <li>Introduction to C#</li>
                          <li>C# Syntax and Structure</li>
                          <li>Data Types and Variables</li>
                          <li>Operators and Expressions</li>
                          <li>Control Flow Statements</li>
                          <li>Exception Handling</li>
                      </ul>
                      <h4>Object-Oriented Programming</h4>
                      <ul>
                          <li>Classes and Objects</li>
                          <li>Inheritance</li>
                          <li>Polymorphism</li>
                          <li>Abstraction</li>
                          <li>Encapsulation</li>
                      </ul>
                      <h4>Advanced C#</h4>
                      <ul>
                          <li>Collections and Generics</li>
                          <li>LINQ (Language Integrated Query)</li>
                          <li>Asynchronous Programming with Async/Await</li>
                          <li>File I/O and Serialization</li>
                          <li>Working with Databases</li>
                      </ul>
                      <h4>Project Work</h4> <ul> <li>Developing a Windows Forms Application in C#</li> <li>Creating a C# Console Application for File Management</li> </ul>
                      <h4>Duration: 3 months</h4>
                  </>
              ),
          },
      ],
        duration: '3 months',
        highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
      }
      ,
    {
        id: 'Dot-NET',
        title: '.NET',
        image: '/path/to/dotnet-course-image.jpg',
        description: 'Master .NET development with our comprehensive course, covering the basics to advanced concepts of .NET programming.',
        detailedDescription: [
            {
                heading: <strong>About Course</strong>,
                paragraph: "The .NET course at Excerpt IT Training Services covers essential aspects of .NET development, including C#, ASP.NET, and the .NET framework. You'll learn to build robust and scalable applications, from desktop software to web applications. The course includes practical training with real-world projects to enhance your development skills. Excerpt IT Training Services provides lifetime access to course materials and additional coaching to support your learning journey. Our certification will boost your credentials and help advance your career in .NET development."
            },
            {
                heading: <strong>Topics include</strong>,
                paragraph: (
                    <>
                        <h4>Introduction to .NET</h4>
                        <ul>
                            <li>Overview of .NET Framework and .NET Core</li>
                            <li>Understanding the Common Language Runtime (CLR)</li>
                            <li>Basic Concepts of Object-Oriented Programming</li>
                            <li>Setting Up the Development Environment</li>
                        </ul>
                        <h4>C# Programming</h4>
                        <ul>
                            <li>Introduction to C#</li>
                            <li>Data Types, Variables, and Operators</li>
                            <li>Control Flow Statements</li>
                            <li>Object-Oriented Programming (OOP) Concepts</li>
                            <li>Exception Handling</li>
                            <li>LINQ and Collections</li>
                        </ul>
                        <h4>ASP.NET for Web Development</h4>
                        <ul>
                            <li>Introduction to ASP.NET</li>
                            <li>Building Web Applications with ASP.NET MVC</li>
                            <li>ASP.NET Web API for RESTful Services</li>
                            <li>Data Access with Entity Framework</li>
                            <li>Razor Pages and Blazor</li>
                        </ul>
                        <h4>.NET Core and Cross-Platform Development</h4>
                        <ul>
                            <li>Introduction to .NET Core</li>
                            <li>Building Cross-Platform Applications</li>
                            <li>Dependency Injection and Middleware</li>
                            <li>Configuration and Logging</li>
                            <li>Deploying .NET Core Applications</li>
                        </ul>
                        <h4>Advanced Topics</h4>
                        <ul>
                            <li>Asynchronous Programming with Async/Await</li>
                            <li>Microservices Architecture</li>
                            <li>Security Best Practices</li>
                            <li>Unit Testing and Test-Driven Development (TDD)</li>
                            <li>Performance Optimization</li>
                        </ul>
                        <h4>Hands-on Labs and Projects</h4>
                        <ul>
                            <li>Building a Web Application</li>
                            <li>Creating RESTful APIs</li>
                            <li>Implementing a Cross-Platform Solution</li>
                        </ul>
                        <h4>Project Work</h4> <ul> <li>Building an ASP.NET MVC Application</li> <li>Creating a Web API with .NET Core</li> </ul>
                        <h4>Duration: 3 months</h4>
                    </>
                ),
            },
        ],
        duration: '4 months',
        highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
      },
    {
        id: 'AWS',
        title: 'AWS',
        image: '/path/to/aws-course-image.jpg',
        description: 'Master Amazon Web Services with our comprehensive course, covering the basics to advanced concepts of AWS cloud computing.',
        detailedDescription: [
            {
                heading: <strong>About Course</strong>,
                paragraph: "The AWS course at Excerpt IT Training Services provides in-depth knowledge of Amazon Web Services, focusing on cloud computing fundamentals, AWS services, and architecture best practices. Learn to deploy, manage, and scale applications using AWS tools and services, including EC2, S3, RDS, and Lambda. The course includes practical labs and projects to give you hands-on experience with AWS. Excerpt IT Training Services ensures lifetime access to course content and additional practice sessions to support your learning. Our certification will validate your expertise and enhance your career in cloud computing."
            },
          {
              heading: <strong>Topics include</strong>,
              paragraph: (
                  <>
                      <h4>Introduction to AWS</h4>
                      <ul>
                          <li>Overview of Cloud Computing</li>
                          <li>Introduction to AWS and Cloud Services</li>
                          <li>AWS Global Infrastructure</li>
                          <li>AWS Management Console and CLI</li>
                      </ul>
                      <h4>Core AWS Services</h4>
                      <ul>
                          <li>Compute Services (EC2, Lambda, ECS, EKS)</li>
                          <li>Storage Services (S3, EBS, Glacier)</li>
                          <li>Database Services (RDS, DynamoDB, Aurora)</li>
                          <li>Networking Services (VPC, Route 53, CloudFront)</li>
                          <li>Security and IAM</li>
                      </ul>
                      <h4>Cloud Architecture and Design</h4>
                      <ul>
                          <li>Architectural Best Practices</li>
                          <li>High Availability and Fault Tolerance</li>
                          <li>Scalability and Load Balancing</li>
                          <li>Cost Optimization Strategies</li>
                      </ul>
                      <h4>DevOps and Automation</h4>
                      <ul>
                          <li>AWS CloudFormation</li>
                          <li>AWS Elastic Beanstalk</li>
                          <li>Continuous Integration and Deployment (CI/CD)</li>
                          <li>Monitoring and Logging (CloudWatch, CloudTrail)</li>
                      </ul>
                      <h4>Advanced Topics</h4>
                      <ul>
                          <li>Advanced Networking Concepts</li>
                          <li>Machine Learning and AI Services</li>
                          <li>Serverless Architectures</li>
                          <li>Disaster Recovery and Backup</li>
                      </ul>
                      <h4>Hands-on Labs and Projects</h4>
                      <ul>
                          <li>Deploying a Web Application</li>
                          <li>Setting Up a Scalable Database</li>
                          <li>Implementing Security Best Practices</li>
                      </ul>
                      <h4>Project Work</h4> <ul> <li>Deploying a Web Application on AWS</li> <li>Setting Up a Scalable AWS Infrastructure</li> </ul>
                      <h4>Duration: 3 months</h4>
                  </>
              ),
          },
      ],
        duration: '3 months',
        highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
      }
      ,
    {
        id: 'C-Plus-Plus',
        title: 'C++ Programming',
        image: '/path/to/cpp-course-image.jpg',
        description: 'Master C++ programming with our comprehensive course, covering the basics to advanced concepts of C++ development.',
        detailedDescription: [
            {
                heading: <strong>About Course</strong>,
                paragraph: "The C++ course at Excerpt IT Training Services provides a comprehensive overview of the C++ programming language, from basic syntax to advanced features. You'll learn object-oriented programming, data structures, algorithms, and best practices for writing efficient C++ code. The course emphasizes hands-on programming experience with real-world projects to build your skills in developing high-performance applications. Excerpt IT Training Services offers lifetime access to course materials and additional support through practice sessions and coaching. Our certification will validate your skills and advance your career in C++ programming."
            },
          {
              heading: <strong>Topics include</strong>,
              paragraph: (
                  <>
                      <h4>C++ Basics</h4>
                      <ul>
                          <li>Introduction to C++</li>
                          <li>C++ Syntax and Structure</li>
                          <li>Data Types and Variables</li>
                          <li>Operators and Expressions</li>
                          <li>Control Flow Statements</li>
                          <li>Functions and Scope</li>
                      </ul>
                      <h4>Object-Oriented Programming</h4>
                      <ul>
                          <li>Classes and Objects</li>
                          <li>Inheritance</li>
                          <li>Polymorphism</li>
                          <li>Abstraction</li>
                          <li>Encapsulation</li>
                      </ul>
                      <h4>Advanced C++ Concepts</h4>
                      <ul>
                          <li>Templates and Generics</li>
                          <li>Exception Handling</li>
                          <li>File Handling</li>
                          <li>Dynamic Memory Management</li>
                          <li>Standard Template Library (STL)</li>
                      </ul>
                      <h4>Data Structures and Algorithms</h4>
                      <ul>
                          <li>Arrays and Vectors</li>
                          <li>Stacks and Queues</li>
                          <li>Linked Lists</li>
                          <li>Trees and Graphs</li>
                          <li>Sorting and Searching Algorithms</li>
                      </ul>
                      <h4>Advanced Topics</h4>
                      <ul>
                          <li>Multithreading and Concurrency</li>
                          <li>Networking Basics</li>
                          <li>Design Patterns</li>
                          <li>Optimization Techniques</li>
                      </ul>
                      <h4>Capstone Project</h4>
                      <ul>
                          <li>Developing a Comprehensive C++ Application</li>
                          <li>Project Planning and Implementation</li>
                          <li>Testing and Debugging</li>
                      </ul><h4>Project Work</h4> <ul> <li>Developing a Simple Banking System in C++</li> <li>Creating a C++ Game Using SFML Library</li> </ul>
                      <h4>Duration: 3 months</h4>
                  </>
              ),
          },
      ],
        duration: '3 months',
        highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
      }
      ,
    {
        id: 'Python-FullStack-Development',
        title: 'Python Full Stack',
        image: '/path/to/Python-FullStack-Development-course-image.jpg',
        description: 'Become a proficient full stack developer with our Python Full Stack Development course, covering both front-end and back-end technologies using Python.',
        detailedDescription: [
            {
                heading: <strong>About Course</strong>,
                paragraph: "The Python Full Stack Development course at Excerpt IT Training Services is designed for those looking to become proficient in both front-end and back-end web development. The course covers Python-based back-end technologies, including Django and Flask, along with front-end technologies like HTML, CSS, and JavaScript. You'll learn to build and deploy full-stack applications, integrating various technologies to create robust web solutions. Practical, hands-on projects are a key component of this course, providing real-world experience in full-stack development. Excerpt IT Training Services ensures you have lifetime access to course content and additional practice opportunities, with certification to enhance your career prospects in web development."
            },
          {
              heading: <strong>Topics include</strong>,
              paragraph: (
                  <>
                      <h4>Introduction to Full Stack Development</h4>
                      <ul>
                          <li>Overview of Full Stack Development</li>
                          <li>Understanding Front-End and Back-End Technologies</li>
                          <li>Introduction to Python in Web Development</li>
                      </ul>
                      <h4>Front-End Development</h4>
                      <ul>
                          <li>HTML and CSS Basics</li>
                          <li>JavaScript Fundamentals</li>
                          <li>Front-End Frameworks (e.g., React, Vue.js)</li>
                          <li>Responsive Design and UI/UX Principles</li>
                      </ul>
                      <h4>Back-End Development with Python</h4>
                      <ul>
                          <li>Introduction to Django/Flask</li>
                          <li>Building RESTful APIs</li>
                          <li>Database Integration with ORM</li>
                          <li>User Authentication and Authorization</li>
                      </ul>
                      <h4>Database Management</h4>
                      <ul>
                          <li>Introduction to Databases</li>
                          <li>SQL Basics and Advanced Queries</li>
                          <li>Database Design and Normalization</li>
                          <li>Working with NoSQL Databases (e.g., MongoDB)</li>
                      </ul>
                      <h4>Deployment and DevOps</h4>
                      <ul>
                          <li>Introduction to Deployment</li>
                          <li>Hosting and Cloud Services (e.g., AWS, Heroku)</li>
                          <li>Continuous Integration/Continuous Deployment (CI/CD)</li>
                          <li>Docker Basics and Containerization</li>
                      </ul>
                      <h4>Capstone Project</h4>
                      <ul>
                          <li>Designing and Implementing a Full-Stack Application</li>
                          <li>Project Planning and Management</li>
                          <li>Deploying and Presenting the Final Project</li>
                      </ul>
                      <h4>Project Work</h4> <ul> <li>Building a Full-Stack Web Application with Django</li> <li>Creating a RESTful API with Flask and SQLAlchemy</li> </ul>
                      <h4>Duration: 4 months</h4>
                  </>
              ),
          },
      ],
        duration: '4 months',
        highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
      },

    {
        id: 'Data-Science-with-Python',
        title: 'Data Science with Python',
        image: '/path/to/data-science-python-course-image.jpg',
        description: 'Unlock the power of data with our Data Science with Python course, covering data analysis, machine learning, and data visualization.',
        detailedDescription: [
            {
                heading: <strong>About Course</strong>,
                paragraph: "Data Science with Python is an advanced course designed to equip you with the skills needed to analyze and interpret complex data. Our course at Excerpt IT Training Services covers a wide range of topics including data manipulation with Pandas, data visualization with Matplotlib and Seaborn, and machine learning with Scikit-Learn. You'll gain practical experience through hands-on projects and real-world data scenarios. The course emphasizes the application of Python in data science, providing a strong foundation in data analysis, statistical modeling, and machine learning. Excerpt IT Training Services offers lifetime access to course materials and additional coaching to support your learning journey. Our certification will boost your career prospects in the field of data science."
            },
          {
              heading: <strong>Topics include</strong>,
              paragraph: (
                  <>
                      <h4>Introduction to Data Science</h4>
                      <ul>
                          <li>Overview of Data Science</li>
                          <li>Data Science Process and Lifecycle</li>
                          <li>Introduction to Python for Data Science</li>
                      </ul>
                      <h4>Data Manipulation and Analysis</h4>
                      <ul>
                          <li>Data Cleaning and Preprocessing with Pandas</li>
                          <li>Exploratory Data Analysis (EDA)</li>
                          <li>Handling Missing Data and Outliers</li>
                          <li>Data Aggregation and Grouping</li>
                      </ul>
                      <h4>Data Visualization</h4>
                      <ul>
                          <li>Introduction to Matplotlib</li>
                          <li>Creating Plots and Graphs</li>
                          <li>Advanced Visualization Techniques with Seaborn</li>
                          <li>Interactive Visualizations with Plotly</li>
                      </ul>
                      <h4>Machine Learning Fundamentals</h4>
                      <ul>
                          <li>Introduction to Machine Learning</li>
                          <li>Supervised Learning Algorithms</li>
                          <li>Unsupervised Learning Algorithms</li>
                          <li>Model Evaluation and Validation</li>
                      </ul>
                      <h4>Advanced Machine Learning</h4>
                      <ul>
                          <li>Feature Engineering and Selection</li>
                          <li>Hyperparameter Tuning</li>
                          <li>Deep Learning Basics with TensorFlow/Keras</li>
                          <li>Natural Language Processing (NLP)</li>
                      </ul>
                      <h4>Project and Case Studies</h4>
                      <ul>
                          <li>Real-World Data Science Projects</li>
                          <li>Case Studies and Practical Applications</li>
                          <li>Building End-to-End Data Science Solutions</li>
                      </ul>
                      <h4>Project Work</h4> <ul> <li>Developing a Predictive Analytics Model</li> <li>Building a Machine Learning Model with Scikit-Learn</li> </ul>
                      <h4>Duration: 3 months</h4>
                  </>
              ),
          },
      ],
        duration: '4 months',
        highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
      },
    {
        id: 'Python',
        title: 'Python',
        image: '/path/to/python-course-image.jpg',
        description: 'Master Python programming with our comprehensive course, covering the basics to advanced concepts of Python development.',
        detailedDescription: [
            {
                heading: <strong>About Course</strong>,
                paragraph: "Python is a powerful, high-level programming language known for its simplicity and readability. Our Python course at Excerpt IT Training Services covers everything from basic syntax to advanced concepts, preparing you for a career in software development, data science, and more. The course includes in-depth training on Python's core features, data structures, algorithms, object-oriented programming, web frameworks like Django and Flask, and data analysis libraries like Pandas and NumPy. This training ensures you gain hands-on experience with real-world projects and develop the skills necessary for a successful career in Python programming. Excerpt IT Training Services offers a comprehensive learning experience with lifetime access to recorded tutorials and additional practice coaching. Our certificate will enhance your credentials and open doors to exciting career opportunities in Python development."
            },
          {
              heading: <strong>Topics include</strong>,
              paragraph: (
                  <>
                      <h4>Python Basics</h4>
                      <ul>
                          <li>Introduction to Python</li>
                          <li>Python Syntax and Structure</li>
                          <li>Data Types and Variables</li>
                          <li>Operators and Expressions</li>
                          <li>Control Flow Statements</li>
                          <li>Exception Handling</li>
                      </ul>
                      <h4>Object-Oriented Programming</h4>
                      <ul>
                          <li>Classes and Objects</li>
                          <li>Inheritance</li>
                          <li>Polymorphism</li>
                          <li>Encapsulation</li>
                          <li>Abstraction</li>
                      </ul>
                      <h4>Data Structures and Algorithms</h4>
                      <ul>
                          <li>Lists and Tuples</li>
                          <li>Sets and Dictionaries</li>
                          <li>Stacks and Queues</li>
                          <li>Linked Lists</li>
                          <li>Sorting and Searching Algorithms</li>
                      </ul>
                      <h4>Web Development</h4>
                      <ul>
                          <li>Introduction to Web Frameworks</li>
                          <li>Flask Basics</li>
                          <li>Django Basics</li>
                          <li>Building Web Applications</li>
                      </ul>
                      <h4>Data Analysis</h4>
                      <ul>
                          <li>Using Pandas for Data Manipulation</li>
                          <li>Data Visualization with Matplotlib</li>
                          <li>Introduction to NumPy</li>
                      </ul>
                      <h4>Advanced Topics</h4>
                      <ul>
                          <li>Python Decorators</li>
                          <li>Generators and Iterators</li>
                          <li>Concurrency with Asyncio</li>
                      </ul>
                      <h4>Project Work</h4> <ul> <li>Creating a Data Analysis Tool Using Pandas</li> <li>Building a Web Scraper with Python and BeautifulSoup</li> </ul>
                      <h4>Duration: 3 months</h4>
                  </>
              ),
          },
      ]
      ,
        duration: '3 months',
        highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
      }
      ,
    {
        id: 'Fullstack-Development',
        title: 'Full Stack Development',
        image: '/path/to/fullstack-development-course-image.jpg',
        description: 'Learn full stack development with our comprehensive course, covering both front-end and back-end technologies to build complete web applications.',
        detailedDescription: [
            {
                heading: <strong>About Course</strong>,
                paragraph: "Full Stack Development involves working on both the front-end and back-end of web applications. This course provides a comprehensive understanding of web development by covering key technologies and frameworks for both client-side and server-side development. You will learn about HTML, CSS, JavaScript, and modern front-end frameworks like React, as well as back-end technologies such as Node.js, Express, and MongoDB. Through hands-on projects and real-world scenarios, you'll gain the skills needed to build and deploy robust web applications."
            },
            {
                heading: <strong>Topics include</strong>,
                paragraph: (
                    <>
                        <h4>Front-End Development</h4>
                        <ul>
                            <li>HTML5 and CSS3 Basics</li>
                            <li>Responsive Web Design</li>
                            <li>JavaScript Fundamentals</li>
                            <li>DOM Manipulation</li>
                            <li>React Basics and Hooks</li>
                            <li>State Management with Redux</li>
                            <li>Front-End Build Tools (Webpack, Babel)</li>
                        </ul>
                        <h4>Back-End Development</h4>
                        <ul>
                            <li>Introduction to Node.js</li>
                            <li>Building RESTful APIs with Express</li>
                            <li>Database Design and MongoDB</li>
                            <li>Authentication and Authorization</li>
                            <li>Deploying Applications with Heroku</li>
                            <li>API Integration and Testing</li>
                        </ul>
                        <h4>Full Stack Integration</h4>
                        <ul>
                            <li>Connecting Front-End with Back-End</li>
                            <li>Handling Asynchronous Operations</li>
                            <li>State Management across Full Stack</li>
                            <li>Building and Deploying Full Stack Applications</li>
                            <li>Version Control with Git and GitHub</li>
                        </ul>
                        <h4>Project Work</h4> <ul> <li>Building a Full-Stack Blog Application</li> <li>Developing a Multi-Tenant SaaS Application</li> </ul>
                        <h4>Duration: 3 months</h4>
                    </>
                ),
            },
        ],
        duration: '3 months',
        highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
      }
      ,
    {
        id: 'Java',
        title: 'Java',
        image: '/path/to/java-course-image.jpg',
        description: 'Become proficient in Java programming with our comprehensive course, designed to cover the fundamentals to advanced concepts of Java development.',
        detailedDescription: [
          {
              heading: <strong>About Course</strong>,
              paragraph: "Java is a versatile, high-level programming language that is widely used for building enterprise-level applications, mobile apps, and web-based solutions. Our Java course covers fundamental concepts including object-oriented programming, data structures, algorithms, and advanced topics such as JavaFX and Spring Framework. The course is designed to provide hands-on experience through projects and assignments, preparing you for real-world programming challenges. Join us to gain a deep understanding of Java and enhance your programming skills for a successful career in software development."
          },
          {
              heading: <strong>Topics include</strong>,
              paragraph: (
                  <>
                      <h4>Java Basics</h4>
                      <ul>
                          <li>Introduction to Java</li>
                          <li>Java Syntax and Structure</li>
                          <li>Data Types and Variables</li>
                          <li>Operators and Expressions</li>
                          <li>Control Flow Statements</li>
                          <li>Exception Handling</li>
                      </ul>
                      <h4>Object-Oriented Programming</h4>
                      <ul>
                          <li>Classes and Objects</li>
                          <li>Inheritance</li>
                          <li>Polymorphism</li>
                          <li>Abstraction</li>
                          <li>Encapsulation</li>
                      </ul>
                      <h4>Data Structures and Algorithms</h4>
                      <ul>
                          <li>Arrays and Lists</li>
                          <li>Stacks and Queues</li>
                          <li>Linked Lists</li>
                          <li>Trees and Graphs</li>
                          <li>Sorting and Searching Algorithms</li>
                      </ul>
                      <h4>Advanced Java</h4>
                      <ul>
                          <li>JavaFX for GUI Development</li>
                          <li>Multithreading and Concurrency</li>
                          <li>Networking in Java</li>
                          <li>Java Streams and Lambdas</li>
                          <li>Spring Framework Basics</li>
                      </ul>
                      <h4>Project Work</h4> <ul> <li>Developing a Java-Based Inventory Management System</li> <li>Creating a JavaFX Desktop Application</li> </ul>
                      <h4>Duration: 3 months</h4>
                  </>
              ),
          },
      ],
        duration: '3 months',
        highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
      },
    {
        id: 'WebDesign',
        title: 'WebDesign',
        image: '/path/to/mern-stack-course-image.jpg',
        description: 'Master the Web Design Development with our comprehensive course',
        detailedDescription: [
            {
                heading: <h1>About Course</h1>,
                paragraph: "Web Design is basically used to design and develop static websites that are displayed on the Web Browser. It covers the appearance, layout, and content of the website. It also includes the complete part of CSS and JavaScript. Web Designing makes the visuals compelling for the user. Excerpt Tech IT Training Services online software training course provides an insight into the most used aspects of Web Design including the use of CSS, JavaScript, JQuery & various HTML tags. Web Designing comprises graphic design, color schemes, button design, interface design, Photoshop/Illustrator, navigational design, page architecture, and file preparation, etc. Creating a well-designed website is very important and it will only be considered perfect if the visitor is able to experience the same what they are looking for. Excerpt Tech IT Training Services gives a boost to students’ careers and encourages them to do Internships and work on projects so that they get more in-hand knowledge of the software. Excerpt Tech IT Training Services offers the best quality online software training and in addition to that students get free lifetime access to recorded video tutorials and login access to their student dashboard. Along with that, students also get offline practice coaching or revision classes at any of our franchise centers in India, even after course completion. Excerpt Tech IT Training Services certificate facilitates its students to escalate their career, and lead their way in the web designing industry."
            },
            {
                heading: "Topics include",
                paragraph: (
                    <>
                        <h4>HTML</h4>
                        <ul>
                            <li>Getting Started with HTML</li>
                            <li>Enhancing Web Pages</li>
                            <li>Working with Tables and Frames</li>
                            <li>Adding Interactivity to Web Pages</li>
                            <li>Creating Dynamic Web Pages</li>
                            <li>Working with Graphics</li>
                            <li>Adding Visual Effects to Web Pages</li>
                        </ul>
                        <h4>CSS</h4>
                        <ul>
                            <li>Types of CSS</li>
                            <li>Classes and Id's</li>
                            <li>Working with div</li>
                            <li>CSS Selectors and Properties</li>
                            <li>Background Properties</li>
                            <li>Page Layouts</li>
                            <li>Links and classes</li>
                            <li>Navigation Bar</li>
                            <li>Fonts and Text Effects</li>
                        </ul>
                        <h4>JavaScript</h4>
                        <ul>
                            <li>Identifying the Benefits of JavaScript</li>
                            <li>Using Variables, Operators, and Control Structures</li>
                            <li>JavaScript Objects</li>
                            <li>JavaScript Browser Objects</li>
                            <li>Creating Dynamic Web Pages</li>
                            <li>Java Script Events and Validation</li>
                        </ul>
                        <h4>Jquery</h4>
                        <ul>
                            <li>Jquery Effects</li>
                            <li>Jquery Selectors</li>
                            <li>Jquery Plugins</li>
                            <li>Handling Events</li>
                        </ul>
                        <h4>Bootstrap</h4>
                        <ul>
                            <li>Grid System</li>
                            <li>Typography</li>
                            <li>Code</li>
                            <li>Tables, Forms, Buttons</li>
                            <li>Images</li>
                            <li>Helper classes</li>
                            <li>Responsive utilities</li>
                        </ul>
                        <h4>Project Work</h4> <ul> <li>Designing a Responsive E-Commerce Website</li> <li>Creating a Portfolio Website with Custom Graphics</li> </ul>
                        <h4>Duration: 30 days</h4>
                    </>
                ),
            },
        ],
        duration: '3 months',
        highlights: ['Expert Faculty', 'Hands-on Training', 'Flexible Schedule', 'Certification Provided'],
    },
{
    id: 'Mern-Stack',
  title: 'MERN STACK',
  image: '/path/to/mern-stack-course-image.jpg',
  description: 'Master the MERN stack with our comprehensive course, covering MongoDB, Express.js, React, and Node.js for full-stack development.',
    detailedDescription: [
        {
            heading: <h1>About Course</h1>,
            paragraph: "The MERN stack is a powerful set of technologies for building dynamic web applications and services. It consists of MongoDB, Express.js, React.js, and Node.js, all of which are based on JavaScript. Our MERN Stack course covers everything you need to know to become a full-stack web developer, from front-end development with React to back-end development with Node.js and Express, and database management with MongoDB. Whether you're looking to build robust, scalable web applications or simply want to enhance your development skills, this course is perfect for you."
        },
        {
            heading: "Topics include",
            paragraph: (
                <>
                    <h4>MongoDB</h4>
                    <ul>
                        <li>Introduction to NoSQL Databases</li>
                        <li>CRUD Operations in MongoDB</li>
                        <li>Schema Design and Data Modeling</li>
                        <li>Indexing and Aggregation</li>
                        <li>Working with MongoDB Atlas</li>
                    </ul>
                    <h4>Express.js</h4>
                    <ul>
                        <li>Building RESTful APIs with Express</li>
                        <li>Middleware and Routing</li>
                        <li>Data Validation and Error Handling</li>
                        <li>Authentication and Authorization</li>
                        <li>Session Management and Cookies</li>
                    </ul>
                    <h4>React.js</h4>
                    <ul>
                        <li>React Components and JSX</li>
                        <li>State and Props</li>
                        <li>React Hooks</li>
                        <li>Managing Application State with Redux</li>
                        <li>Routing with React Router</li>
                        <li>Building Single Page Applications (SPAs)</li>
                    </ul>
                    <h4>Node.js</h4>
                    <ul>
                        <li>Introduction to Node.js</li>
                        <li>Asynchronous Programming with Callbacks, Promises, and Async/Await</li>
                        <li>Working with the File System</li>
                        <li>Building Command Line Tools</li>
                        <li>Deploying Node.js Applications</li>
                    </ul>
                    <h4>Project Work</h4> <ul> <li>Building a Full-Stack Social Media Application</li> <li>Developing a MERN Stack E-Commerce Platform</li> </ul>
                    <strong>Duration:</strong> 3 months
                </>
            ),
        },
    ],
    duration: '3 months',
    highlights: ['Expert Faculty', 'Hands-on Projects', 'Job Assistance', 'Certification Provided'],
},














];
const CourseInterestForm = () => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    state: 'Karnataka',
    center: 'Bangalore'
  });
  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new URLSearchParams();
    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value);
    }

    try {
      const response = await fetch('/api/course-interest', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: data.toString(),
      });

      const result = await response.json();
      console.log(response)
      if (response.status === 200) {
        setFormStatus("Your interest has been submitted successfully.");
        // Clear form data or reset form fields if needed
        setFormData({
          fname: '',
          lname: '',
          email: '',
          phone: '',
          state: 'Karnataka',
          center: 'Bangalore'
        });
      } else {
        setFormStatus("There was an error submitting your interest.");
      }
    } catch (error) {
      console.error("Error:", error);
      setFormStatus("There was an error submitting your interest.");
    }
  };

    return (
      //   <form onSubmit={handleSubmit}>
      //   <div className="form-row">
      //     <div className="form-group col-md-6">
      //       <label>First Name:</label>
      //       <input
      //         type="text"
      //         id="fname"
      //         name="fname"
      //         className="form-control"
      //         placeholder="Enter Your First Name"
      //         value={formData.fname}
      //         onChange={handleChange}
      //         required
      //       />
      //     </div>
      //     <div className="form-group col-md-6">
      //       <label>Last Name:</label>
      //       <input
      //         type="text"
      //         id="lname"
      //         name="lname"
      //         className="form-control"
      //         placeholder="Enter Your Last Name"
      //         value={formData.lname}
      //         onChange={handleChange}
      //         required
      //       />
      //     </div>
      //   </div>
      //   <div className="form-row">
      //     <div className="form-group col-md-6">
      //       <label>Email:</label>
      //       <input
      //         type="email"
      //         id="email"
      //         name="email"
      //         className="form-control"
      //         placeholder="Enter Your Email Address"
      //         value={formData.email}
      //         onChange={handleChange}
      //         required
      //       />
      //     </div>
      //     <div className="form-group col-md-6">
      //       <label>Mobile:</label>
      //       <input
      //         type="number"
      //         id="phone"
      //         name="phone"
      //         className="form-control"
      //         placeholder="Enter Your mobile number"
      //         value={formData.phone}
      //         onChange={handleChange}
      //         required
      //       />
      //     </div>
      //   </div>
      //   <div className="form-row">
      //     <div className="form-group col-md-6">
      //       <label>State:</label>
      //       <input
      //         type="text"
      //         id="state"
      //         name="state"
      //         className="form-control"
      //         value="Karnataka"
      //         readOnly
      //       />
      //     </div>
      //     <div className="form-group col-md-6">
      //       <label>Center:</label>
      //       <input
      //         type="text"
      //         id="center"
      //         name="center"
      //         className="form-control"
      //         value="Bangalore"
      //         readOnly
      //       />
      //     </div>
      //   </div>
      //   <div className="form-group mb-0">
      //     <button type="submit" id="btn" className="btn btn-primary contest_register_now" name="register_users">
      //       Apply Now
      //     </button>
      //   </div>
      // </form>
      <div>
      {formStatus && <p>{formStatus}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>First Name:</label>
            <input
              type="text"
              id="fname"
              name="fname"
              className="form-control"
              placeholder="Enter Your First Name"
              value={formData.fname}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label>Last Name:</label>
            <input
              type="text"
              id="lname"
              name="lname"
              className="form-control"
              placeholder="Enter Your Last Name"
              value={formData.lname}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Enter Your Email Address"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label>Mobile:</label>
            <input
              type="number"
              id="phone"
              name="phone"
              className="form-control"
              placeholder="Enter Your mobile number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>State:</label>
            <input
              type="text"
              id="state"
              name="state"
              className="form-control"
              value="Karnataka"
              readOnly
            />
          </div>
          <div className="form-group col-md-6">
            <label>Center:</label>
            <input
              type="text"
              id="center"
              name="center"
              className="form-control"
              value="Bangalore"
              readOnly
            />
          </div>
        </div>
        <div className="form-group mb-0">
          <button type="submit" id="btn" className="btn btn-primary contest_register_now" name="register_users">
            Apply Now
          </button>
        </div>
      </form>
    </div>
      
    );
  };
  
  const CoursePage = () => {
    const { courseId } = useParams();
    const course = coursesDataa.find(c => c.id === (courseId));
    
    if (!course) return <div>Course not found</div>;
  
    const highlights = Array.isArray(course.highlights) ? course.highlights : [];
    const detailedDescription = Array.isArray(course.detailedDescription) ? course.detailedDescription : [];
  
    return (
      <div>
        <div className="course-page">
          {/* Course Banner */}
          <div className="about-banner-wrap vision-space bg-img">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 section-space--pt_100">
                  <h1 style={{ color: "#fff" }}>{course.title}</h1>
                  <p style={{ color: "#fff" }}>{course.description}</p>
                  {/* Breadcrumb */}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">{course.title}</li>
                    </ol>
                  </nav>
                </div>
                <div className="col-lg-4 offset-lg-2 form-column">
                  <div className="form-container border" id="courseform">
                    <CourseInterestForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Training Highlights */}
          <div className="vision-overview">
            <div className="container">
              <div className="section-title-wrap text-center">
                <h3 style={{marginTop:"40px"}}><span className="text-color-primary" style={{ fontSize: "2.0rem" }}> Training Highlights</span></h3>
              </div>
              <div className="row">                {commonHighlights.map((highlight, index) => (
                  <div key={index} className="col-12 col-lg-4">
                    <div className="item-container d-flex align-items-center">
                      <div className="res-image-container" >
                        <img src={highlight.image} className="lazy-load" height="50px" width="50px" style={{ borderRadius: "5px", margin: "10px" }} alt={highlight.title} />
                      </div>
                      <div className="content-container">
                        <h3 className="title">{highlight.title}</h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Course Overview */}
          <div className="row">
            <div className="col-lg-12 col-12">
              <h2 className="heading mb-20" style={{ fontSize: "2.0rem" }}>Course Summary</h2>
              <div className="vision-content">
                {detailedDescription.map((item, index) => (
                  <div key={index}>
                    <h4>{item.heading}</h4>
                    <p>{item.paragraph}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Certification Info */}
          <div className="contact-us-section-wrapper">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="contact-us-wrap-one">
                    <h5 className="heading mb-20">How will you get your certificate?</h5>
                    <p style={{ textAlign: "justify" }}>
                      Excerpt Technologies IT Training Services is a pioneer in CS/IT training and most of the industries give great recognition to its certificate throughout India and abroad. Excerpt Technologies IT Training Services Certificates are ISO verified which makes it highly reputed comparing to the other local brands and helps the student get prioritized while going for job opportunities in the industry. Excerpt Technologies IT Training Services Software Training is conducted by highly knowledgeable trainers which help students to enhance their skill set by putting great emphasis on project-based learning.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="contact-form-wrap">
                    <img src={cert} style={{ width: "100%", height: "370px" }} alt="Certificate" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default CoursePage;