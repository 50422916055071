import React from "react";

const Ecommerce = () => {
  return (
    <div>
      <section
        style={{
          backgroundImage: "url(images/e01.jpg)",
          backgroundColor: "rgb(255, 255, 255)",
          backgroundSize: "cover", // or "contain" based on your preference
          backgroundPosition: "center center", // adjust as needed
          backgroundRepeat: "no-repeat",
        }}
        class="sub-header position-relative"
      >
        <div class="container">
          <div class="page-info position-absolute">
            <h2 class="heading-2">E-commerce </h2>
            <h6 class="heading-6">
              <a href="/">Home</a> / <a href="#">Ecommerce </a>
            </h6>
          </div>
        </div>
      </section>

      <section id="service-details" class="service-details">
        <div class="container">
          <div class="row g-4">
            <div class="col-md-8 mb-40">
              <div class="img-wrapper mb-25">
                <img src="images/ecommerce1.jpg" class="img-fluid" alt="" />
              </div>

              <h4 class="heading-4 mb-25">E-Commerce solutions.</h4>

              <div class="paragraph mb-40">
                An e-commerce website plays a crucial role in providing a
                comprehensive set of services to enhance the overall shopping
                experience for customers. At its core, an e-commerce company
                focuses on delivering a user-friendly platform that facilitates
                online transactions.
              </div>

              <div class="row g-4 mb-25">
                <div class="col-md-6">
                  <div class="img-wrapper">
                    <img
                      src="images/ecommerce2.jpg"
                      class="img-fluid sub"
                      alt=""
                      style={{ height: "300px" }}
                    />
                  </div>
                </div>
                <div class="d-none d-md-block col-md-6">
                  <div class="img-wrapper">
                    <img
                      src="images/ecommerce3.jpg"
                      class="img-fluid sub"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <h4 class="heading-3 mb-25">Manage designing Solutions</h4>

              <div class="paragraph mb-40">
                The transparency and clarity of return and refund policies
                contribute to building customer trust. E-commerce companies
                strive to make the return process user-friendly, with clear
                instructions available on the website.
              </div>

              <div class="service-divider"></div>
            </div>
            <div class="d-none d-md-block col-md-4">


              <div class="service">
                <h4 class="heading-3">Services</h4>

                <div class="underline"></div>

                <ul class="service-list">
                  <li class="active">
                    <a href="/webdesign">
                      <div class="d-flex justify-content-between item">
                        <span>Web Design and Development</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/mobileapplication">
                      <div class="d-flex justify-content-between item">
                        <span>MobileApplication</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Cybersecurity">
                      <div class="d-flex justify-content-between item">
                        <span>CyberSecurity</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/DigitalMarketing">
                      <div class="d-flex justify-content-between item">
                        <span>DigitalMarketing</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Seo">
                      <div class="d-flex justify-content-between item">
                        <span>SEOAnalytics</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Dataanalytics">
                      <div class="d-flex justify-content-between item">
                        <span>DataAnalytics</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>

                </ul>
              </div>
              <div class="squre-banner">
                <h2 class="heading-3">Easy solutions to your tech problem</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ecommerce;
