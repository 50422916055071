import React from "react";

const DigitalMarketing = () => {
  return (
    <div>
      <section
        style={{
          backgroundImage: "url(images/dm01.jpg)",
          backgroundColor: "rgb(255, 255, 255)",
        }}
        class="sub-header position-relative"
      >
        <div class="container">
          <div class="page-info position-absolute">
            <h2 class="heading-2" style={{color:'white',textAlign:'center'}}>Digital Marketing </h2>
            <h6 class="heading-6">
              <a href="/">Home</a> / <a href="#">Digital Marketing</a>
            </h6>
          </div>
        </div>
      </section>

      <section id="service-details" class="service-details">
        <div class="container">
          <div class="row g-4">
            <div class="col-md-8 mb-40">
              <div class="img-wrapper mb-25">
                <img src="images/dg.jpg" class="img-fluid" alt="" />
              </div>

              <h4 class="heading-4 mb-25">Digital Marketing Solutions</h4>

              <div class="paragraph mb-40">
              We provide bespoke digital marketing solutions to boost your brand's online visibility. With expertise in SEO, social media, and PPC, we craft strategic campaigns for optimal audience engagement and conversion rates. Utilizing data-driven methods, we guarantee maximum ROI across digital channels.

              </div>

              <div class="row g-4 mb-25">
                <div class="col-md-6">
                  <div class="img-wrapper">
                    <img
                      src="images/dg2.jpg"
                      class="img-fluid sub"
                      alt=""
                      style={{ height: "300px" }}
                    />
                  </div>
                </div>
                <div class="d-none d-md-block col-md-6">
                  <div class="img-wrapper">
                    <img src="images/dg3.jpg" class="img-fluid sub" alt="" />
                  </div>
                </div>
              </div>

              <h4 class="heading-3 mb-25" >Manage designing Solutions</h4>

              <div class="paragraph mb-40">
              We specialize in crafting visually captivating interfaces that embody your brand's essence. Our collaborative design approach ensures custom graphics, website designs, and multimedia content resonate with your audience, enhancing user experience and setting your brand apart in the digital arena.

              </div>

              <div class="service-divider"></div>
            </div>
            <div class="d-none d-md-block col-md-4">
              <div class="service">
                <h4 class="heading-3">Services</h4>

                <div class="underline"></div>

                <ul class="service-list">
                  <li class="active">
                    <a href="/webdesign">
                      <div class="d-flex justify-content-between item">
                        <span>Web Design and Development</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/mobileapplication">
                      <div class="d-flex justify-content-between item">
                        <span>MobileApplication</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Cybersecurity">
                      <div class="d-flex justify-content-between item">
                        <span>CyberSecurity</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/ecommerce">
                      <div class="d-flex justify-content-between item">
                        <span>Ecommerce</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Seo">
                      <div class="d-flex justify-content-between item">
                        <span>SEOAnalytics</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Dataanalytics">
                      <div class="d-flex justify-content-between item">
                        <span>DataAnalytics</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="squre-banner">
                <h2 class="heading-3">Easy solutions to your tech problem</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DigitalMarketing;
