


import React,{useEffect} from 'react';
import ReactGA from 'react-ga'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';


const ThankYouPage = () => {
  const redirectTo = (url) => {
    window.open(url, '_blank');
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
}, []);


  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.title}>Thank You!</h1>
        <p style={styles.message}>We've received your message and will respond within 24 hours.</p>
        {/* <p style={styles.additionalMessage}>We appreciate your feedback.</p> */}
       <br />
       <br />
       
   

       <button onClick={() => redirectTo('https://www.instagram.com/excerpt_technologies/?igsh=cjF6MnY3djNtZTY4')} className="social-button instagram" style={{marginLeft:"40px"}}>
        <FontAwesomeIcon icon={faInstagram} /> Follow us on Instagram
      </button>
      <br />

      <button onClick={() => redirectTo('https://www.facebook.com/Excerptechnology')} className="social-button facebook" style={{marginLeft:"40px"}}>
        <FontAwesomeIcon icon={faFacebook} /> Like us on Facebook
      </button>


      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: '#f0f0f0', // Light gray background
  },
  content: {
    textAlign: 'center',
    padding: '40px',
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)', // Box shadow for depth
    maxWidth: '400px',
    width: '100%',
  },
  title: {
    fontSize: '2.5rem',
    color: '#333', // Dark title color
    marginBottom: '20px',
  },
  message: {
    fontSize: '1.2rem',
    color: '#666', // Medium gray message color
    marginBottom: '30px',
  },
  additionalMessage: {
    fontSize: '1rem',
    color: '#888', // Light gray additional message color
  },
};

export default ThankYouPage;

