import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function CertificateDetail() {
    const { regNo } = useParams();
    const [student, setStudent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchStudent() {
            try {
                const response = await axios.get(`/api/courseStudents?regNo=${regNo}`);
                setStudent(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching student data:', error.message);
                setLoading(false);
            }
        }

        fetchStudent();
    }, [regNo]);

    if (loading) return <p>Loading...</p>;
    if (!student) return <p>No student found with Registration Number: {regNo}</p>;

    return (
        <div>
            <h2>Certificate Details for {student.name}</h2>
            <p><strong>Name:</strong> {student.name}</p>
            <p><strong>Course:</strong> {student.course}</p>
            <p><strong>Year of Passing:</strong> {student.yop}</p>
            <p><strong>Software Covered:</strong> {student.softwareCovered}</p>
            <p><strong>Registration Number:</strong> {student.regNo}</p>
            <p><strong>College Name:</strong> {student.collegeName}</p>
            <p><strong>From Date:</strong> {student.from}</p>
            <p><strong>To Date:</strong> {student.to}</p>
        </div>
    );
}

export default CertificateDetail;
