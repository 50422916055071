import React from "react";

const Mobileapp = () => {
  return (
    <div>
      <section
        style={{
          backgroundImage: "url(images/m01.jpg)",
          backgroundColor: "rgb(255, 255, 255)",
        }}
        class="sub-header position-relative"
      >
        <div class="container">
          <div class="page-info position-absolute">
            <h2 class="heading-2" style={{color:'white',textAlign:'center'}}>Mobile Application </h2>
            <h6 class="heading-6">
              <a href="/">Home</a> / <a href="#">Mobile Application </a>
            </h6>
          </div>
        </div>
      </section>

      <section id="service-details" class="service-details">
        <div class="container">
          <div class="row g-4">
            <div class="col-md-8 mb-40">
              <div class="img-wrapper mb-25">
                <img src="images/mb2.jpg" class="img-fluid" alt="" />
              </div>

              <h4 class="heading-4 mb-25">Mobile Application Development</h4>

              <div class="paragraph mb-40">
                We excel in crafting custom mobile apps for iOS and Android,
                prioritizing performance, usability, and innovation. Our
                experienced team ensures your app meets business needs while
                delivering exceptional user experiences, from concept to
                deployment. 
              </div>

              <div class="row g-4 mb-25">
                <div class="col-md-6">
                  <div class="img-wrapper">
                    <img
                      src="images/mb1.jpg"
                      class="img-fluid sub"
                      alt=""
                      style={{ height: "300px" }}
                    />
                  </div>
                </div>
                <div class="d-none d-md-block col-md-6">
                  <div class="img-wrapper">
                    <img src="images/mb3.jpg" class="img-fluid sub" alt="" />
                  </div>
                </div>
              </div>

              <h4 class="heading-3 mb-25">Manage designing Solutions</h4>

              <div class="paragraph mb-40">
              Collaborating closely with you, our team crafts
                intuitive interfaces and engaging experiences. We prioritize
                user-centric designs, ensuring functionality and aesthetics
                seamlessly merge for your app's success.
              </div>

              <div class="service-divider"></div>
            </div>
            <div class="d-none d-md-block col-md-4">
              <div class="service">
                <h4 class="heading-3">Services</h4>

                <div class="underline"></div>

                <ul class="service-list">
                  <li class="active">
                    <a href="/webdesign">
                      <div class="d-flex justify-content-between item">
                        <span>Web Design</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/ecommerce">
                      <div class="d-flex justify-content-between item">
                        <span>Ecommerce</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Cybersecurity">
                      <div class="d-flex justify-content-between item">
                        <span>CyberSecurity</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/DigitalMarketing">
                      <div class="d-flex justify-content-between item">
                        <span>DigitalMarketing</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Seo">
                      <div class="d-flex justify-content-between item">
                        <span>SEOAnalytics</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Dataanalytics">
                      <div class="d-flex justify-content-between item">
                        <span>DataAnalytics</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="squre-banner">
                <h2 class="heading-3">Easy solutions to your tech problem</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Mobileapp;
