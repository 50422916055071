
    import React from 'react';
import { Link } from 'react-router-dom';
import './newintern.css'; // Assuming you have a CSS file for styling

const Project = [
  {
    image: './assets/images/image4.jpg',
    title: 'Cloud Based CHATBOT',
    description: 'A chatbot is a computer program that simulates conversation with human end users.',
    link: './assets/images/image4.jpg',
  },
  {
    image: './assets/images/image1.jpg',
    title: 'Online Blood Bank System',
    description: 'The major goal of the blood bank management system is to keep track of blood, donors.',
    link: './assets/images/image1.jpg',
  },
  {
    image: './assets/images/image2.jpg',
    title: 'Airline Reservation System',
    description: 'Airline reservation systems (ARS) are systems that allow an airline to sell their inventory (seats).',
    link: './assets/images/image2.jpg',
  },
  {
    image: './assets/images/image3.jpg',
    title: 'Rural Banking',
    description: 'Rural Banking, we mean reaching the banking services to individuals residing in rural areas of the country.',
    link: './assets/images/image3.jpg',
  },
  // Add more projects as needed
  {
    image: './images/f1.jpg',
    title: 'Face Recognization',
    description: 'Build a Python app to detect & recognize faces. Use OpenCV for processing & face_recognition for recognition.',
    
  },
  {
    image: './images/ph1.jpg',
    title: 'Phishing Website',
    description: 'Phishing websites mimic real ones to steal your data. Look for typos in the URL, bad grammar, or missing contact info.',
    
  },
  {
    image: './images/WS.jpg',
    title: 'Web Scraping',
    description: 'Web scraping with Python is a powerful technique to extract data from websites.',
    
  },
  {
    image: './images/AI-ml.png',
    title: 'AI ML',
    description: 'Artificial intelligence (AI) and machine learning (ML) are rapidly evolving fields with a vast array of project opportunities. ',
   
  },
];

function Projects() {
  return (
    <>
    

    <div className="projects-container" >
      <h2 className="projects-title" style={{textAlign:"center",padding:"30px"}}>Our Projects</h2>
      <p className="projects-subtitle">Learn More About Our Complete Projects</p>
      <div className="projects-grid" style={{gap:"40px",paddingTop:"50px",padding:"60px"}}>
        {Project.map((project, index) => (
          <div key={index} className="project-card">
            <img src={project.image} alt={project.title} className="project-image" />
            {/* <Link to={project.link} className="eye-icon" target='_blank'>
              <i className="fa fa-eye"></i>
            </Link> */}
            <h3 className="project-title">{project.title}</h3>
            <p className="project-description">{project.description}</p>
          </div>
        ))}
      </div>
    </div>
    </>
  );
}

export default Projects;

