import React, { useState, useEffect } from 'react';
import { TiStar } from "react-icons/ti";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const MobileNav = () => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [isNavVisible, setNavVisible] = useState(true);
    const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
    const toggleMobileNav = () => {
        setMobileNavOpen(!isMobileNavOpen);
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            <div className={`mobile-nav-wrapper ${isNavVisible ? 'visible' : ''}`} style={{ textAlign: 'right', padding: '10px', background: isNavVisible ? 'white' : 'transparent', transition: 'background 0.3s' }}>
                <div
                    className="mobile-nav-icon"
                    style={{ fontSize: '25px', cursor: 'pointer', marginRight: "10px" }}
                    onClick={toggleMobileNav}
                >
                    ☰
                </div>
                <div style={{ backgroundColor: "white" }}>
                    {isMobileNavOpen && (
                        <div className="mobile-nav-content" style={{ top: '50px', width: "100px" }}>
                            <ul style={{ listStyle: 'none', padding: 0, marginRight: "60px" }}>
                                <li><Link to="/" style={{ fontSize: "18px" }}><b>HOME</b></Link></li>
                                <li><Link to="/portfolio" style={{ fontSize: "18px" }}><b>PORTFOLIO</b></Link></li>
                                <li><Link to="/services" style={{ fontSize: "18px" }}><b>SERVICES</b></Link></li>
                                <li><Link to="/contact" style={{ fontSize: "18px" }}><b>CONTACT</b></Link></li>
                                <li class="menu">
                                    <Link to="/careers" style={{ color: "blue", fontSize: "18px" }}><b>CAREERS</b>  <TiStar className="star1 blink star-button" /></Link>
                                </li>
                                <li><Link to="/trainings" style={{ fontSize: "18px" }}><b>TRAINING</b></Link></li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MobileNav;
