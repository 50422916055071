import React from 'react';
import Slider from 'react-slick';


const Portfolio = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const projectCards = [
   
    {
      imageSrc: 'images/p1.png',
      title: 'Dr MANJUNATH KN',
      category: 'Plastic Surgery',
    },
    {
      imageSrc: 'images/p7.png',
      title: 'SLICRC',
      category: 'Health Care',
    },
    {
      imageSrc: 'images/p8.png',
      title: 'KRTIEXIM',
      category: 'Import and Export',
    },
    {
      imageSrc: 'images/p9.png',
      title: 'HASIRAATA',
      category: 'Ayurvedic',
    },
    {
      imageSrc: 'images/p10.png',
      title: 'SSF',
      category: 'Electrical',
    },
    {
      imageSrc: 'images/p11.png',
      title: 'FLAREMAX',
      category: 'E-Commerce',
    },
    {
      imageSrc: 'images/p4.png',
      title: 'CAD DESK MAHADEVPURA',
      category: 'Educational',
    },
    {
      imageSrc: 'images/p5.png',
      title: 'IT DESK MAHADEVPURA',
      category: 'Educational',
    },
    {
      imageSrc: 'images/a1.png',
      title: 'ANANTA SAUKHYAM',
      category: 'Wellness',
    },
    {
      imageSrc: 'images/d1.png',
      title: 'DIEA',
      category: 'Industrial Assosiation',
    },
    {
      imageSrc: 'images/au1.png',
      title: 'AUTOMET ENGINEERING',
      category: 'Automobile',
    },
    {
      imageSrc: 'images/gi1.png',
      title: 'GIFCO AUTO FILTERS INDIA',
      category: 'Automobile',
    },
   
    {
      imageSrc: 'images/de1.png',
      title: 'DTECH',
      category: 'Civil',
    },
    {
      imageSrc: 'images/st1.png',
      title: 'Shaw Tech Training Services',
      category: 'Educational',
    },
    
  ];

  return (
    <div>
     
<div style={{marginTop:"-60px"}}></div>
      <section id="projects">
        <div className="container">
          <div className="section-heading d-flex flex-column align-items-center justify-content-center">
            <div className="space100"></div>
            <h2 className="heading-21 mb-20" style={{fontSize:"45px"}}><b>Our Latest Projects</b></h2>
        
          </div>
        </div>

        <Slider {...sliderSettings}>
          {projectCards.map((card, index) => (
            <div key={index} className="col-md-6 col-lg-4">
              <a >
                <div className="news-card position-relative" style={{ marginBottom: "20px", border: "2px solid black", padding: "10px",height:"350px", width:"450px" }}>
                  <div className="img-wrapper mb-30">
                    <img src={card.imageSrc} alt={card.title} style={{ width: "100%", objectFit: "cover" }} />
                  </div>
                  <div className="content" style={{ textAlign: 'center', opacity: 1, transition: 'opacity 0.3s ease-in-out' }}>
                    <h5 className="heading-5 mb-15" style={{color:"blue"}} >{card.title}</h5>
                    <div className="paragraph mb-25">{card.category}</div>
                  </div>

                  <div className="circle-1 position-absolute"></div>
                  <div className="circle-2 position-absolute"></div>
                </div>
              </a>
            </div>
          ))}
        </Slider>

        
      </section>

     

    
    </div>
  );
};

export default Portfolio;
