import React from 'react'

const AdvanceExcel = () => {
  return (
    <div>
         <div id="main-wrapper">
        <div class="site-wrapper-reveal">
          <div class="about-banner-wrap vision-space bg-img" style={{ backgroundColor: "darkturquoise", height: "600px" }}>
            <div class="container">
              <div class="row">
                <div class="col-lg-6 section-space--pt_100">
                  <h1 style={{ color: "#fff" }}>Adv. Excel</h1>
                  <p style={{ color: "#fff" }}>MS Excel represents a large amount of data in an efficient and user-friendly manne</p>
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-4">
                  <div class="form-container mx-auto mx-xl-0 ml-xl-auto border" id="corseform">
                    <div id="signup-form">
                      <p style={{ color: "green" }}>Your Enquiry Successfully Submit</p>
                      <form id="basic-form" name="yoursite_wp_mail_from_name" method="post" />
                      <input type="hidden" name="courseId" value="753" />
                      <input type="hidden" name="category" value="16" />
                      <div class="form-group">
                        <label>Name:</label>
                        <input type="text" id="fname" name="fname" class="form-control" placeholder="Enter Your Name" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Email:</label>
                        <input type="email" id="email" name="email" class="form-control" placeholder="Enter Your Email Address" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Mobile:</label>
                        <input type="number" id="phone" name="phone" class="form-control" placeholder="Enter Your mobile number" value="" required="" />
                      </div>
                      <div class="form-group mb-0">
                        <div class="form-row">
                          <div class="form-group col-6">
                            <label class="control-label">State</label>
                            <select id="states" name="states" class="form-control" required="">
                              <option value="">SELECT STATE</option>
                              <option value="2">Andhra Pradesh</option>
                              <option value="5">Bihar</option>
                              <option value="12">Gujarat</option>
                              <option value="13">Haryana</option>
                              <option value="14">Himachal Pradesh</option>
                              <option value="16">Jharkhand</option>
                              <option value="17">Karnataka</option>
                              <option value="21">Maharashtra</option>
                              <option value="28">Punjab</option>
                              <option value="29">Rajasthan</option>
                              <option value="31">Tamil Nadu</option>
                              <option value="32">Telangana</option>
                              <option value="35">Uttar Pradesh</option>
                            </select>
                          </div>
                          <div class="form-group col-6 city">
                            <label class="control-label">Center</label>
                            <select name="city" id="city" class="form-control center" required="">
                              <option value="">SELECT CENTRE</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <button type="submit" id="btn" class="btn btn-primary contest_register_now" name="register_users">Apply Now</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >f
          <li class="breadcrumb-item" >
            <a href="/" >Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Adv. Excel</li>
        </ol>
      </nav>



      <div class="vision-overview ">
        <div class="container">
          <div class="section-title-wrap text-center">
            <br/>
            <br/>

            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Training Highlights</span></h3>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container" >
                  <img src="./assets/images/img11.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Instructor Led Training</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img22.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Weekly Doubt clearing Session</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img33.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Assistance for Revision</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img44.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Login Access to Student Dashboard</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img55.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">World-Wide Valid Certification</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img666.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Study Material, Assignments and Projects</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>

          </div>
          <br /> <br />
          <div class="section-title-wrap text-center">
          <br/>
            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Course Highlights</span></h3>
          </div>
          {/* <div class="row">
            <div class="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
              <div class="dashed-border">
                <img class="d-block d-lg-none" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg" />
                <img class="d-none d-lg-block" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg" />
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-lg-center">

                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">1</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Numpy and Scipy</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">2</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Data Visualization with Matplotlib</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">3</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Supervised & Unsupervised Learning</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">4</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Spark</p>
                  </div>
                </div>

              </div>
            </div>
          </div> */}

<div class="row">
  <div class="col-lg-12 col-12">
    <br/>
    <br/>
    <h3 class="heading" style={{fontSize: "21px"}}>Advanced Excel Overview</h3>
    <br/>
    <h3 class="heading"><span class="text-color-primary" style={{fontSize: "21px"}}>Advanced Excel Overview</span></h3>
    <div class="vision-content">
      <h2>Advanced Excel: Master Data Analysis and Visualization</h2>
      <p>Advanced Excel is a powerful tool for data analysis, reporting, and automation. It enables users to manipulate and analyze large datasets, create complex formulas, and generate insightful visualizations.</p>
      <p><strong>Table of Contents</strong></p>
      <ul style={{fontSize: "15px", fontFamily:"sans-serif"}}>
        <li>Introduction to Advanced Excel</li>
        <li>Key Features of Advanced Excel</li>
        <li>Benefits of Learning Advanced Excel</li>
        <li>Advanced Excel Functions and Formulas</li>
        <li>Data Analysis Techniques</li>
        <li>Visualizations and Dashboards</li>
        <li>Automation with Macros and VBA</li>
        <li>Advanced Excel Training Programs</li>
        <li>Certification and Recognition</li>
        <li>Career Opportunities</li>
        <li>FAQs</li>
      </ul>
      <br/>
      <br/>
      <h2><strong>Introduction to Advanced Excel</strong></h2>
      <p>Advanced Excel goes beyond basic spreadsheet tasks to offer advanced features for data manipulation, analysis, and visualization. It is widely used in industries for financial analysis, reporting, and business intelligence.</p>
      <br/>
      <h2><strong>Key Features of Advanced Excel</strong></h2>
      <p>Advanced Excel includes powerful features such as:</p>
      <ul>
        <li><strong>Data Analysis:</strong> Tools for sorting, filtering, and analyzing large datasets.</li>
        <li><strong>Formulas and Functions:</strong> Complex formulas and functions for calculations and data manipulation.</li>
        <li><strong>Charts and Graphs:</strong> Create dynamic visual representations of data for insights.</li>
        <li><strong>PivotTables:</strong> Summarize and analyze data with interactive PivotTables.</li>
        <li><strong>Macros and VBA:</strong> Automate tasks and customize Excel with Visual Basic for Applications (VBA).</li>
      </ul>
      <br/>
      <h2><strong>Benefits of Learning Advanced Excel</strong></h2>
      <p>Learning Advanced Excel offers several benefits:</p>
      <ul>
        <li><strong>Enhanced Data Analysis:</strong> Perform complex data analysis tasks efficiently.</li>
        <li><strong>Improved Reporting:</strong> Create professional reports with interactive charts and graphs.</li>
        <li><strong>Automation:</strong> Streamline repetitive tasks and increase productivity with macros.</li>
        <li><strong>Career Advancement:</strong> Gain competitive edge in job market with advanced Excel skills.</li>
      </ul>
      <br/>
      <h2><strong>Advanced Excel Functions and Formulas</strong></h2>
      <p>Advanced Excel includes a wide range of functions and formulas for various tasks:</p>
      <ul>
        <li>Statistical Functions (e.g., SUMIF, COUNTIF)</li>
        <li>Logical Functions (e.g., IF, AND, OR)</li>
        <li>Lookup and Reference Functions (e.g., VLOOKUP, HLOOKUP)</li>
        <li>Mathematical Functions (e.g., SUM, AVERAGE)</li>
        <li>Date and Time Functions (e.g., DATE, NOW)</li>
      </ul>
      <br/>
      <h2><strong>Data Analysis Techniques</strong></h2>
      <p>Advanced Excel enables various data analysis techniques, including:</p>
      <ul>
        <li>Filtering and Sorting</li>
        <li>Conditional Formatting</li>
        <li>PivotTables and PivotCharts</li>
        <li>Data Validation</li>
        <li>What-If Analysis with Scenario Manager</li>
      </ul>
      <br/>
      <h2><strong>Visualizations and Dashboards</strong></h2>
      <p>Create dynamic visualizations and dashboards in Advanced Excel to:</p>
      <ul>
        <li>Present data trends and patterns visually</li>
        <li>Interactive charts, graphs, and sparklines</li>
        <li>Customize dashboards for effective data presentation</li>
      </ul>
      <br/>
      <h2><strong>Automation with Macros and VBA</strong></h2>
      <p>Automate repetitive tasks and customize Excel functionality with:</p>
      <ul>
        <li>Recording and editing macros</li>
        <li>Writing VBA code for advanced automation</li>
        <li>Creating user-defined functions (UDFs)</li>
      </ul>
      <br/>
      <h2><strong>Advanced Excel Training Programs</strong></h2>
      <p>Advanced Excel training programs offer structured learning:</p>
      <ul>
        <li>Hands-on workshops and practical exercises</li>
        <li>Expert-led training sessions</li>
        <li>Online tutorials and self-paced courses</li>
      </ul>
      <br/>
      <h2><strong>Certification and Recognition</strong></h2>
      <p>Upon completing Advanced Excel training, certifications validate your skills:</p>
      <ul>
        <li>Industry-recognized certifications</li>
        <li>Enhanced credibility in job market</li>
        <li>Proof of proficiency in data analysis</li>
      </ul>
      <br/>
      <h2><strong>Career Opportunities</strong></h2>
      <p>Advanced Excel skills open doors to various career opportunities:</p>
      <ul>
        <li>Data Analyst</li>
        <li>Financial Analyst</li>
        <li>Business Intelligence Analyst</li>
        <li>Operations Manager</li>
        <li>Management Consultant</li>
      </ul>
      <br/>
      <p>Master Advanced Excel to streamline data analysis, create insightful visualizations, and automate tasks for enhanced productivity and career advancement.</p>
      <br/>
      <br/>
    </div>
  </div>
</div>
<div class="contact-us-section-wrappaer ">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7 col-lg-7">
              <div class="conact-us-wrap-one">
                <br />
                <br />
                <h5 class="heading mb-20">How will you get your certificate?</h5>
                <div class="center_details">
                  {/* <!--   <h5 class="heading mb-20">CERTIFICATE FAQ</h5> --> */}
                  <p>Upon successful completion of the program, each intern receives a prestigious Digital Marketing Internship Certificate. This credential not only recognizes their achievements but also enhances their employability.</p>
                  <p style={{ textAlign: "justify" }}>IT DESK is a pioneer in CS/IT training and most of the industries give great recognition to its certificate throughout India and abroad. IT DESK Certificates are ISO verified which makes it highly reputed comparing to the other local brands and helps the student get prioritize while going for job opportunities in the industry. IT DESK Software Training is conducted by highly knowledgeable trainers which help students to enhance their skill set by putting great emphasizes on project-based learning.</p>
                  {/* <!-- <a class="btn btn-primary">Get Certificate</a> --> */}
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-lg-5">
              <div class="contact-form-wrap">
                {/* <!-- <h5 class="heading mb-20">How will you get your certificate?</h5> --> */}
                <img src="./cert11.jpg" style={{ width: " 100%", height: "370px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
    </div>
  )
}

export default AdvanceExcel