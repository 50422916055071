import React from 'react'

const Mysql = () => {
  return (
    <div>
           
           <div id="main-wrapper">
        <div class="site-wrapper-reveal">
          <div class="about-banner-wrap vision-space bg-img" style={{ backgroundColor: "darkturquoise", height: "600px" }}>
            <div class="container">
              <div class="row">
                <div class="col-lg-6 section-space--pt_100">
                  <h1 style={{ color: "#fff" }}>My SQL</h1>
                  <p style={{ color: "#fff" }}>A most powerful tool to grow business and increase reach worldwide.</p>
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-4">
                  <div class="form-container mx-auto mx-xl-0 ml-xl-auto border" id="corseform">
                    <div id="signup-form">
                      <p style={{ color: "green" }}>Your Enquiry Successfully Submit</p>
                      <form id="basic-form" name="yoursite_wp_mail_from_name" method="post" />
                      <input type="hidden" name="courseId" value="753" />
                      <input type="hidden" name="category" value="16" />
                      <div class="form-group">
                        <label>Name:</label>
                        <input type="text" id="fname" name="fname" class="form-control" placeholder="Enter Your Name" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Email:</label>
                        <input type="email" id="email" name="email" class="form-control" placeholder="Enter Your Email Address" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Mobile:</label>
                        <input type="number" id="phone" name="phone" class="form-control" placeholder="Enter Your mobile number" value="" required="" />
                      </div>
                      <div class="form-group mb-0">
                        <div class="form-row">
                          <div class="form-group col-6">
                            <label class="control-label">State</label>
                            <select id="states" name="states" class="form-control" required="">
                              <option value="">SELECT STATE</option>
                              <option value="2">Andhra Pradesh</option>
                              <option value="5">Bihar</option>
                              <option value="12">Gujarat</option>
                              <option value="13">Haryana</option>
                              <option value="14">Himachal Pradesh</option>
                              <option value="16">Jharkhand</option>
                              <option value="17">Karnataka</option>
                              <option value="21">Maharashtra</option>
                              <option value="28">Punjab</option>
                              <option value="29">Rajasthan</option>
                              <option value="31">Tamil Nadu</option>
                              <option value="32">Telangana</option>
                              <option value="35">Uttar Pradesh</option>
                            </select>
                          </div>
                          <div class="form-group col-6 city">
                            <label class="control-label">Center</label>
                            <select name="city" id="city" class="form-control center" required="">
                              <option value="">SELECT CENTRE</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <button type="submit" id="btn" class="btn btn-primary contest_register_now" name="register_users">Apply Now</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >f
          <li class="breadcrumb-item" >
            <a href="/" >Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">MySQL</li>
        </ol>
      </nav>



      <div class="vision-overview ">
        <div class="container">
          <div class="section-title-wrap text-center">
            <br/>
            <br/>

            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Training Highlights</span></h3>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container" >
                  <img src="./assets/images/img11.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Instructor Led Training</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img22.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Weekly Doubt clearing Session</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img33.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Assistance for Revision</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img44.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Login Access to Student Dashboard</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img55.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">World-Wide Valid Certification</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img666.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Study Material, Assignments and Projects</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>

          </div>
          <br /> <br />
          <div class="section-title-wrap text-center">
          <br/>
            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Course Highlights</span></h3>
          </div>
          {/* <div class="row">
            <div class="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
              <div class="dashed-border">
                <img class="d-block d-lg-none" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg" />
                <img class="d-none d-lg-block" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg" />
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-lg-center">

                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">1</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Numpy and Scipy</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">2</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Data Visualization with Matplotlib</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">3</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Supervised & Unsupervised Learning</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">4</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Spark</p>
                  </div>
                </div>

              </div>
            </div>
          </div> */}

<div class="row">
  <div class="col-lg-12 col-12">
    <br/>
    <br/>
    <h3 class="heading" style={{ fontSize: "21px" }}>Course Overview</h3>
    <br/>
    <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}>Course Overview</span></h3>
    <div class="vision-content">
      <h2>MySQL Certification Training: Master Relational Database Management</h2>
      <p>Are you ready to enhance your skills in database management? Enroll in the MySQL Certification Training at Excerpt Technologies IT Training Services in Bangalore. This comprehensive training program is designed to equip you with the knowledge and expertise needed to master MySQL, a leading relational database management system. In this article, we will explore the course details, the benefits of MySQL certification, and why Excerpt Technologies IT Training Services is the ideal choice for your career advancement in database administration.</p>
      <p><strong>Table of Contents</strong></p>
      <ul style={{fontSize:'15px', fontFamily: 'Open Sans, sans-serif'}}>
        <li>Introduction to MySQL Certification Training</li>
        <li>The Importance of Database Management in Today’s Data-driven World</li>
        <li>Benefits of MySQL Certification Training</li>
        <li>Course Curriculum and Training Approach</li>
        <li>Hands-on Labs and Practical Applications</li>
        <li>Expert Instructors and Industry Insights</li>
        <li>Interactive Learning Environment</li>
        <li>Flexible Learning Options</li>
        <li>MySQL Certification and Career Opportunities</li>
        <li>Placement Assistance and Career Support</li>
        <li>Excerpt Technologies IT Training Services: Your Trusted Training Partner</li>
        <li>FAQs</li>
      </ul>
      <br/>
      <br/>
      <h2><strong>Introduction to MySQL Certification Training</strong></h2>
      <p>MySQL is a widely-used open-source relational database management system known for its performance, reliability, and ease of use. The MySQL Certification Training at Excerpt Technologies IT Training Services provides comprehensive instruction on MySQL architecture, SQL queries, database design, optimization techniques, and administration tasks.</p>
      <br/>
      <p><strong>The Importance of Database Management in Today’s Data-driven World</strong></p>
      <p>Effective database management is essential for organizations to store, retrieve, and manage data efficiently. MySQL, as a powerful database solution, enables businesses to handle large datasets, ensure data integrity, and support critical applications across various industries.</p>
      <br/>
      <h2><strong>Benefits of MySQL Certification Training</strong></h2>
      <p>Enrolling in the MySQL Certification Training offers several advantages:</p>
      <p><strong>Skills Validation:</strong> MySQL certifications validate your expertise in database administration and enhance your credibility as a skilled MySQL professional sought after by employers.</p>
      <br/>
      <p><strong>Performance Optimization:</strong> Gain insights into MySQL performance tuning, query optimization, and indexing strategies to maximize database efficiency and application performance.</p>
      <br/>
      <p><strong>Data Security:</strong> Learn best practices for MySQL security, data encryption, access control, and compliance to protect sensitive information and ensure regulatory compliance.</p>
      <br/>
      <p><strong>Course Curriculum and Training Approach</strong></p>
      <p>The MySQL Certification Training at <strong>Excerpt Technologies IT Training Services</strong> covers essential MySQL concepts, tools, and practical skills required for MySQL certification exams. The course includes:</p>
      <ul>
        <li>MySQL Architecture and Installation</li>
        <li>Database Design and Normalization</li>
        <li>SQL Queries and Data Manipulation</li>
        <li>Transactions and Locking</li>
        <li>Stored Procedures, Functions, and Triggers</li>
        <li>Backup and Recovery Techniques</li>
        <li>Performance Optimization and Monitoring</li>
      </ul>
      <p>Through hands-on labs, real-world projects, and interactive sessions, you will gain proficiency in designing, managing, and optimizing MySQL databases.</p>
      <br/>
      <p><strong>Expert Instructors and Industry Insights</strong></p>
      <p>Learn from experienced instructors and industry experts at Excerpt Technologies IT Training Services who bring real-world experience and insights into MySQL database management. Benefit from their guidance, practical advice, and best practices to enhance your MySQL skills.</p>
      <br/>
      <p><strong>Interactive Learning Environment</strong></p>
      <p>The MySQL Certification Training features an interactive learning environment where you can engage with instructors, collaborate with peers, and participate in discussions. This interactive approach fosters knowledge sharing, enhances problem-solving skills, and accelerates your understanding of MySQL concepts.</p>
      <br/>
      <p><strong>Flexible Learning Options</strong></p>
      <p>Excerpt Technologies IT Training Services offers flexible learning options tailored to your schedule and preferences. Choose from classroom-based instruction, live online sessions, or a blended learning approach to accommodate your learning needs while balancing professional commitments.</p>
      <br/>
      <h2><strong>MySQL Certification and Career Opportunities</strong></h2>
      <p>Upon successful completion of the MySQL Certification Training, you will be prepared to pursue MySQL certifications such as MySQL Database Administrator, MySQL Developer, or MySQL Database Specialist. These certifications validate your skills and open doors to diverse career opportunities in database administration, data management, and application development.</p>
      <h2><strong>Placement Assistance and Career Support</strong></h2>
      <p>Excerpt Technologies IT Training Services provides comprehensive placement assistance and career support to help you transition into a rewarding career in MySQL database management. Benefit from interview preparation, resume building, networking opportunities, and access to their extensive industry network to secure employment as a MySQL-certified professional.</p>
      <h2><strong>Excerpt Technologies IT Training Services: Your Trusted Training Partner</strong></h2>
      <p>With a commitment to delivering quality education and empowering professionals, Excerpt Technologies IT Training Services is your trusted training partner for MySQL certification courses. Their industry-aligned curriculum, expert instructors, and student-focused approach ensure that you receive top-tier training and support throughout your MySQL certification journey.</p>
      <br/>
      <p><strong>Excerpt Technologies IT Training Services</strong> offers professional software training across various courses within the <strong>Computer Science and Information Technology</strong> domains. Since its establishment in 2020, <strong>Excerpt Technologies IT Training Services</strong> has successfully trained over 10,000 students and professionals globally.</p>
     
      <p>Enhance your career prospects with the MySQL Certification Training at Excerpt Technologies IT Training Services. Gain expertise in MySQL database management, achieve MySQL certifications, and advance your career as a database professional. Start your journey towards mastering relational database management with MySQL today!</p>
      <br/>
      <br/>
    </div>
  </div>
</div>
        </div>
      </div>
      <div class="contact-us-section-wrappaer ">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7 col-lg-7">
              <div class="conact-us-wrap-one">
                <br />
                <br />
                <h5 class="heading mb-20">How will you get your certificate?</h5>
                <div class="center_details">
                  {/* <!--   <h5 class="heading mb-20">CERTIFICATE FAQ</h5> --> */}
                  <p>Upon successful completion of the program, each intern receives a prestigious Digital Marketing Internship Certificate. This credential not only recognizes their achievements but also enhances their employability.</p>
                  <p style={{ textAlign: "justify" }}>IT DESK is a pioneer in CS/IT training and most of the industries give great recognition to its certificate throughout India and abroad. IT DESK Certificates are ISO verified which makes it highly reputed comparing to the other local brands and helps the student get prioritize while going for job opportunities in the industry. IT DESK Software Training is conducted by highly knowledgeable trainers which help students to enhance their skill set by putting great emphasizes on project-based learning.</p>
                  {/* <!-- <a class="btn btn-primary">Get Certificate</a> --> */}
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-lg-5">
              <div class="contact-form-wrap">
                {/* <!-- <h5 class="heading mb-20">How will you get your certificate?</h5> --> */}
                <img src="./assets/images/images/cert11.jpg" style={{ width: " 100%", height: "370px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  )
}

export default Mysql