import React from 'react'

const Dotnet = () => {
  return (
    <div>
          <div id="main-wrapper">
        <div class="site-wrapper-reveal">
          <div class="about-banner-wrap vision-space bg-img" style={{ backgroundColor: "darkturquoise", height: "600px" }}>
            <div class="container">
              <div class="row">
                <div class="col-lg-6 section-space--pt_100">
                  <h1 style={{ color: "#fff" }}>.NET</h1>
                  <p style={{ color: "#fff" }}>A most powerful tool to grow business and increase reach worldwide.</p>
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-4">
                  <div class="form-container mx-auto mx-xl-0 ml-xl-auto border" id="corseform">
                    <div id="signup-form">
                      {/* <p style={{ color: "green" }}>Your Enquiry Successfully Submit</p> */}
                      <form id="basic-form" name="yoursite_wp_mail_from_name" method="post" />
                      <input type="hidden" name="courseId" value="753" />
                      <input type="hidden" name="category" value="16" />
                      <div class="form-group">
                        <label>Name:</label>
                        <input type="text" id="fname" name="fname" class="form-control" placeholder="Enter Your Name" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Email:</label>
                        <input type="email" id="email" name="email" class="form-control" placeholder="Enter Your Email Address" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Mobile:</label>
                        <input type="number" id="phone" name="phone" class="form-control" placeholder="Enter Your mobile number" value="" required="" />
                      </div>
                      <div class="form-group mb-0">
                        <div class="form-row">
                          <div class="form-group col-6">
                            <label class="control-label">State</label>
                            <select id="states" name="states" class="form-control" required="">
                              <option value="">SELECT STATE</option>
                              <option value="2">Andhra Pradesh</option>
                              <option value="5">Bihar</option>
                              <option value="12">Gujarat</option>
                              <option value="13">Haryana</option>
                              <option value="14">Himachal Pradesh</option>
                              <option value="16">Jharkhand</option>
                              <option value="17">Karnataka</option>
                              <option value="21">Maharashtra</option>
                              <option value="28">Punjab</option>
                              <option value="29">Rajasthan</option>
                              <option value="31">Tamil Nadu</option>
                              <option value="32">Telangana</option>
                              <option value="35">Uttar Pradesh</option>
                            </select>
                          </div>
                          <div class="form-group col-6 city">
                            <label class="control-label">Center</label>
                            <select name="city" id="city" class="form-control center" required="">
                              <option value="">SELECT CENTRE</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <button type="submit" id="btn" class="btn btn-primary contest_register_now" name="register_users">Apply Now</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
          <li class="breadcrumb-item" >
            <a href="/" >Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">.NET</li>
        </ol>
      </nav>



      <div class="vision-overview ">
        <div class="container">
          <div class="section-title-wrap text-center">
            <br/>
            <br/>

            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Training Highlights</span></h3>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container" >
                  <img src="./assets/images/img11.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Instructor Led Training</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img22.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Weekly Doubt clearing Session</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img33.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Assistance for Revision</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img44.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Login Access to Student Dashboard</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img55.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">World-Wide Valid Certification</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img666.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Study Material, Assignments and Projects</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>

          </div>
          <br /> <br />
          <div class="section-title-wrap text-center">
          <br/>
            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Course Highlights</span></h3>
          </div>
          {/* <div class="row">
            <div class="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
              <div class="dashed-border">
                <img class="d-block d-lg-none" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg" />
                <img class="d-none d-lg-block" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg" />
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-lg-center">

                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">1</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Numpy and Scipy</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">2</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Data Visualization with Matplotlib</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">3</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Supervised & Unsupervised Learning</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">4</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Spark</p>
                  </div>
                </div>

              </div>
            </div>
          </div> */}

<div class="row">
  <div class="col-lg-12 col-12">
    <br/>
    <br/>
    <h5 class="heading" style={{ fontSize: "21px" }}>Course Overview</h5>
    <br/>
    {/* <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}>Course Overview</span></h3> */}
    <div class="vision-content" style={{textAlign:"justify"}}>
      <h2>.NET Development Course: Building Robust Applications</h2>
      <p>Are you ready to become proficient in .NET development? Look no further than the .NET Development course at Excerpt Technologies IT Training Services in Bangalore. This comprehensive training program is designed to equip you with the skills and knowledge needed to excel in .NET development. In this article, we will explore the course details, the benefits of learning .NET development, and why Excerpt Technologies IT Training Services is the perfect training institute for your educational needs.</p>
      <p><strong>Table of Contents</strong></p>
      <ul style={{fontSize:'15px', fontfamily: 'Open Sans sans-serif'}}>
        <li>Introduction to .NET Development Course</li>
        <li>The Importance of .NET in Today’s Web Development</li>
        <li>Benefits of .NET Development Course Training</li>
        <li>Course Curriculum and Training Approach</li>
        <li>Industry-Relevant Projects and Case Studies</li>
        <li>Experienced Faculty and Mentors</li>
        <li>Hands-on Practical Sessions</li>
        <li>Flexible Learning Options</li>
        <li>Certification Program and Recognition</li>
        <li>Placement Assistance and Career Support</li>
        <li>.NET Development Course Training Institute</li>
        <li>.NET Development Course Fees & Class</li>
        <li>Excerpt Technologies IT Training Services: A Trusted Training Institute</li>
        <li>FAQs</li>
      </ul>
      <br/>
      <br/>
      <h2><strong>Introduction to .NET Development Course</strong></h2>
      <p>The .NET framework is a popular development platform that combines various tools, languages, and libraries for building dynamic and responsive web applications. The .NET Development course in Bangalore offered by Excerpt Technologies IT Training Services provides a comprehensive introduction to building robust and scalable applications using the .NET framework.</p>
      <br/>
      <p><strong>The Importance of .NET in Today’s Web Development</strong></p>
      <p>In today's fast-paced digital world, the demand for developers who can create end-to-end web applications is on the rise. The .NET framework enables developers to build robust, scalable, and maintainable web applications using a unified platform. By mastering .NET, you will be well-equipped to meet the needs of modern web development.</p>
      <br/>
      <h2><strong>Benefits of .NET Development Course Training</strong></h2>
      <p>Learning .NET development offers several advantages for aspiring developers:</p>
      <p><strong>Comprehensive Skill Set:</strong> The .NET framework covers all aspects of web development, from backend management to front-end development, making you a versatile and highly sought-after developer.</p>
      <br/>
      <p><strong>High Demand:</strong> .NET developers are in high demand due to the framework's efficiency and ability to handle large-scale applications. Proficiency in .NET opens doors to numerous job opportunities.</p>
      <br/>
      <p><strong>Streamlined Development:</strong> Using a single platform for both client-side and server-side development streamlines the development process and improves productivity.</p>
      <p><strong>Active Community:</strong> The .NET framework has a vibrant and active community of developers who contribute to a rich ecosystem of libraries, frameworks, and tools.</p>
      <br/>
      <p><strong>Course Curriculum and .NET Development Course Training Approach</strong></p>
      <p>The .NET Development course at <strong>Excerpt Technologies IT Training Services</strong> covers a comprehensive curriculum that encompasses both theoretical concepts and hands-on practical exercises. The training is designed to provide a solid foundation in the .NET framework, along with essential web development principles. Through a practical-oriented approach, you will gain the necessary skills to tackle real-world web development challenges.</p>
      <br/>
      <p><strong>Industry-Relevant Projects and Case Studies</strong></p>
      <p>To enhance your learning experience, the course incorporates industry-relevant projects and case studies. These real-world scenarios enable you to apply your knowledge and skills to solve practical problems encountered in various domains. By working on these projects, you will build a robust portfolio that showcases your proficiency in .NET development.</p>
      <br/>
      <p><strong>Experienced Faculty and Mentors</strong></p>
      <p>At Excerpt Technologies IT Training Services, you will learn from experienced faculty members and industry experts who possess extensive knowledge and practical experience in .NET development. They will guide you throughout the course, providing valuable insights, mentoring, and personalized attention to ensure your success in mastering .NET development.</p>
      <br/>
      <p><strong>Hands-on Practical Sessions</strong></p>
      <p>The course emphasizes hands-on practical sessions to reinforce your learning and enhance your problem-solving skills. Through interactive coding exercises, development tasks, and project work, you will gain proficiency in applying the .NET framework to real-world applications. These practical sessions will empower you to confidently undertake .NET development projects in your future endeavors.</p>
      <br/>
      <p><strong>Flexible Learning Options</strong></p>
      <p>Excerpt Technologies IT Training Services understands the diverse needs of learners. To accommodate different schedules and preferences, they offer flexible learning options. You can choose between classroom-based training, live online sessions, or a blended learning approach. This flexibility ensures that you can pursue the course at your own pace, balancing your professional and personal commitments.</p>
      <br/>
      <h2><strong>.NET Certification Program and Recognition</strong></h2>
      <p>Upon successful completion of the .NET Development <strong>Certification Program</strong>, you will receive a prestigious certification from Excerpt Technologies IT Training Services. This industry-recognized certification demonstrates your expertise in .NET development, enhancing your credibility and employability in the job market.</p>
      <h2><strong>Placement Assistance and Career Support</strong></h2>
      <p>Excerpt Technologies IT Training Services is committed to your career success. They provide dedicated placement assistance and career support to help you secure lucrative job opportunities. Their extensive network of industry connections, interview preparation guidance, and resume-building workshops will empower you to kickstart your career as a .NET developer.</p>
      <h2><strong>.NET Development Course Training Institute</strong></h2>
      <p>Welcome to our .NET development training institute in Bangalore, where we specialize in providing comprehensive courses in full-stack web development. Our institute is dedicated to equipping aspiring developers and professionals with the skills and knowledge needed to excel in this rapidly growing field.</p>
      <br/>
      <p>Our .NET-based <strong>Development Courses in Bangalore</strong> are designed to cater to individuals at all skill levels, from beginners with no prior programming experience to experienced programmers looking to deepen their web development expertise. Our expert instructors bring years of industry experience and a deep understanding of .NET development concepts, ensuring that our students receive top-notch training.</p>
      <h2><strong>.NET Development Course Fees & Class</strong></h2>
      <p>Excerpt Technologies IT Training Services offers competitive course fees for .NET development programs in Bangalore. They understand the financial constraints that students may face and provide scholarships and installment options to make the course accessible to a wider audience. Contact their admissions team to learn more about the fee structure and available scholarships. Excerpt Technologies IT Training Services offers .NET Development Course classes Online &amp; Offline by experts.</p>
      <h2><strong>Excerpt Technologies IT Training Services: A Trusted Training Institute</strong></h2>
      <p>With a proven track record of delivering high-quality education and empowering thousands of students, Excerpt Technologies IT Training Services stands as a trusted training institute for .NET development courses. Their commitment to excellence, industry-relevant curriculum, and student-centric approach makes them the ideal choice for your .NET development journey.</p>
      <br/>
      <p><strong>Excerpt Technologies IT Training Services</strong> provides professional software training on a vast array of courses in the fields of <strong>Computer Science and Information Technology</strong>. Since its founding in 2016, <strong>Excerpt Technologies IT Training Services</strong> has provided training to over 10,000+ students and professionals from various countries.</p>
      <p>Since its inception, in a very short span of time, with the qualitative services of training and well-designed training modules, <strong>Excerpt Technologies IT Training Services</strong> has expanded its network and become the topmost leading franchise network. <em><strong>Excerpt Technologies IT Training Services proudly owns 35+ franchise centers in various parts of the country</strong></em><em>.</em> Furthermore, we are now expanding our network center in various countries in the world.</p>
      <p>Embarking on a .NET Development course at Excerpt Technologies IT Training Services in Bangalore can be a transformative step toward a rewarding career in web development. By mastering the .NET framework, you will possess the skills to build dynamic and responsive web applications, unlocking numerous opportunities and positioning yourself for success in the dynamic world of technology.</p>
    </div>
  </div>
</div>

<div class="contact-us-section-wrappaer ">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7 col-lg-7">
              <div class="conact-us-wrap-one">
                <br />
                <br />
                <h5 class="heading mb-20">How will you get your certificate?</h5>
                <div class="center_details">
                  {/* <!--   <h5 class="heading mb-20">CERTIFICATE FAQ</h5> --> */}
                  <p>Upon successful completion of the program, each intern receives a prestigious Digital Marketing Internship Certificate. This credential not only recognizes their achievements but also enhances their employability.</p>
                  <p style={{ textAlign: "justify" }}>IT DESK is a pioneer in CS/IT training and most of the industries give great recognition to its certificate throughout India and abroad. IT DESK Certificates are ISO verified which makes it highly reputed comparing to the other local brands and helps the student get prioritize while going for job opportunities in the industry. IT DESK Software Training is conducted by highly knowledgeable trainers which help students to enhance their skill set by putting great emphasizes on project-based learning.</p>
                  {/* <!-- <a class="btn btn-primary">Get Certificate</a> --> */}
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-lg-5">
              <div class="contact-form-wrap">
                {/* <!-- <h5 class="heading mb-20">How will you get your certificate?</h5> --> */}
                <img src="./cert11.jpg" style={{ width: " 100%", height: "370px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>

      {/* <div class="contact-us-section-wrappaer ">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7 col-lg-7">
              <div class="conact-us-wrap-one">
                <br />
                <br />
                <h5 class="heading mb-20">How will you get your certificate?</h5>
                <div class="center_details">
              
                  <p>Upon successful completion of the program, each intern receives a prestigious Digital Marketing Internship Certificate. This credential not only recognizes their achievements but also enhances their employability.</p>
                  <p style={{ textAlign: "justify" }}>IT DESK is a pioneer in CS/IT training and most of the industries give great recognition to its certificate throughout India and abroad. IT DESK Certificates are ISO verified which makes it highly reputed comparing to the other local brands and helps the student get prioritize while going for job opportunities in the industry. IT DESK Software Training is conducted by highly knowledgeable trainers which help students to enhance their skill set by putting great emphasizes on project-based learning.</p>
                
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-lg-5">
              <div class="contact-form-wrap">
             
                <img src="assests/images/cert11.jpg" style={{ width: " 100%", height: "370px" }} />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <br />
      <br />
      <br />
    </div>
  )
}

export default Dotnet