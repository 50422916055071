import React from 'react'
import { Link } from 'react-router-dom'
import './OnlineProgram.css'

const Onlineprogram = () => {
  return (
    <div>
	  <div className="tab-container" style={{marginTop:"40px"}}>
                    
	  <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Allprogram" style={{color:"white"}}>Allprogram</Link>
                    </button>
                    <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Onlineprogram" style={{color:"white"}}>Onlineprogram</Link>
                    </button>
                    <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Internship" style={{color:"white"}}>Internship</Link>
                    </button>
                    <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Placement" style={{color:"white"}}>Placement</Link>
                    </button>
                </div>
        
        <div className="banner-agile-2">

	  
	  <div class="course-w3ls py-5 " style={{marginTop:'-80px'}}>
				<div class="container py-xl-5 py-lg-3">
					<h3 class="title text-capitalize font-weight-light text-dark text-center mb-sm-5 mb-4">course -
						<span class="font-weight-bold">Software</span>
					</h3>


					<div class="row cource-list-agile pt-4 cource-list-agile-2" style={{marginTop:'-50px'}}>
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assets/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">A Guide For Beginners Software</h3>
									<p class="mt-3 mb-4 pr-lg-5">Java has long been one of the most popular programming languages in the software industry. With its versatility and wide range of applications.</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i> Aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 Months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assets/images/java3.jpg" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assets/images/ds1.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assets/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4" style={{justifyContent:"center"}}>
									<h3 class="text-dark">Introduction To Engineering Design</h3>
									<p class="mt-3 mb-4 pl-lg-4">if youre looking to explore the fascinating world of data science and gain proficiency in R programming,.</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assets/images/java3.jpg" alt="" class="img-fluid" />
						</div> */}
						<div class="buttons-w3ls-2" style={{marginRight: "-950px"}}>
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assets/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Organize Of Program Languages</h3>
									<p class="mt-3 mb-4 pr-lg-5">Mean Stack Development is one such technology that has gained immense popularity among developers.</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>Aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 Months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assets/images/ds2.png" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assets/images/ds7.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assets/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Programming Software Engineer</h3>
									<p class="mt-3 mb-4 pl-lg-4"> C/C++ programming internship is a practical learning opportunity designed for students and programming enthusiasts.</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="buttons-w3ls-2" style={{marginRight: "-950px"}}>
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assets/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Object-Oriented Programming Java</h3>
									<p class="mt-3 mb-4 pr-lg-5">Java is an object-oriented programming language which is generally designed either to be compiled to native (machine) code or to be interpreted from source code at runtime..</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>Aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 Months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assets/images/ds8.jpg" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assets/images/ds6.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assets/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Algorithms Software Programming</h3>
									<p class="mt-3 mb-4 pl-lg-4"> Java offers a plethora of opportunities for developers. Among the various branches of Java, Advanced Java J2EE stands out as a powerful tool for building enterprise-level applications</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="buttons-w3ls-2" style={{marginRight: "-950px"}}>
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assets/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Digitral Marketing</h3>
									<p class="mt-3 mb-4 pr-lg-5">Any marketing that uses electronic devices and can be used by marketing specialists to convey promotional messaging and measure its impact through your customer journey</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assets/images/dm2.png" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assets/images/dm3.png" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assets/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">AWS</h3>
									<p class="mt-3 mb-4 pl-lg-4"> Build, Deploy, and Manage Websites, Apps or Processes On AWS Secure, Reliable Network.</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div class="buttons-w3ls-2" style={{marginRight: "-950px"}}>
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>

					</div>


					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assets/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Android</h3>
									<p class="mt-3 mb-4 pr-lg-5">an automaton that resembles a human being. synonyms: humanoid, mechanical man. type of: automaton, golem, robot.</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assets/images/androd1.jpg" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assets/images/mern11.png" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assets/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">MERN STACK</h3>
									<p class="mt-3 mb-4 pl-lg-4"> a collection of technologies that enables faster application development</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div class="buttons-w3ls-2" style={{marginRight: "-950px"}}>
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>

					</div>

					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assets/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">PYTHON</h3>
									<p class="mt-3 mb-4 pr-lg-5"> developing websites and software, task automation, data analysis, and data visualization.</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assets/images/python111.jpg" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls-2 resposnive_python_button" >
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assets/images/Java-developers1.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assets/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">JAVA</h3>
									<p class="mt-3 mb-4 pl-lg-4">  Java. Java is a popular programming language. Java is used to develop mobile apps,.</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div class="buttons-w3ls-2" style={{marginRight: "-950px"}}>
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/contact" role="button">Apply Now</a>
						</div>

					</div>


				</div>
			</div>
            </div>
    </div>
  )
}

export default Onlineprogram