// import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
// import MobileNav from './Mobilenav';
// import whiteLogo from "./excerpt.png";
// import blackLogo from "./logo2b.png";
// import { TiStar } from "react-icons/ti";
// import './Navbar.css'

// const Navbar = ({ isAdmin, onLogout }) => {
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [logoImage, setLogoImage] = useState({
//     white: whiteLogo,
//     black: blackLogo,
//     width: 200,
//     height: 70,
//   });
//   const [showLogoutToast, setShowLogoutToast] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollY = window.scrollY;
//       if (scrollY > 50) {
//         setIsScrolled(true);
//         setLogoImage({
//           ...logoImage,
//           width: 150,
//           height: 50,
//           marginLeft: 200,
//         });
//       } else {
//         setIsScrolled(false);
//         setLogoImage({
//           ...logoImage,
//           width: 200,
//           height: 70,
//         });
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [logoImage]);

//   const handleLogout = () => {
//     // Perform logout actions
//     onLogout();
//     setShowLogoutToast(true); // Show the toast
//   };

//   useEffect(() => {
//     if (showLogoutToast) {
//       setTimeout(() => {
//         setShowLogoutToast(false);
//       }, 3000); // Hide the toast after 3 seconds
//     }
//   }, [showLogoutToast]);

//   return (
//     <div>
//       <section id="navbar-1" style={{marginTop:"-12px"}}>
//         <div className={`nav-wrapper container ${isScrolled ? 'fixed-navbar' : ''}`} style={{ height: "60px" }}>
//           <div className="d-flex align-items-center justify-content-between">
//             <div className={`logo-wrapper ${isScrolled ? 'visible' : ''}`}>
//               <div className="logo" style={{marginTop:"-5px"}}>
//                 <Link to="/">
//                   <img
//                     src={isScrolled ? logoImage.black : logoImage.white}
//                     width={logoImage.width}
//                     height={logoImage.height}
//                     alt=""
//                     style={{ marginLeft: "-40px" }}
//                   />
//                 </Link>
//               </div>
//             </div>
//             <div className={`nav-content ${isScrolled ? 'scrolled-content' : ''}`} style={{marginLeft:"-80px"}}>
//               <div className="clip-path"></div>
//               <div className="white__background"></div>
//               <nav>
//                 <div className="clip-border" style={{ width: "300px" }}>
//                   <div className="options" style={{height:"50px"}}>
//                     <div className="each-optins-list d-flex align-items-center" style={{marginLeft:"-10px"}}>
//                       <ul className="menus d-flex align-items-center" style={{marginTop:"-12px"}}>
//                         <li className="">
//                           <Link to="/">HOME</Link>
//                           <div className="dropdown__menu submenu"></div>
//                         </li>
//                         <li className="menu dropdown">
//                           <Link to="/services">SERVICES</Link>
//                           <div className="dropdown__menu submenu bigger-width">
//                             <Link to="/ecommerce">E-commerce</Link>
//                             <Link to="/webdesign">Web Design</Link>
//                             <Link to="/webdevelopment">Web Development</Link>
//                             <Link to="/mobileapplication">Mobile Application</Link>
//                             <Link to="/cybersecurity">Cyber Security</Link>
//                             <Link to="/digitalmarketing">Digital Marketing</Link>
//                             <Link to="/seo">SEO Analytics</Link>
//                             <Link to="/dataanalytics">Data Analytics</Link>
//                             <Link to="/uiux">UI/UX</Link>
//                           </div>
//                         </li>
//                         <li className="menu">
//                           <Link to="/portfolio">PORTFOLIO</Link>
//                         </li>
//                         <li className="menu">
//                           <Link to="/contact">CONTACT</Link>
//                         </li>
//                         <li class="menu">
//                           <a href="/careers" style={{color:"blue"}}
                          
//                           >CAREERS <TiStar className="star blink star-button" /> </a>
//                         </li>

//                         {isAdmin && (

//                           <li className="menu search-option">
//                             <Link to="/dashboard" className="get-start-button d-flex align-items-center" style={{width:"20px",marginTop:"-1px"}}>
//                               <b>ADMIN</b>
//                             </Link>
//                           </li>

//                         )}
//                       </ul>
//                       <br />
//                       <br />
//                       <div className="search-option">
//                         <div className="d-flex align-items-center">
//                           <a href="https://itdeskmahadevpura.com/" target="_blank" className="get-start-button" style={{marginLeft:"20px",marginTop:"-11px"}}>
//                             <b>TRAINING</b>
//                           </a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </nav>
//             </div>
//           </div>
//         </div>
//       </section>
//       <MobileNav />
//       {showLogoutToast && (
//         <div className="logout-toast">
//           Logout successful
//         </div>
//       )}
//     </div>
//   );
// };

// export default Navbar;


import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import MobileNav from './Mobilenav';
import whiteLogo from "./excerpt.png";
import blackLogo from "./logo2b.png";
import { TiStar } from "react-icons/ti";
import { FaPhoneAlt } from "react-icons/fa";
import PopupImg from "../Pages/Training/PopupImage/PopupImg";
import './Navbar.css'

const Navbar = ({ isAdmin, onLogout }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const [logoImage, setLogoImage] = useState({
    white: whiteLogo,
    black: blackLogo,
    width: 200,
    height: 70,
  });
  const [showLogoutToast, setShowLogoutToast] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setIsScrolled(true);
        setLogoImage({
          ...logoImage,
          width: 150,
          height: 50,
          marginLeft: 200,
        });
      } else {
        setIsScrolled(false);
        setLogoImage({
          ...logoImage,
          width: 200,
          height: 70,
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [logoImage]);

  const handleLogout = () => {
    // Perform logout actions
    onLogout();
    setShowLogoutToast(true); // Show the toast
  };

  useEffect(() => {
    if (showLogoutToast) {
      setTimeout(() => {
        setShowLogoutToast(false);
      }, 3000); // Hide the toast after 3 seconds
    }
  }, [showLogoutToast]);

 

  return (
    <div>
      <section id="navbar-1" style={{ marginTop: "-12px" }}>
        <div className={`nav-wrapper container ${isScrolled ? 'fixed-navbar' : ''}`} style={{ height: "60px" }}>
          <div className="d-flex align-items-center">
            <div className={`logo-wrapper ${isScrolled ? 'visible' : ''}`}>
              <div className="logo" style={{ marginTop: "-5px" }}>
                <Link to="/">
                  <img
                    src={isScrolled ? logoImage.black : logoImage.white}
                    width={logoImage.width}
                    height={logoImage.height}
                    alt=""
                    style={{ marginLeft: "-40px" }}
                  />
                </Link>
              </div>
              <div className="phone-number mobile-only">
              <FaPhoneAlt style={{color:"white"}}/>
                <a href="tel:+91 63646 57660" style={{color:"white"}}> +91 63646 57660</a>
              </div>
            </div>
            <div className={`nav-content ${isScrolled ? 'scrolled-content' : ''}`} style={{ marginLeft: "-80px" }}>
              <div className="clip-path"></div>
              <div className="white__background"></div>
              <nav>
                <div className="clip-border" style={{ width: "300px" }}>
                  <div className="options">
                    <div className="each-optins-list d-flex align-items-center" style={{ marginLeft: "-10px" }}>
                      <ul className="menus d-flex align-items-center" style={{ marginTop: "-12px" }}>
                        <li className="">
                          <Link to="/">HOME</Link>
                          <div className="dropdown__menu submenu"></div>
                        </li>
                        <li className="menu dropdown">
                          <Link to="/services">SERVICES</Link>
                          <div className="dropdown__menu submenu bigger-width">
                           
                            <Link to="/webdesign">Web Design & Development</Link>
                            {/* <Link to="/webdevelopment">Web Development</Link> */}
                            <Link to="/mobileapplication">Mobile Application</Link>
                            <Link to="/cybersecurity">Cyber Security</Link>
                            <Link to="/digitalmarketing">Digital Marketing</Link>
                            <Link to="/seo">SEO Analytics</Link>
                            <Link to="/ecommerce">E-commerce</Link>
                            <Link to="/dataanalytics">Data Analytics</Link>
                            <Link to="/uiux">UI/UX</Link>
                            <Link to="/trainings">Corporate Training Services</Link>
                          </div>
                        </li>
                        
                        <li className="menu">
                          <Link to="/portfolio">PORTFOLIO</Link>
                        </li>
                        <li className="menu">
                          <Link to="/contact">CONTACT</Link>
                        </li>
                        <li class="menu">
                          <a href="/careers" style={{ color: "blue" }}
                          >CAREERS <TiStar className="star blink star-button" /> </a>
                        </li>
                        <li className="menu dropdown">
                          <Link to="/trainings"><button style={{width:'100px',fontSize:'15px',backgroundColor:'#0554f2'}}>Trainings</button></Link>
                          {/* <div className="dropdown__menu submenu bigger-width">
                            <Link to="https://itdeskmahadevpura.com/" target="_blank">IT Trainings</Link>
                            <Link to="https://caddeskmahadevpura.com/" target="_blank">CAD Trainings</Link>
                           
                          </div> */}
                        </li>

                        {isAdmin && (

                          <li className="menu search-option">
                            <Link to="/dashboard" className="get-start-button d-flex align-items-center" style={{ width: "20px", marginTop: "-1px" }}>
                              <b>ADMIN</b>
                            </Link>
                          </li>

                        )}
                      </ul>
                      <br />
                      <br />
                      {/* <div className="search-option">
                        <div className="d-flex align-items-center">
                          <a href="https://itdeskmahadevpura.com/" target="_blank" className="get-start-button" style={{ marginLeft: "20px", marginTop: "-11px" }}>
                            <b>TRAINING</b>
                          </a>
                        </div>
                      </div> */}




                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
       
      </section>
      <MobileNav />
      {showLogoutToast && (
        <div className="logout-toast">
          Logout successful
        </div>
      )}
    </div>
  );
};

export default Navbar;
