import React from 'react'
import { Link } from 'react-router-dom'
import './Internship.css'
import InternProject from './InternProject'
import { px } from 'framer-motion'

const Internship = () => {
  return (
    <div>
        <div className="tab-container" style={{marginTop:"40px"}}>
                    
                    <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Allprogram" style={{color:"white"}}>Allprogram</Link>
                    </button>
                    <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Onlineprogram" style={{color:"white"}}>Onlineprogram</Link>
                    </button>
                    <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Internship" style={{color:"white"}}>Internship</Link>
                    </button>
                    <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Placement" style={{color:"white"}}>Placement</Link>
                    </button>
                </div>

      <div className="banner-agile-2">

        {/* <div className="navigation-w3ls">
          <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
            <button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
              <ul className="navbar-nav justify-content-center">
                <li className="nav-item active">

                  <a className="nav-link text-white" href="/">Home
                    <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/about">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/allprogram">All Programs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/Onlineprogram">Online Programs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/internship">Internship</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/placement">Placement</a>
                </li>

               
                <li className="nav-item">
                  <a className="nav-link text-white" href="/contactus">Contactus</a>
                </li>


              </ul>
            </div>
          </nav>
        </div> */}
      </div>
      {/* <nav aria-label="breadcrumb">
        <ol className="breadcrumb" style={{ fontSize: "14px", fontFamily:"'Open Sans', sans-serif",padding : (".5em",".5em"), backgroundColor: "#e9ecef"}}>
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">Internship</li>
        </ol>
      </nav> */}



      {/* <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5"> INTERNSHIP
                        <span className="font-weight-bold"> Courses</span>
                    </h3> */}



      <div id="main-wrapper">
        <div class="site-wrapper-reveal">

          <div class="blog-pages-wrapper section-space--ptb_100">
            <div class="container">
              <div class="row" style={{marginLeft:"70px",marginTop:"30px"}}>
               <img src='./assets/images/IT.png' style={{width:"500px", height:"550px"}}/>
               <img src='./assets/images/non1.jpeg' style={{width:"500px", height:"550px"}}/>

               </div>
              
              </div>
            </div>
            <InternProject/>
                
        </div>
      </div>



      
    </div>
  )
}

export default Internship
                {/* <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assets/images/c++.jpg" alt="C/C++" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br />
                        <br />
                        <a href="/onlineprogram">C/C++ Internship</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>C and C++ both are powerful languages to develop p..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2> <i class="ml-1 button-icon far fa-long-arrow-right" ></i></a>
                      </div>
                    </div>

                  </div>


                </div>
                <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="./images/py.jpg" alt="Python Programming" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br />
                        <a href="/onlineprogram">Python Programming Internship</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>Simple, clean syntax, object encapsulation, good l..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2><i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div> */}




                {/* <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assets/images/images/digital-marketin1.webp" alt="Digital Marketing" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br />
                        <a href="/onlineprogram">Digital Marketing Internship</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>A most powerful tool to grow business and increase..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2> <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div> */}

{/* 
                <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assets/images/javai.webp" alt="Core Java Internship" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br />
                        <a href="/onlineprogram">Core Java Internship</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>Most popular in Console Application, Desktop Appli..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2> <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div> */}


                {/* <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="./images/web4.jpg" alt="Web Design Internship" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br />
                        <a href="/onlineprogram">Web Design and Development Internship</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>Most popular in Websites Designing (static website..)</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2> <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div> */}

                {/* <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assets/images/cloud1.jpeg" alt="Core Java Internship" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br />
                        <a href="/onlineprogram"> Cloud Computing Internship</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>Most popular in Console Application, Desktop Appli..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more.....</h2> <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div> */}


           
          {/* <div class="cta-image-area_one section-space--ptb_80 cta-bg-image_one">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-xl-8 col-lg-7">
                  <div class="cta-content md-text-center">
                    <h3 class="heading text-white">10,000 Students Already Registred <span class="text-color-secondary">In Internships Course</span></h3>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-5">
                  <div class="cta-button-group--one text-center">
                    <a href="./" class="btn btn--white btn-one quick-option"><span class="btn-icon mr-2"><i class="far fa-comment-alt-dots"></i></span></a>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="projects-wrapper projectinfotechno-bg section-space--ptb_100">
            <div class="container">

               <div class="row">
                <div class="col-lg-12">

                  <div class="section-title-wrap text-center section-space--mb_40">

                    <h3 class="heading">More <span class="text-color-primary"> Course Categories</span></h3>
                  </div>

                </div>
              </div> 

               <div class="row">
                <div class="col-12">
                  <div class="projects-wrap swiper-container projects-slider__container swiper-container-initialized swiper-container-horizontal">
                    <div class="swiper-wrapper" style={{ transform: "translate3d(-1170px,0px,0px); transition-duration:0ms" }}>
                      <div class="swiper-slide swiper-slide-duplicate-prev" data-swiper-slide-index="3" style={{ width: "390px" }}>
                        
                        <a href="https://itdeskindia.com/categories/trending-courses/" class="projects-wrap style-01 wow move-up animated" style={{ visibility: "visible" }}>
                          <div class="projects-image-box">
                            <div class="projects-image" >
                              <img class="img-fluid" src="assets/images/images111.png" alt="Trending Courses" />
                            </div>
                            <div class="content">
                              <h6 class="heading">Trending Courses</h6>
                            
                              <div class="text"></div>
                              <div class="box-projects-arrow">
                                <span class="button-text">View More</span>
                                <i class="fa fa-long-arrow-right ml-1"></i>
                              </div>
                            </div>
                          </div>
                        </a>

                      </div>
                      <div class="swiper-slide swiper-slide-duplicate-prev" data-swiper-slide-index="3" style={{ width: "390px" }}>

                        <a href="https://itdeskindia.com/categories/cs-it/" class="projects-wrap style-01 wow move-up animated" style={{ visibility: "visible" }}>
                          <div class="projects-image-box">
                            <div class="projects-image">
                              <img class="img-fluid" src="assets/images/download11.jpg" alt="CS/IT" />
                            </div>
                            <div class="content">
                              <h6 class="heading">CS/IT</h6>
                          
                              <div class="text"></div>
                              <div class="box-projects-arrow">
                                <span class="button-text">View More</span>
                                <i class="fa fa-long-arrow-right ml-1"></i>
                              </div>
                            </div>
                          </div>
                        </a>

                      </div>
                    </div>
                    <div class="swiper-pagination swiper-pagination-project mt_20 swiper-pagination-clickable swiper-pagination-bullets"><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 4"></span></div>
                    <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>



                </div>
              </div> 
            </div>
          </div> */}


