// import React, { useState, useEffect } from 'react';
// import './Slider.css'

// function Slider() {
//   const slides = [
//     {
//       image: 'images/home1/slider/s1.jpg',
//       caption: 'Breaking Boundaries, One Innovation At a Time.',
//     },
//     {
//       image: 'images/home1/slider/s2.jpg',
//       caption: 'Where Ideas Ignite And Dreams Take Flight',
//     },
//     {
//       image: 'images/home1/slider/s3.jpg',
//       caption: 'A Triumphant Three Years Of Software ',
//     },
//     {
//       image: 'Trainingimage/img.jpg',
//       caption: '  IT Corporate Training ',
//     },
//     {
//       image: 'Trainingimage/sm.jpg',
//       caption: '  ',
//     },
//   ];

//   const [currentSlide, setCurrentSlide] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
//     }, 4000); 

//     return () => clearInterval(intervalId);
//   }, [slides.length]);

//   return (
//     <div>
//       <section id="hero">
//         <div className="hero-1-slide" style={{ height: '100px' }}>
//           <div className="slide-wrapper" >
//             {slides.map((slide, index) => (
//               <div
//                 key={index}
//                 className="slide-image slide-card"
//                 style={{
//                   backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
//                                      url(${slide.image})`,
//                   display: index === currentSlide ? 'block' : 'none',
//                   backgroundSize: 'cover',
//                   backgroundRepeat: 'no-repeat',
//                   height: '6s00px',
//                   width: '100%',
//                   '@media (max-width: 768px)': {
//                     height: '300px',
//                   },
//                   '@media (max-width: 480px)': {
//                     height: '200px',
//                   },
//                   animation: 'slideAnimation 5s forwards',
//                   animationDirection: 'alternate', 
//                 }}
              
//               >
//                 <div className="slide__card--wrapper">
                  
//                   <h2 className="heading-2 mb-25" style={{ animation: 'textAnimation 1s forwards',color:"white" }}>{slide.caption}</h2>
//                   <h1 style={{ animation: 'textAnimation 1s forwards' }}>IT Solutions</h1>
//                   <div className="heading-7 mb-30" style={{ animation: 'textAnimation 1s forwards',color:"white" }}>
//                     Innovative Solutions, Seamless Experiences.
//                   </div>
//                   <a href="/trainings" className="button-primary-1" style={{ animation: 'textAnimation 1s forwards' }}>
//                     <span>Read More</span>
//                   </a>
//                 </div>
//               </div>
//             ))}
//           </div>
//           <img className="curve-overlay" style={{ marginTop: '100px', height: "60px" }} src="images/home1/c1.png" alt="" />
//           <div className="overlay-wrapper">
//             <div className="left-overlay"></div>
//             <div className="right-overlay"></div>
//           </div>
//         </div>

//         <div className="hero-contact d-flex">
//           <ul className="d-flex phone-email">
//             <li className="paragraph-3">
//               <span>
//                 <i className="fa-solid fa-envelope"></i>
//               </span>
//               <a href="mailto:info@excerptech.com">info@excerptech.com</a>
//             </li>
//             <li className="paragraph-3">
//               <span>
//                 <i className="fa-solid fa-phone"></i> 
//                 <a href='tel:+916364657660'> +91 63646 57660</a>
//               </span>
//             </li>
//             <li className="paragraph-3" style={{ marginRight: "-42px" }}></li>
//           </ul>
//           <ul className="d-flex social">
//             <li>
//               <a href="https://www.facebook.com/Excerptechnology/" target="_blank">
//                 <i className="fa-brands fa-facebook-f"></i>
//               </a>
//             </li>
//             <li>
//               <a href="https://www.instagram.com/excerpt_technologies/?igsh=Z3k5OXozNDIzZHps" target="_blank">
//                 <i className="fa-brands fa-instagram"></i>
//               </a>
//             </li>
//             <li>
//               <a href="https://www.linkedin.com/company/excerptech" target="_blank">
//                 <i className="fa-brands fa-linkedin-in"></i>
//               </a>
//             </li>
//           </ul>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default Slider;


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Slider.css';

function Slider() {
  const slides = [
    {
      image: 'images/home1/slider/s1.jpg',
      caption: 'Breaking Boundaries, One Innovation At a Time.',
    },
    {
      image: 'images/home1/slider/s2.jpg',
      caption: 'Where Ideas Ignite And Dreams Take Flight',
    },
    {
      image: 'images/home1/slider/s3.jpg',
      caption: 'A Triumphant Three Years Of Software',
    },
    {
      image: 'Trainingimage/img.jpg',
      caption: 'IT Corporate Training',
    },
    {
      image: 'Trainingimage/sm.jpg',
      caption: '',
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [slides.length]);

  const getSlideHeightClass = () => {
    if (window.innerWidth <= 480) return 'height-200';
    if (window.innerWidth <= 768) return 'height-300';
    return 'height-600';
  };

  return (
    <div>
      <section id="hero">
        <div className="hero-1-slide">
          <div className="slide-wrapper">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`slide-image slide-card ${getSlideHeightClass()}`}
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url(${slide.image})`,
                  display: index === currentSlide ? 'block' : 'none',
                }}
              >
                <div className="slide__card--wrapper">
                  <h2 className="heading-2 mb-25" style={{ color: 'white' }}>{slide.caption}</h2>
                  <h1>IT Solutions</h1>
                  <div className="heading-7 mb-30" style={{ color: 'white' }}>
                    Innovative Solutions, Seamless Experiences.
                  </div>
                  {slide.caption === 'IT Corporate Training' ? (
                    <Link to="/trainings" className="button-primary-1">
                      <span>Read More</span>
                    </Link>
                  ) : (
                    <Link to="/portfolio" className="button-primary-1">
                      <span>Read More</span>
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
          <img className="curve-overlay" style={{ marginTop: '100px', height: '60px' }} src="images/home1/c1.png" alt="" />
          <div className="overlay-wrapper">
            <div className="left-overlay"></div>
            <div className="right-overlay"></div>
          </div>
        </div>

        <div className="hero-contact d-flex">
          <ul className="d-flex phone-email">
            <li className="paragraph-3">
              <span>
                <i className="fa-solid fa-envelope"></i>
              </span>
              <a href="mailto:info@excerptech.com">info@excerptech.com</a>
            </li>
            <li className="paragraph-3">
              <span>
                <i className="fa-solid fa-phone"></i>
                <a href="tel:+916364657660"> +91 63646 57660</a>
              </span>
            </li>
          </ul>
          <ul className="d-flex social">
            <li>
              <a href="https://www.facebook.com/Excerptechnology/" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/excerpt_technologies/?igsh=Z3k5OXozNDIzZHps" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/excerptech" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Slider;
