import React from 'react';

const DataScience = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div className="about-banner-wrap vision-space bg-img" style={{ backgroundColor: "darkturquoise", height: "600px", position: "relative" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 section-space--pt_100">
                <h1 style={{ color: "#fff" }}>Data Science with Python</h1>
                <p style={{ color: "#fff" }}>Data science with Python performs data analysis, data visualization, web scraping, and more.</p>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-4 col-12">
                <div className="form-container mx-auto mx-xl-0 ml-xl-auto border" id="corseform">
                  <div id="signup-form">
                    <form id="basic-form" name="yoursite_wp_mail_from_name" method="post">
                      <input type="hidden" name="courseId" value="766" />
                      <input type="hidden" name="category" value="16" />
                      <div className="form-group">
                        <label>Name:</label>
                        <input type="text" id="fname" name="fname" className="form-control" placeholder="Enter Your Name" required />
                      </div>
                      <div className="form-group">
                        <label>Email:</label>
                        <input type="email" id="email" name="email" className="form-control" placeholder="Enter Your Email Address" required />
                      </div>
                      <div className="form-group">
                        <label>Mobile:</label>
                        <input type="number" id="phone" name="phone" className="form-control" placeholder="Enter Your Mobile Number" required />
                      </div>
                      <div className="form-group mb-0">
                        <div className="form-row">
                          <div className="form-group col-6">
                            <label className="control-label">State</label>
                            <select id="states" name="states" className="form-control" required>
                              <option value="">SELECT STATE</option>
                              <option value="2">Andhra Pradesh</option>
                              <option value="5">Bihar</option>
                              <option value="12">Gujarat</option>
                              <option value="13">Haryana</option>
                              <option value="14">Himachal Pradesh</option>
                              <option value="16">Jharkhand</option>
                              <option value="17">Karnataka</option>
                              <option value="21">Maharashtra</option>
                              <option value="28">Punjab</option>
                              <option value="29">Rajasthan</option>
                              <option value="31">Tamil Nadu</option>
                              <option value="32">Telangana</option>
                              <option value="35">Uttar Pradesh</option>
                            </select>
                          </div>
                          <div className="form-group col-6 city">
                            <label className="control-label">Center</label>
                            <select name="city" id="city" className="form-control center" required>
                              <option value="">SELECT CENTRE</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-0">
                        <button type="submit" id="btn" className="btn btn-primary contest_register_now" name="register_users">Apply Now</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav aria-label="breadcrumb" className="breadcrumb-container" style={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)", display: "flex", justifyContent: "center", width: "100%" }}>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">Home</a></li>
              <li className="breadcrumb-item active" aria-current="page">Python</li>
            </ol>
          </nav>
        </div>

        <div className="vision-overview">
          <div className="container">
            <div className="section-title-wrap text-center">
              <br />
              <br />
              <h3 className="heading"><span className="text-color-primary" style={{ fontSize: "21px" }}>Training Highlights</span></h3>
            </div>
            <div className="row">
              {[
                { src: "./Trainingimage/img11.jpg", title: "Instructor Led Training" },
                { src: "./assets/images/img22.jpg", title: "Weekly Doubt Clearing Session" },
                { src: "./assets/images/img33.jpg", title: "Lifetime Assistance for Revision" },
                { src: "./assets/images/img44.jpg", title: "Lifetime Login Access to Student Dashboard" },
                { src: "./assets/images/img55.jpg", title: "World-Wide Valid Certification" },
                { src: "./assets/images/img666.jpg", title: "Study Material, Assignments and Projects" },
              ].map((item, index) => (
                <div key={index} className="col-12 col-lg-4">
                  <div className="item-container d-flex align-items-center">
                    <div className="res-image-container">
                      <img src={item.src} className="lazy-load" height="150px" width="200px" style={{ borderRadius: "5px", margin: "10px" }} />
                    </div>
                    <div className="content-container">
                      <h3 className="title">{item.title}</h3>
                      <p className="description"></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <br /> <br />
            <div className="section-title-wrap text-center">
              <br />
              <h3 className="heading"><span className="text-color-primary" style={{ fontSize: "21px" }}>Course Highlights</span></h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-12">
            <br />
            <br />
            <h3 className="heading" style={{ fontSize: "21px", marginTop: "-80px", textAlign: "center" }}>Course Overview</h3>
            <div className="vision-content">
              <h2>Data Science with Python Course: Unlocking the Power of Data</h2>
              <p>Are you ready to embark on an exciting journey into the world of data science? Look no further than the Data Science with Python course at Excerpt Technologies IT Training Services in Bangalore. This comprehensive training program is designed to equip you with the skills and knowledge needed to excel in the field of data science using Python. In this article, we will explore the course details, the benefits of learning data science with Python, and why Excerpt Technologies IT Training Services is the perfect training institute for your educational needs.</p>
              <p><strong>Table of Contents</strong></p>
              <ul style={{ fontSize: '15px', fontFamily: 'Open Sans, sans-serif' }}>
                <li>Introduction to Data Science with Python Course</li>
                <li>The Importance of Data Science in Today’s World</li>
                <li>Benefits of Data Science with Python Course Training</li>
                <li>Course Curriculum and Training Approach</li>
                <li>Industry-Relevant Projects and Case Studies</li>
                <li>Experienced Faculty and Mentors</li>
                <li>Hands-on Practical Sessions</li>
                <li>Flexible Learning Options</li>
                <li>Certification Program and Recognition</li>
                <li>Placement Assistance and Career Support</li>
                <li>Data Science with Python Course Training Institute</li>
                <li>Data Science with Python Course Fees & Class</li>
                <li>Excerpt Technologies IT Training Services: A Trusted Training Institute</li>
                <li>FAQs</li>
              </ul>
              <br />
              <br />
              <h2><strong>Introduction to Data Science with Python Course</strong></h2>
              <p>Data Science is a multidisciplinary field that combines various techniques, algorithms, and tools to extract insights and knowledge from structured and unstructured data. Python, a versatile programming language, has gained immense popularity among data scientists due to its simplicity, extensive libraries, and powerful analytical capabilities. The Data Science with Python course in Bangalore offered by Excerpt Technologies IT Training Services provides a comprehensive introduction to the fundamental concepts and practical applications of data science using Python.</p>
              <br />
              <p><strong>The Importance of Data Science in Today’s World</strong></p>
              <p>In today’s data-driven world, organizations across industries are leveraging data to make informed decisions, drive innovation, and gain a competitive edge. Data science plays a crucial role in analyzing large volumes of data, uncovering patterns, and deriving actionable insights. Python’s rich ecosystem of libraries and tools makes it an ideal language for data analysis, machine learning, and visualization, making it an essential skill for aspiring data scientists.</p>
              <br />
              <h2><strong>Benefits of Data Science with Python Course Training</strong></h2>
              <p>Enrolling in the Data Science with Python course at Excerpt Technologies IT Training Services offers numerous benefits:</p>
              <p><strong>Comprehensive Knowledge:</strong> Gain a deep understanding of data science concepts, including data manipulation, analysis, visualization, and machine learning using Python.</p>
              <p><strong>Industry-Relevant Skills:</strong> Learn how to apply data science techniques to real-world scenarios, preparing you for roles in various industries.</p>
              <p><strong>Hands-on Experience:</strong> Engage in practical projects and case studies that simulate real-world data challenges and solutions.</p>
              <p><strong>Experienced Instructors:</strong> Benefit from the expertise of experienced faculty members and industry professionals who provide valuable insights and guidance.</p>
              <p><strong>Flexible Learning Options:</strong> Choose from various learning formats, including classroom training, online sessions, and weekend batches, to suit your schedule.</p>
              <p><strong>Certification:</strong> Earn a recognized certification that validates your skills and enhances your career prospects.</p>
              <br />
              <h2><strong>Course Curriculum and Training Approach</strong></h2>
              <p>The Data Science with Python course at Excerpt Technologies IT Training Services covers a comprehensive curriculum designed to provide a solid foundation in data science concepts and practical skills. The course includes:</p>
              <ul>
                <li>Introduction to Data Science and Python Programming</li>
                <li>Data Manipulation and Analysis using Pandas</li>
                <li>Data Visualization with Matplotlib and Seaborn</li>
                <li>Statistical Analysis and Hypothesis Testing</li>
                <li>Machine Learning Algorithms and Models</li>
                <li>Data Preprocessing and Feature Engineering</li>
                <li>Model Evaluation and Hyperparameter Tuning</li>
                <li>Hands-on Projects and Case Studies</li>
              </ul>
              <p>The training approach combines theoretical knowledge with practical exercises, ensuring that you gain a thorough understanding of data science principles and their applications.</p>
              <br />
              <h2><strong>Industry-Relevant Projects and Case Studies</strong></h2>
              <p>To enhance your learning experience, the course incorporates industry-relevant projects and case studies. These real-world scenarios provide practical exposure to solving data science problems and implementing solutions using Python. By working on projects, you will develop a portfolio of work that demonstrates your skills and expertise to potential employers.</p>
              <br />
              <h2><strong>Experienced Faculty and Mentors</strong></h2>
              <p>At Excerpt Technologies IT Training Services, you will learn from experienced faculty members and industry experts who bring a wealth of knowledge and practical experience to the classroom. Our instructors are passionate about data science and dedicated to providing high-quality training and mentorship throughout the course.</p>
              <br />
              <h2><strong>Hands-on Practical Sessions</strong></h2>
              <p>The course emphasizes hands-on practical sessions to ensure that you gain real-world experience in applying data science techniques. Through coding exercises, data analysis tasks, and project work, you will develop the skills needed to tackle complex data challenges and drive data-informed decision-making.</p>
              <br />
              <h2><strong>Flexible Learning Options</strong></h2>
              <p>We understand that individuals have different learning preferences and schedules. Therefore, the Data Science with Python course offers flexible learning options, including classroom training, online sessions, and weekend batches. You can choose the mode of learning that best fits your needs and availability.</p>
              <br />
              <h2><strong>Certification Program and Recognition</strong></h2>
              <p>Upon successful completion of the course, you will receive a certification from Excerpt Technologies IT Training Services. This certification serves as a testament to your skills and knowledge in data science with Python, enhancing your credibility and employability in the job market.</p>
              <br />
              <h2><strong>Placement Assistance and Career Support</strong></h2>
              <p>At Excerpt Technologies IT Training Services, we are committed to supporting your career growth. Our placement assistance program provides guidance on resume building, interview preparation, and job placement opportunities. We aim to help you secure relevant positions in the data science field and achieve your career goals.</p>
              <br />
              <h2><strong>Data Science with Python Course Training Institute</strong></h2>
              <p>Excerpt Technologies IT Training Services is a leading training institute in Bangalore, known for its quality education and industry-relevant training programs. Our Data Science with Python course is designed to meet the demands of the industry and provide you with the skills needed to excel in the field of data science.</p>
              <br />
              <h2><strong>Data Science with Python Course Fees & Class</strong></h2>
              <p>The course fees for the Data Science with Python program are competitively priced to offer value for your investment. For detailed information on course fees, class schedules, and enrollment options, please contact our admissions team.</p>
              <br />
              <h2><strong>Excerpt Technologies IT Training Services: A Trusted Training Institute</strong></h2>
              <p>Excerpt Technologies IT Training Services is a trusted training institute with a track record of delivering high-quality education and training programs. Our experienced instructors, comprehensive curriculum, and commitment to student success make us the ideal choice for your data science education.</p>
              <br />
              <h2><strong>FAQs</strong></h2>
              <ul>
                <li><strong>Q:</strong> What are the prerequisites for the Data Science with Python course?<br /><strong>A:</strong> Basic knowledge of programming concepts and familiarity with Python is recommended.</li>
                <li><strong>Q:</strong> Can I take the course online?<br /><strong>A:</strong> Yes, we offer online learning options for the Data Science with Python course.</li>
                <li><strong>Q:</strong> What career opportunities are available after completing the course?<br /><strong>A:</strong> Graduates can pursue roles such as Data Scientist, Data Analyst, Machine Learning Engineer, and more.</li>
                <li><strong>Q:</strong> How can I enroll in the course?<br /><strong>A:</strong> You can contact our admissions team for information on enrollment and registration.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataScience;
