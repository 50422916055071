import React, { useEffect, useState } from 'react';

const 
ResumeComponent = () => {
  const [resumeData, setResumeData] = useState([]);

  useEffect(() => {
    const fetchResumeData = async () => {
      try {
        const response = await fetch('/api/resumedata');
        const data = await response.json();
        setResumeData(data);
      } catch (error) {
        console.error('Error fetching resume data:', error);
      }
    };

    fetchResumeData();
  }, []); // The empty dependency array ensures this effect runs once after the initial render

  const handleDownload = async (resumePath) => {
    try {
      const response = await fetch(`/api/resumes/${resumePath}`);
      const blob = await response.blob();
      
      // Create a temporary anchor element and trigger a download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = resumePath; // Set the download file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading resume file:', error);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Resume Data</h2>
      <table style={{ width: '80%', borderCollapse: 'collapse', marginTop: '20px', border: '1px solid #ddd', margin: '0 auto' }}>
        <thead>
          <tr style={{ border: '1px solid #ddd' }}>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Email</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Job</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Resume File</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {resumeData.map((resume) => (
            <tr key={resume._id} style={{ border: '1px solid #ddd' }}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{resume.name}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{resume.email}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{resume.job}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                <a href={`/api/resumes/${resume.resumePath}`} target="_blank" rel="noopener noreferrer">
                  {resume.resumePath}
                </a>
              </td>
              <td style={{ border: '1px solid #ddd', padding: '' }}>
                <button onClick={() => handleDownload(resume.resumePath)} style={{width:"110px"}}>Download</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResumeComponent;
