import React from "react";

const Cybersecurity = () => {
  return (
    <div>
      <section
        style={{
          backgroundImage: "url(images/banners1se1.jpg)",
          backgroundColor: "rgb(255, 255, 255)",
        }}
        class="sub-header position-relative"
      >
        <div class="container">
          <div class="page-info position-absolute">
            <h2 class="heading-2"  style={{color:'white',textAlign:'center'}}>Cyber Security </h2>
            <h6 class="heading-6">
              <a href="/">Home</a> / <a href="#">Cyber Security</a>
            </h6>
          </div>
        </div>
      </section>

      <section id="service-details" class="service-details">
        <div class="container">
          <div class="row g-4">
            <div class="col-md-8 mb-40">
              <div class="img-wrapper mb-25">
                <img src="images/cb3.jpg" class="img-fluid" alt="" />
              </div>

              <h4 class="heading-4 mb-25" >Cyber Security</h4>

              <div class="paragraph mb-40">
                We prioritize safeguarding your digital assets with our
                comprehensive cyber security services. Our experts protect your
                business from cyber threats, ensuring data security and
                uninterrupted operations. From threat detection to compliance
                management, we offer tailored solutions with the latest
                technologies for robust defenses.
              </div>

              <div class="row g-4 mb-25">
                <div class="col-md-6">
                  <div class="img-wrapper">
                    <img
                      src="images/cb1.jpg"
                      class="img-fluid sub"
                      alt=""
                      style={{ height: "300px" }}
                    />
                  </div>
                </div>
                <div class="d-none d-md-block col-md-6">
                  <div class="img-wrapper">
                    <img src="images/cb2.jpg" class="img-fluid sub" alt="" />
                  </div>
                </div>
              </div>

              <h4 class="heading-3 mb-25">Manage designing Solutions</h4>

              <div class="paragraph mb-40">
                We customize solutions to your security needs, crafting
                effective and scalable architectures. Our experts collaborate
                with you throughout the process, ensuring ongoing protection and
                adaptability for your evolving business.
              </div>

              <div class="service-divider"></div>
            </div>
            <div class="d-none d-md-block col-md-4">
              <div class="service">
                <h4 class="heading-3">Services</h4>

                <div class="underline"></div>

                <ul class="service-list">
                  <li class="active">
                    <a href="/webdesign">
                      <div class="d-flex justify-content-between item">
                        <span>Web Design and Development</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/mobileapplication">
                      <div class="d-flex justify-content-between item">
                        <span>MobileApplication</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/ecommerce">
                      <div class="d-flex justify-content-between item">
                        <span>Ecommerce</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/DigitalMarketing">
                      <div class="d-flex justify-content-between item">
                        <span>DigitalMarketing</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Seo">
                      <div class="d-flex justify-content-between item">
                        <span>SEOAnalytics</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/Dataanalytics">
                      <div class="d-flex justify-content-between item">
                        <span>DataAnalytics</span>
                        <div class="icon">
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="squre-banner">
                <h2 class="heading-3">Easy solutions to your tech problem</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Cybersecurity;
