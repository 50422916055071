import React from 'react'

function HomeCard() {
  return (
    <div>
         <section id="service" class="service">
    <div class="container" style={{maxWidth:"1340px"}} >
        <div class="service-cards row g-4">

        <div class="col-md-6 col-lg-3">
                <div class="service-card">
                    <div class="img_wrapper mb-25">
                        <img src="images/home1/service/2.png" alt="" />
                    </div>
                    <h5 class="heading-5 mb-15">Web Design and Development</h5>
                    <div class="paragraph mb-30">
                     Creation and maintenance of websites. It encompasses the design.
                    {/* Where Innovation Meets Impeccable User Experience." */}






                    </div>
                    <a href="/webdesign" class="button-primary-1">
        <span> Read More </span>
      </a>

                    <div class="circle"></div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 hideOnMd">
                <div class="service-card" style={{height:"420px"}}>
                    <div class="img_wrapper mb-25">
                        <img src="images/home1/service/1.png" alt="" />
                    </div>
                    <h5 class="heading-5 mb-15">AI/ML, Data Analytics and Data Science</h5>
                    <div class="paragraph mb-30">
                    With our AI/ML solutions, we transform your business with data-driven strategies today!
                    </div>
                    <a href="/CyberSecurity" class="button-primary-1">
        <span> Read More </span>
      </a>
                    <div class="circle"></div>
                </div>
            </div>
            




            <div class="col-md-6 col-lg-3" >
                <div class="service-card" style={{height:"420px"}}>
                    <div class="img_wrapper mb-25">
                        <img src="images/home1/service/3.png" alt="" />
                    </div>
                    <h5 class="heading-5 mb-15">Mobile App Development </h5>
                    <div class="paragraph mb-30">
                    Creating software applications specifically designed to operate on mobile devices.</div>
                    <a href="/mobileapplication" class="button-primary-1">
        <span> Read More </span>
      </a>

                    <div class="circle"></div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="service-card">
                    <div class="img_wrapper mb-25">
                        <img src="images/home1/service/4.png" alt="" />
                    </div>
                    <h5 class="heading-5 mb-15">Digital Marketing</h5>
                    <div class="paragraph mb-30">
                    We aim to boost your business with our digital marketing services to elevate your brand's presence and engagement
                    {/* Elevate your online presence with our cutting-edge ecommerce solutions.  */}
                    </div>
                    <a href="/ecommerce" class="button-primary-1">
        <span> Read More </span>
      </a>

                    <div class="circle"></div>
                </div>
            </div>
          
           
            
           
        </div>
        <div class="space120"></div>
    </div>
</section>
    </div>
  )
}

export default HomeCard
