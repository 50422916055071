import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav style={navStyles}>
      <ul style={ulStyles}>
        <li style={liStyles}>
          <Link to="/resumes" style={linkStyles}>Applied_Resumes</Link>
        </li>
      </ul>
    </nav>
  );
};

const navStyles = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#333', // Add background color as needed
};

const ulStyles = {
  listStyle: 'none',
  display: 'flex',
  padding: 0,
};

const liStyles = {
  margin: '0 10px', // Adjust the margin as needed
};

const linkStyles = {
  textDecoration: 'none',
  color: 'white', // Add desired text color
};

export default Navbar;
