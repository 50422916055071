import React, { useState, useEffect } from 'react';
import axios from 'axios';

function CertificatePage() {
    const [studentDetails, setStudentDetails] = useState({
        name: '',
        fatherName: '',
        course: '',
        collegeName: '',
        yop: '',
        regNo: ''
    });

    useEffect(() => {
        async function fetchStudentDetails() {
            try {
                const regNo = getRegistrationNumberFromURL(); // Call the function here
                if (!regNo) {
                    console.error('Registration number is null or undefined');
                    return;
                }
                const response = await axios.get(`/api/courseStudents?REG_NO=${regNo}`);
                const data = response.data;
                const student = data.find(student => student.regNo === regNo);
                if (student) {
                    setStudentDetails(student); // Update student details state with fetched data
                } else {
                    console.error('Student not found with registration number:', regNo);
                }
            } catch (error) {
                console.error('Error fetching student details:', error);
            }
        }
        
    
        fetchStudentDetails();
    }, []); // Empty dependency array to run effect only once on mount
    
    function getRegistrationNumberFromURL() {
        const urlParams = new URLSearchParams(window.location.search);
        const regNo = urlParams.get('regNo');
        return regNo || ''; // Return an empty string if regNo is null or undefined
    }
    

    return (
        <div className="container" style={{ width: '1100px', marginTop: '140px' }}>
            <div className="row">
                <div className="col-lg-4" style={{ marginTop: '-100px' }}>
                    <div className="card shadow-sm">
                        <div className="card-header bg-transparent text-center">
                            <h3><b>{studentDetails.name}</b></h3>
                        </div>
                        <div className="card-body">
                            <form>
                                <label htmlFor="fatherName">Father's Name:</label>
                                <input type="text" id="fatherName" name="fatherName" className="form-control mb-2" value={studentDetails.fatherName} disabled />
                                <label htmlFor="section">Reg No:</label>
                                <input type="text" id="section" name="section" className="form-control mb-2" value={studentDetails.regNo} disabled />
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8" style={{ marginTop: '-100px' }}>
                    <div className="card shadow-sm">
                        <div className="card-header bg-transparent border-0">
                            <h3 className="mb-0"><i className="far fa-clone pr-1"></i>General Information</h3>
                        </div>
                        <div className="card-body pt-0">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th width="30%">College name</th>
                                        <td width="2%">:</td>
                                        <td>{studentDetails.collegeName}</td>
                                    </tr>
                                    <tr>
                                        <th width="30%">Academic Year</th>
                                        <td width="2%">:</td>
                                        <td>{studentDetails.yop}</td>
                                    </tr>
                                    <tr>
                                        <th width="30%">Course</th>
                                        <td width="2%">:</td>
                                        <td>{studentDetails.course}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ fontSize: '18px', marginLeft: '30px' }}>
                <p><b>"He/she successfully completed their internship at EXCERPT TECHNOLOGIES PVT.LTD, demonstrating
                    exceptional dedication and skill. Their contributions significantly enriched our projects and
                    team dynamics, showcasing their potential for future success in the industry."</b></p>
            </div>
        </div>
    );
}

export default CertificatePage;
