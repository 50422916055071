// import React, { useState, useEffect } from 'react';
// import './Popupform.css';

// const PopupImg = () => {
//   const [showPopup, setShowPopup] = useState(false);
//   const [userClosed, setUserClosed] = useState(false);

//   useEffect(() => {
//     const hasPopupShown = localStorage.getItem('hasPopupShown');

//     if (!hasPopupShown) {
//       setShowPopup(true);
//       localStorage.setItem('hasPopupShown', 'true');
//     }
//   }, []);

//   const handleClose = () => {
//     setShowPopup(false);
//     setUserClosed(true);
//   };

//   return (
//     <>
//       {showPopup && (
//         <div className="popup-overlay">
//           <div className="popup-content">
//             <button className="close-button" onClick={handleClose}>&times;</button>
//             <a href="/careers" className="apply-now-link">
//               <img src="images/apply-now.png" alt="Apply Now" className="apply-now-image" />
//               <div className="overlay-text">Apply Now</div>
//             </a>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default PopupImg;


// TrainingPopup.js

import React from 'react';
import '../PopupImage/Popupform.css'; // Add CSS for styling

const PopupImg = ({ onClose }) => {
  return (
    <div className="training-popup">
      <div className="popup-content">
        <span className="close-btn" onClick={onClose}>&times;</span>
        <h2>Excerpt Technologies Pvt.Ltd</h2>
        <p>This is your training popup content.</p>
        {/* Add your popup image or content here */}
      </div>
    </div>
  );
};

export default PopupImg;
