

import React from "react";
import './homeabout.css'

function Homeabout() {
  const style = {
    
    
  };
  return (

    <div className="responsive_aboutus" style={{marginTop:"-35px"}}>
      <section class="about-area pb-150s tp-el-section">
        <div class="container" style={{ maxWidth: "1440px" }}>
          <div class="row align-items-end">
            <div class="col-xl-6 col-lg-5">
              <div
                class="tp-about-thumb wow fadeInRight"
                data-wow-duration=".8s"
                data-wow-delay=".4s"
              >
                <div class="tp-about-thumb-main overlay-anim w-img tp-thumb-common fix">
                  <div class="tp-thumb-common-overlay wow"></div>
                  <img
                    decoding="async"
                    src="images/111.jpg"
                    width={'400px'}
                    height={'500px'}

                    alt=""
                  />
                </div>
                <div class="tp-about-thumb-shape">
                  <div class="tp-about-thumb-shape-one">
                    <div class="about-image-1 overlay-anim tp-thumb-common fix">
                      <div class="tp-thumb-common-overlay wow"></div>
                      <img
                        decoding="async"
                        src="images/ab2.jpg"
                        width={'400px'}
                        height={'400px'}
                        alt=""
                        marginTop={'30px'}
                      />
                    </div>
                  </div>
                  <div class="tp-about-thumb-shape-two">
                    <img
                      decoding="async"
                      src="https://data.themeim.com/wp/tecz/wp-content/themes/tecz/assets/img/about/one/about-shape-1.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="tp-about-exprience d-none d-xl-block" >
                  {/* <h5 class="tp-about-exprience-title">25</h5> */}
                  <p style={{ fontSize: '25px' }} className="paragraph">
                    <b>  Since <br /> 2020</b>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-7" style={{ marginTop: '-30px' }}>
              <div
                class="tp-about-wrap ml-20 wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".4s"

              >
                <div class="tp-section mb-40"   >
                  <h1><b>About us..</b>.</h1>

                  <h2 class="tp-section-title">
                    <br /> Smart Solution For Business
                  </h2>


                  <div className="container" style={{marginLeft:"-17px"}}>
                    <div className="tp-section-title-wrapper" style={{textAlign:"justify"}}  >
                      
                      <p>
                        <b style={{ fontSize: "17px" }}>Excerpt Technologies Private Limited</b>   <span></span>
                      is a leading IT solutions and services company established with a specific mission of providing comprehensive, top of the line solutions. Since its inception, ExcerptTech has emerged as a key player in Ecommerce solution providers, WebSite Design and Development, Mobile App Development, CyberSecurity and Data Analytics . ExcerptTech brings together the best Technology and Domain expertise in Ecommerce online solutions space to offer a full spectrum of services ranging from Implementation, Business Consulting, Maintenance and Managed Operations.</p>
                    
                    </div>
                    <div className="tp-section-title-wrapper" style={{textAlign:"justify"}}>
                      <h6 style={style}>
                       
                      </h6>
                      <p>   <b>Excerpt Technologies Private Limited</b> is a cutting edge retail technology company that provides you all the tools to build your e-commerce business. Whether you are a conventional retailer intending to build your omni-channel business or an online-only retailer intending to build your e-commerce business, we have a suite of products that will quickly and easily empower you to achieve your goals.</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>


      </section>
    </div>

  );
}

export default Homeabout;
