import React, { useState, useEffect } from 'react';
import '../Cookie/Cookie.css'; // Import CSS for styling

const CookieConsentBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  useEffect(() => {
    // Check if the user has previously accepted cookies
    const cookiesAccepted = document.cookie.split(';').some((cookie) => cookie.trim().startsWith('cookiesAccepted='));
    setAcceptedCookies(cookiesAccepted);
  }, []);

  const acceptCookies = () => {
    // Set a cookie indicating that the user has accepted cookies
    document.cookie = "cookiesAccepted=true; expires=Thu, 01 Jan 2026 00:00:00 UTC; path=/;";
    setAcceptedCookies(true);
  };

  const ignoreCookies = () => {
    setAcceptedCookies(true);
    // Optionally, you can set a cookie indicating that the user has ignored cookies
  };

  if (acceptedCookies) {
    return null; // Do not display the banner if cookies are accepted
  }

  return (
    <div className="cookie-modal">
      <div className="cookie-modal-content">
        <p>This website uses cookies to improve user experience.</p>
        <button onClick={acceptCookies}>Accept All Cookies</button>
        <button onClick={ignoreCookies}>Ignore</button>
      </div>
    </div>
  );
};

export default CookieConsentBanner;

