import React, { useState, useEffect } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode';
// Import PDFDocument and rgb from pdf-lib
import { TextAlignment } from 'pdf-lib';


import './Certificate.css';
import PropTypes from 'prop-types'; // Import PropTypes
import './DropdownExample.css'



function CertificateGenerator({ firstName, CollegeName, FATHER_NAME, REG_NO, coursename, certificate_type, yop, coursecertificatr, Gender, Role }) {
  console.log("Received props:", { firstName, CollegeName, FATHER_NAME, REG_NO, coursename, certificate_type, yop, Gender, Role });
  const defaultImage = '/image/default.png';


  const getImageSource = (regNo) => {
    return `/image/${regNo}.jpg`; // Assuming the image file format is .jpg
  };

  const [qrCodeValue, setQrCodeValue] = useState('');

  const fullurl = `https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`;

  // const handleViewCertificate = () => {
  //   const fullurl = `http://localhost:3000/certificate.html?REG_NO=${REG_NO}`;
  //   setQrCodeValue(fullurl);
  // };

  const capitalize = (str, lower = false) => {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
      match.toUpperCase()
    );
  };


  const getLines = (text, maxWidth, font, fontSize) => {
    const paragraphs = text.split('\n'); // Split text into paragraphs
    let lines = [];
    let y = 400; // Initial y-position

    paragraphs.forEach((paragraph) => {
      const words = paragraph.split(' ');
      let currentLine = '';

      for (let word of words) {
        const width = font.widthOfTextAtSize(currentLine + ' ' + word, fontSize);
        if (width > maxWidth) {
          lines.push(currentLine.trim());
          currentLine = word;
        } else {
          currentLine += ' ' + word;
        }
      }

      if (currentLine !== '') {
        lines.push(currentLine.trim());
      }
    });

    return { lines, y };
  };






  const handleGenerateCertificate = async () => {
    const trimmedName = firstName ? firstName.trim() : '';


    if (trimmedName !== '' && certificate_type !== '') {
      try {
        let templatePath = '';
        if (certificate_type.toLowerCase() === 'internship') {
          templatePath = "./cert11.pdf";
        } else if (certificate_type.toLowerCase() === 'project') {
          templatePath = "./cert2.pdf";
        } else if (coursecertificatr.toLowerCase() === 'course') {
          templatePath = "./cert3.pdf";
        } else {
          throw new Error('Invalid course title');
        }

        const existingPdfBytes = await fetch(templatePath).then((res) =>
          res.arrayBuffer()
        );

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(fontkit);

        const nameFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
          res.arrayBuffer()
        );



        const nameFont = await pdfDoc.embedFont(nameFontBytes);

        const pages = pdfDoc.getPages();
        const firstPage = pages[0];

        let xPos = 0;
        let yPos = 0;
        let fontSize = 34;

        if (certificate_type.toLowerCase() === 'course') {
          xPos = 480;
          yPos = 380;
        } else if (certificate_type.toLowerCase() === 'internship') {
          xPos = 200;
          yPos = 470;
        } else if (certificate_type.toLowerCase() === 'project') {
          xPos = 320;
          yPos = 310;
          fontSize = 30;
        }

        const displayName = capitalize(trimmedName);
        const { width: pageWidth, height: pageHeight } = firstPage.getSize();
        const nameWidth = nameFont.widthOfTextAtSize(displayName, fontSize);
        const nameX = (pageWidth - nameWidth) / 2;

        firstPage.drawText(displayName, {
          x: nameX,
          y: yPos,
          size: fontSize,
          font: nameFont,
          color: rgb(0, 0, 0),
        });



        const mainContentFontBytes = await fetch("./NunitoSans_10pt-Bold.ttf").then((res) =>
          res.arrayBuffer()
        );

        const boldFontBytes = await fetch("./NunitoSans_7pt_SemiExpanded-SemiBold.ttf").then((res) =>
          res.arrayBuffer()
        );

        const boldFont = await pdfDoc.embedFont(boldFontBytes);

        const mainContentFont = await pdfDoc.embedFont(mainContentFontBytes);


        // const introText = 'This is to certify that student from'; 
        // const collegeNameText = CollegeName 
        // const middleText = 'has completed  their internship work from 8-Jan-2024 to \n13-Apr-2024 at Excerpt Technologies Private Limited.';
        // const pronounText = Gender === 'Male' ? 'He' : 'She';
        // const projectDetailsText = `has successfully & satisfactorily completed assigned project work. During his tenure as a ${Role} we found him sincere, hardworking, and result-oriented. We wish him great success in all of his future endeavors.`;

        // const introTextX = 110;
        // const introTextY = 380;
        // const collegeNameX = introTextX + mainContentFont.widthOfTextAtSize(introText, additionalContentFontSize);
        // const collegeNameY = introTextY;
        // const middleTextX = introTextX;
        // const middleTextY = introTextY - 20; // Adjust as needed
        // const pronounTextX = introTextX;
        // const pronounTextY = middleTextY - 20; // Adjust as needed
        // const projectDetailsTextX = introTextX;
        // const projectDetailsTextY = pronounTextY - 20; // Adjust as needed

        // // Draw each part with appropriate font
        // firstPage.drawText(introText, {
        //   x: introTextX,
        //   y: introTextY,
        //   size: additionalContentFontSize,
        //   font: mainContentFont,
        //   color: rgb(0, 0, 0),
        // });

        // firstPage.drawText(collegeNameText, {
        //   x: collegeNameX,
        //   y: collegeNameY,
        //   size: additionalContentFontSize,
        //   font: boldFont,
        //   color: rgb(0, 0, 0),
        // });

        // firstPage.drawText(middleText, {
        //   x: middleTextX,
        //   y: middleTextY,
        //   size: additionalContentFontSize,
        //   font: mainContentFont,
        //   color: rgb(0, 0, 0),
        // });

        // firstPage.drawText(pronounText, {
        //   x: pronounTextX,
        //   y: pronounTextY,
        //   size: additionalContentFontSize,
        //   font: boldFont,
        //   color: rgb(0, 0, 0),
        // });

        // firstPage.drawText(projectDetailsText, {
        //   x: projectDetailsTextX,
        //   y: projectDetailsTextY,
        //   size: additionalContentFontSize,
        //   font: mainContentFont,
        //   color: rgb(0, 0, 0),
        // });





        let additionalContent = '';

        if (certificate_type.toLowerCase() === 'internship') {
          if (Gender === 'MALE') {
            additionalContent =
              `This is to certify that student from "${CollegeName}" has completed their internship work from 8-Jan-2024 to 13-Apr-2024 at  Excerpt Technologies Private Limited.\nHe has successfully & satisfactorily completed assigned  project work. During his tenure as a "${Role}" we found him sincere, hardworking, and  result-oriented.\n We wish him great success in all of his future endeavors.`;

          } else if (Gender === 'FEMALE') {
            additionalContent =
              `This is to certify that student from "${CollegeName}" has completed their internship work from 8-Jan-2024 to 13-Apr-2024 at Excerpt Technologies Private Limited. \n She has successfully & satisfactorily completed assigned project work. During her tenure as a "${Role}" we found her sincere, hardworking, and result-oriented.\n We wish her great success in all of her future endeavors.`;
          }







        } else if (certificate_type.toLowerCase() === 'project') {
          if (Gender === 'MALE') {
            additionalContent =
              `This is to certify that student from ${CollegeName} has fsxbsgsfawfegfwaesfzqcompleted their internship work from 8-Jan-2024 to 13-Apr-2024 at  Excerpt Technologies Private Limited. He has successfully & satisfactorily completed assigned  project work. During his tenure as a ${Role} we found him sincere, hardworking, and  result-oriented. We wish him great success in all of his future endeavors.`;
          } else if (Gender === 'FEMALE') {
            additionalContent =
              `This is to certify that student from ${CollegeName}wsdfghsdfghsxdf has completed their internship work from 8-Jan-2024 to 13-Apr-2024 at  Excerpt Technologies Private Limited. He has successfully & satisfactorily completed assigned  project work. During his tenure as a ${Role} we found him sincere, hardworking, and  result-oriented. We wish him great success in all of his future endeavors.`;
          }
        }

        if (certificate_type.toLowerCase() === 'internship') {
          if (additionalContent !== '') {
            const additionalContentX = 160;
            const additionalContentFontSize = 16;


            // Draw each line of additional content for internship
            const { lines, y } = getLines(additionalContent, 900, mainContentFont, additionalContentFontSize);

            lines.forEach((line, index) => {
              const lineSpacing = 10; // Adjust the spacing between lines as needed
              const yPosition = y - (index * additionalContentFontSize) - (index * lineSpacing); // Adjust spacing between lines as needed
              pdfDoc.getPages()[0].drawText(line, {
                  x: additionalContentX,
                  y: yPosition,
                  size: additionalContentFontSize,
                  font: mainContentFont,
                  color: rgb(0, 0, 0),
                  alignment: TextAlignment.Center,
              });
          });}




        } else if (certificate_type.toLowerCase() === 'project') {
          if (additionalContent !== '') {
            const additionalContentX = 190;
            const additionalContentFontSize = 16;

            // Draw each line of additional content for project
            const { lines, y } = getLines(additionalContent, 850, mainContentFont, additionalContentFontSize);

            // Draw each line of additional content
            lines.forEach((line, index) => {
              pdfDoc.getPages()[0].drawText(line, {
                x: additionalContentX,
                y: y - (index * 25), // Adjust spacing between lines as needed
                size: additionalContentFontSize,
                font: mainContentFont,
                color: rgb(0, 0, 0),
                alignment: TextAlignment.Center,
              });
            });
          }
        }





        // let collegeNameXPos = 0;
        // let collegeNameYPos = 0;
        // let collegeNameFontSize = 20; // Default font size

        // if (certificate_type.toLowerCase() === 'course') {
        //   xPos = 300;
        //   yPos = 380;
        //   collegeNameXPos = 100; // Adjust as needed
        //   collegeNameYPos = 100; // Adjust as needed
        //   collegeNameFontSize = 24; // Adjust as needed
        // } else if (certificate_type.toLowerCase() === 'internship') {
        //   xPos = 320;
        //   yPos = 360;

        //   collegeNameXPos = 150; // Adjust as needed
        //   collegeNameYPos = 150; // Adjust as needed
        //   collegeNameFontSize = 18; // Adjust as needed
        // } else if (certificate_type.toLowerCase() === 'project') {
        //   xPos = 320;
        //   yPos = 310;
        //   fontSize = 30;
        //   collegeNameXPos = 200; // Adjust as needed
        //   collegeNameYPos = 200; // Adjust as needed
        //   collegeNameFontSize = 32; // Adjust as needed
        // }

        // // Draw college name
        // firstPage.drawText(CollegeName, {
        //   x: collegeNameXPos,
        //   y: collegeNameYPos,
        //   size: collegeNameFontSize,
        //   font: SanChezFont,
        //   color: rgb(0, 0, 0),
        // });




        // -----------------------------------------------------------qrcode=----------------------------//

        const qrCodeOptions = {
          color: {
            dark: '#000',   // QR code color
          },
          errorCorrectionLevel: 'H', // High error correction level
          width: 200,
          type: 'png',
          transparent: true, // Make the background transparent
        };


        const linkURL = `https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`;
        setQrCodeValue(linkURL); // Set QR code value to the URL with registration number

        const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
        const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
        const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
        const { width, height } = firstPage.getSize();
        let qrCodeWidth = 100; // Default width
        let qrCodeHeight = 100;
        if (certificate_type.toLowerCase() === 'course') {
          qrCodeWidth = 120; // Adjusted width for course certificate
          qrCodeHeight = 120; // Adjusted height for course certificate
        } else if (certificate_type.toLowerCase() === 'internship') {
          qrCodeWidth = 150; // Adjusted width for internship certificate
          qrCodeHeight = 150; // Adjusted height for internship certificate
        } else if (certificate_type.toLowerCase() === 'project') {
          qrCodeWidth = 150; // Adjusted width for project certificate
          qrCodeHeight = 150; // Adjusted height for project certificate
        }

        const qrCodeX = 70; // Adjusted for bottom left
        const qrCodeY = 50;

        firstPage.drawImage(qrCodeImage, {
          x: qrCodeX,
          y: qrCodeY,
          width: qrCodeWidth,
          height: qrCodeHeight,
        });

        const pdfBytes = await pdfDoc.save();
        const file = new File(
          [pdfBytes],
          "Certificate.pdf",
          {
            type: "application/pdf;charset=utf-8",
          }
        );

        saveAs(file);
      } catch (error) {
        console.error('Error generating or downloading certificate:', error);
        alert('Error generating or downloading certificate. Please try again later.');
      }
    } else {
      alert('Please enter a name and select a certificate type.');
    }
  };


  return (

    <>
      <div className="card shadow">
        <div style={{ textAlign: "justify" }}>
          <img className='image' src={getImageSource(REG_NO) || defaultImage} alt="Student" />
          <br />
          <br />
          <p><span className="detail-label">NAME:</span> {firstName}</p>
          <p><span className="detail-label">REG_NO:</span> {REG_NO}</p>
          <p><span className="detail-label">FATHER_NAME:</span> {FATHER_NAME}</p>
          <p><span className="detail-label">COURSETITLE:</span> {coursename}</p>
          <p><span className="detail-label">COOLEGENAME:</span> {CollegeName}</p>
          <p><span className="detail-label">CERTIFICATION:</span> {certificate_type}</p>
          <p><span className="detail-label">YOP:</span> {yop}</p>
          <p><span className="detail-label">COURSECERTIFICATION:</span> {coursecertificatr}</p>
          <p><span className="detail-label">GENDER:</span> {Gender}</p>
          <p><span className="detail-label">Role:</span> {Role}</p>

        </div>
        <div>
          <button onClick={handleGenerateCertificate} style={{ marginLeft: "-9px", width: "200px", marginTop: "10px" }}>Get Certificate</button>
          <a style={{ marginLeft: "180px" }} href={`https://www.excerptech.com/certificate.html?REG_NO=${REG_NO}`} target="_blank" rel="noopener noreferrer">View Certificate</a>
        </div>
      </div>

    </>
  );
}

CertificateGenerator.propTypes = {
  firstName: PropTypes.string.isRequired,
  REG_NO: PropTypes.string.isRequired,
  FATHER_NAME: PropTypes.string.isRequired,
  coursename: PropTypes.string.isRequired,
  CollegeName: PropTypes.string.isRequired,
  Course_Title: PropTypes.string.isRequired,


  certificate_type: PropTypes.string.isRequired,
  yop: PropTypes.string.isRequired
};

export default CertificateGenerator;
