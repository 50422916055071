import React from 'react'

const WebDesign = () => {
  return (
    <div>
        <div id="main-wrapper">
                <div class="site-wrapper-reveal">
                    <div class="about-banner-wrap vision-space bg-img" style={{backgroundColor: "darkturquoise", height: "600px"}}>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 section-space--pt_100">
                                    <h1 style={{color: "#fff"}}>Mern Stack Development</h1>
                                    <p style={{color:" #fff"}}>MEAN is an acronym for MongoDB, Express.js and Angularjs, all of which function upon Node.js</p>
                                </div>
                                <div class="col-lg-2">
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-container mx-auto mx-xl-0 ml-xl-auto border" id="corseform">
                                        <div id="signup-form">
                                            <form id="basic-form" name="yoursite_wp_mail_from_name" method="post" />
                                            <input type="hidden" name="courseId" value="1132" />
                                            <input type="hidden" name="category" value="16" />
                                            <div class="form-group">
                                                <label>Name:</label>
                                                <input type="text" id="fname" name="fname" class="form-control" placeholder="Enter Your Name" value="" required="" />
                                            </div>
                                            <div class="form-group">
                                                <label>Email:</label>
                                                <input type="email" id="email" name="email" class="form-control" placeholder="Enter Your Email Address" value="" required="" />
                                            </div>
                                            <div class="form-group">
                                                <label>Mobile:</label>
                                                <input type="number" id="phone" name="phone" class="form-control" placeholder="Enter Your mobile number" value="" required="" />
                                            </div>
                                            <div class="form-group mb-0">
                                                <div class="form-row">
                                                    <div class="form-group col-6">
                                                        <label class="control-label">State</label>
                                                        <select id="states" name="states" class="form-control" required="">
                                                            <option value="">SELECT STATE</option>
                                                            <option value="2">Andhra Pradesh</option>
                                                            <option value="5">Bihar</option>
                                                            <option value="12">Gujarat</option>
                                                            <option value="13">Haryana</option>
                                                            <option value="14">Himachal Pradesh</option>
                                                            <option value="16">Jharkhand</option>
                                                            <option value="17">Karnataka</option>
                                                            <option value="21">Maharashtra</option>
                                                            <option value="28">Punjab</option>
                                                            <option value="29">Rajasthan</option>
                                                            <option value="31">Tamil Nadu</option>
                                                            <option value="32">Telangana</option>
                                                            <option value="35">Uttar Pradesh</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-6 city">
                                                        <label class="control-label">Center</label>
                                                        <select name="city" id="city" class="form-control center" required="">
                                                            <option value="">SELECT CENTRE</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mb-0">
                                                <button type="submit" id="btn" class="btn btn-primary contest_register_now" name="register_users">Apply Now</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
          <li class="breadcrumb-item" >
            <a href="/" >Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">MernStack</li>
        </ol>
      </nav>




      <div class="vision-overview ">
        <div class="container">
          <div class="section-title-wrap text-center">
            <br/>
            <br/>

            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Training Highlights</span></h3>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container" >
                  <img src="./assets/images/img11.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Instructor Led Training</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img22.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Weekly Doubt clearing Session</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img33.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Assistance for Revision</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img44.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Login Access to Student Dashboard</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img55.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">World-Wide Valid Certification</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="res-image-container">
                <img src="./assets/images/img666.jpg" class="lazy-load"  height={"150px"} width={"200px"} style={{borderRadius:"5px",margin:"10px"}}/>
                </div>
                <div class="content-container">
                  <h3 class="title">Study Material, Assignments and Projects</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>

          </div>
          </div>
          </div>

    </div>
  )
}

export default WebDesign