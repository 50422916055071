import React from "react";
import './footer.css'
const Footer1 = () => {
  return (
    < div id="footer" style={{ marginTop: "" }}>
      <section
        style={{ backgroundImage: `url(images/footer/bg.png), linear-gradient(0deg, #03041c, #03041c)` }}
        id="footer "
      >
        <div class="footer-top ">
          <div class="container ">
            <div class="d-flex flex-column flex-md-row align-items-center justify-content-end ">
              <h2 class="heading-2 mr-50 "></h2>
              <form
                class="d-flex flex-column flex-md-row align-items-center "
                action=" "
              >
                <div class="">

                </div>


              </form>
            </div>
          </div>
        </div>

        <div class="footer-body " >
          <div class="container responsive_ele">
            <div class="row g-4 ">
              <div class="col-md-6 col-12 col-xl-4 ">
                <div class="about-company " style={{ marginTop: "5px", height: "375px" }}>
                  <div class="logo-wrapper " style={{ height: "105px" }}>
                    <a href="/">
                      <img src="images/excerptlogo.png" alt="" height={60} style={{ marginTop: '20px' }} />
                    </a>
                  </div>

                  <div class="content ">
                    <h2 class="heading-3 "></h2>

                    <div class="paragraph ">
                      <b style={{ color: "black" }}>  Excerpt Technologies Private Limited</b><br /> is a leading IT solutions and services company established with a specific mission of providing comprehensive, top of the line solutions.
                    </div>

                    <div class="socials " >
                      <ul>
                        <li>
                          <a href="https://www.facebook.com/Excerptech" target="_blanck">
                            <i class="fa-brands fa-facebook-f "></i>
                          </a>
                        </li>
                        <li class=" ">
                          <a href="https://www.linkedin.com/company/excerptech " target="_blanck">
                            <i class="fa-brands fa-linkedin-in "></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/excerpt_technologies/?igsh=Z3k5OXozNDIzZHps " target="_blanck">
                            <i class="fa-brands fa-instagram "></i>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-xl-4 " >
                <div class="wrapper responsive_ey" >
                  <h4 class="heading-4 ">Quick Links</h4>
                  <ul>
                   
                    <br />
                    <li class=" ">
                      <a href="/webdesign ">Web Design & Development</a>
                    </li>
                    <br />
                    <li>
                      <a href="/mobileapplication ">Mobile App Development</a>
                    </li>
                    <br />
                    <li>
                      <a href="/CyberSecurity">Cyber Security</a>
                    </li>
                    <br />
                    <li>
                      <a href="/DigitalMarketing ">Digital Marketing</a>
                    </li>
                    <br />
                    <li>
                      <a href="/ecommerce ">Ecommerce Solutions</a>
                    </li>
                    <li>
                      <a href="/seo">Search Engine Optimization</a>
                    </li>
                    <br />
                    <li>
                      <a href="/privacy"><b>Privacy</b></a>
                    </li>
                  </ul>
                </div>
              </div>



              <div class="col-md-6 col-xl-4 " style={{ marginLeft: "" }}>
                <div class="wrapper " style={{ marginRight: "-140px" }}>
                  <h4 class="heading-4 ">Contact Us</h4>
                  <div class="d-flex contact ">
                    <div class="icon-wrapper ">
                      <span>
                        <i class="fa-solid fa-phone "></i>
                      </span>
                    </div>

                    <div class="info ">

                      <div class="paragraph "><a onclick="return gtag_report_conversion('tel:+916364657660');" href="tel:+916364657660" >+91 63646 57660
</a></div>
                      <div class="paragraph "><a  href="tel:+919900502404" >+91 99005 02404
</a></div>
                    </div>
                  </div>
                  <div class="d-flex contact ">
                    <div class="icon-wrapper  ">
                      <span>
                        <i class="fa-solid fa-envelope "></i>
                      </span>
                    </div>

                    <div class="info ">
                      <div style={{ marginTop: "10px" }}>
                        <a href="mailto:example@gmail.com " class="paragraph ">
                          info@excerptech.com

                        </a>
                        <br />
                        <a href="mailto:example@gmail.com " class="paragraph ">


                        </a>
                      </div>


                    </div>
                  </div>
                  <div class="d-flex contact ">
                    <div class="icon-wrapper ">
                      <span>
                        <i class="fa-solid fa-location-dot "></i>
                      </span>
                    </div>

                    <div class="info ">
                      <div class="paragraph">   2nd Floor, B133/1,  KSSIDC <br />ITI Estate, Whitefield Road,<br />

                        Mahadevpura  Post Bangalore,  <br />Karnataka-560048</div>


                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="divider "></div>

            <div class="footer-bottom ">
              <div class="d-flex justify-content-center justify-content-md-between align-items-center ">
                <div class="paragraph " >
                  <span style={{ textAlign: "center" }}>© Excerpt Technologies 2024</span>

                  <span style={{ marginLeft: '5px' }}>All Rights Reserved.</span>
                </div>
                <div class="infos ">
                  <ul class="d-none d-md-flex ">

                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer1;
