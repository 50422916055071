import React from 'react'
import './CarrerBg.css'
import { color } from 'framer-motion'

function CarrerBg() {
  return (


    <div>


      <section className="" >
      <section style={{
                backgroundImage: "url(images/Newimage/Career-1.png)",

                backgroundColor: "rgb(255, 255, 255)",
                height:"300px"
            }}
                class="sub-header position-relative">
                <div class="container">
                    <div class="page-info position-absolute " style={{marginTop:"-60px"}}>
                        <h2 class="heading-2" style={{color:"Black",textAlign:"center"}}>Careers</h2>
                        <h6 class="heading-6">
                            <a href="/" style={{color:"Black"}}>Home / </a><a href="#" style={{color:"Black"}}>Careers</a>
                        </h6>
                    </div>
                </div>
            </section>
{/* 
        <div className="fluid-container " style={{ marginTop: "5px" }}>
          <article className="expertise-banner-content">
            <h2 style={{fontSize:"40px"}}>Careers</h2>
            <p style={{fontSize:"20px"}}>Join Excerpt Technologies and embark on a journey of growth and excellence. As a leading technology company, we are always on the lookout for passionate and talented individuals. Sharing our vision through creativity. </p>
            <div className="button-container">
              <a href="" rel="">Get a Quote</a>
              {/* <a href="">Apply Now</a> */}
            {/* </div>
          </article>
        </div>  */}
      </section>
    </div>
  )
}

export default CarrerBg
