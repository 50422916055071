import React, { useState } from 'react';
import '../Training/Training.css';
import Allprogram from '../Training/Allprogram/Allprogram';
import Onlineprogram from './OnlineProgram/Onlineprogram';
import Internship from './Internship/Internship';
import Placement from './Placement/Placement';
import { Link } from 'react-router-dom';
import FormPopup from './FormPopup/FormPopup';
import '../Carrers';
import Carrers from '../Carrers';
import { Helmet } from 'react-helmet';

const Training = () => {
  const [activeTab, setActiveTab] = useState('AllProgram');
  const [showPopup, setShowPopup] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const renderContent = () => {
    switch (activeTab) {
      case 'AllProgram':
        return <Allprogram />;
      case 'OnlineProgram':
        return <Onlineprogram />;
      case 'Internship':
        return <Internship />;
      case 'Placement':
        return <Placement />;
      default:
        return null;
    }
  };

  const handlePopupOpen = () => {
    setShowPopup(true);
    setShowForm(false);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleImageClick = () => {
    setShowPopup(false);
    setShowForm(true); // Show the form when the image is clicked
  };

  return (
    <div>
      <section
        style={{
          backgroundImage: "url(/Trainingimage/training.png)",
          backgroundColor: "rgb(255, 255, 255)",
          height: '500px'
        }}
        className="sub-header position-relative"
      >
        <Helmet>
         <title>IT Trainings | Excerpt Technologies</title>
         <meta name="description" content="Enhance your skills with comprehensive IT Trainings from Excerpt Technologies. Our expert-led courses cover the latest technologies and industry practices to help you stay ahead in the tech world. Enroll now to advance your career and achieve your professional goals!" />
         <meta name="keywords" content='top training & software solutions' />
         </Helmet>
        <div className="container">
          <div className="page-info position-absolute">
            <h2 className="heading-2" style={{ marginLeft: "-280px", marginTop: "140px" }}>IT TRAININGS</h2>
            <h6 className="heading-6" style={{ marginLeft: "-650px" }}>
              <a href="/">Home</a> / <a href="#">Training</a>
            </h6>
          </div>
        </div>
      </section>
      <div className="container" style={{ marginTop: "-110px" }}>
        <div className="section-heading d-flex flex-column align-items-center justify-content-center">
          <div className="space120"></div>
          <h1 className="heading-2">Excerpt Corporate Training Services</h1>
          <div className="paragraph"></div>
        </div>
      </div>

      <div className="App">
        <div className="tab-content" style={{ marginTop: "-30px" }}>
          {renderContent()}
          {/* <Link to="#" className="open-popup-btn" onClick={handlePopupOpen}>
            Open Popup
          </Link> */}
        </div>
      </div>

      {showPopup && (
        <div className="training-popup">
        <div className="popup-content">
          <span className="close-btn" onClick={handlePopupClose}>
            &times;
          </span>
          <h2>Excerpt IT Training Service</h2>
          <img
            src="./assets/images/popupimag.jpeg"
            className="popup-image"
            onClick={handleImageClick}
          />
        </div>
      </div>
      
      )}

      {showForm && (
        <FormPopup onClose={() => setShowForm(false)} />
      )}
    </div>
  );
};

export default Training;
